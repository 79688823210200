export const salesTag = (tags, cityId) => {
	const tagsObj = tags.reduce((obj, key) => {
		const [city, price] = key.split(":");
		obj[city] = price;
		return obj;
	}, {});

	return cityId in tagsObj ? tagsObj[cityId]?.split(",")[0] : null;
};

const sortCategoriesFirebase = (categoryId, categoriesOrder) => {
	let order = 9999;
	const keys = Object.keys(categoriesOrder);
	const values = Object.values(categoriesOrder);

	values.map((value, index) => {
		if (value === categoryId) {
			order = keys[index];
		}
	});

	return +order;
};

const sortCategoriesIiko = (categoryId, categoriesOrder) => {
	let order = categoriesOrder;
	const keys = Object.keys(order);
	const values = Object.values(order);

	values.map((value, index) => {
		if (value === categoryId) {
			order = keys[index];
		}
	});

	return +order;
};

const getProductTags = (productId, productsTags) => {
	const tags = [];
	const activeTags = productsTags.tags.filter((item) => item.isActive);
	for (const tag of activeTags) {
		if (tag.productsIds.includes(productId)) {
			const processedTag = { ...tag };

			if (processedTag?.text?.length > 0) {
				const text = processedTag.text.split("(")[0].trim();
				const colors = processedTag.text.match(/\(([^)]+)\)/)[1];
				const [backgroundColor, color] = colors.split(",");

				processedTag.text = text;
				processedTag.backgroundColor = backgroundColor;
				processedTag.color = color;
			}

			tags.push(processedTag);
		}
	}
	if (tags.length === 0) {
		return null;
	}
	return tags.sort((a, b) => a.position - b.position);
};

export const filtredMenu = (menu, activeBrand, externalMenu) => {
	let filtredCategories = [];
	const filtredProducts = [];
	const allCategories = [];
	const hiddenProductsList = [];
	const categoriesList = menu.categories ? menu.categories : [];
	const productsList = menu.products ? menu.products : [];
	const {
		hiddenCategories,
		hiddenProducts,
		hiddenModifierGroups,
		categoriesOrder,
		parentCategories,
		productsTags,
	} = activeBrand.category;

	// const categories = categoriesList.filter((category) =>
	// 	parentCategories.includes(category.parentGroup)
	// );

	const categories = parentCategories?.some(
		(parentCategory) => parentCategory.trim() !== ""
	)
		? categoriesList.filter((category) =>
				parentCategories.includes(category.parentGroup)
		  )
		: categoriesList;

	const categoriesIds = categories.map((category) => category.id);

	const products = productsList.filter((product) =>
		categoriesIds.includes(product.parentGroup)
	);

	const modifierCategories = categoriesList.filter(
		(item) => item.isGroupModifier === true
	);

	// const allCategories = categories
	// 	.filter((item) => item.isGroupModifier !== true)
	// 	.sort((a, b) => (a.order > b.order ? 1 : -1));

	// products.forEach((product) => {
	// 	const isDubble = categories.find((t) => t.id === product.categorieId);

	// 	if (!isDubble) {
	// 		categories.push({
	// 			id: product.categorieId,
	// 			name: product.categorieName,
	// 			seo: {
	// 				path: product.categoriePath,
	// 			},
	// 		});
	// 	}
	// });

	for (let key in categories) {
		const category = {
			...categories[key],
			order:
				categoriesOrder.length > 1
					? sortCategoriesFirebase(categories[key].id, categoriesOrder)
					: sortCategoriesIiko(categories[key].id, categories[key].order),
		};

		if (!hiddenCategories || !hiddenCategories.includes(category.id)) {
			const isProduct = products.find((t) => t.parentGroup === category.id);
			const isModifierCategory = !!modifierCategories.find(
				(t) => t.id === category.id
			);

			if (!isModifierCategory) {
				allCategories.push(category);
				isProduct && filtredCategories.push(category);
			}
		}
	}

	for (let key in products) {
		const product = products[key];

		// if (!hiddenProducts || !hiddenProducts.includes(product.id)) {
		const oldPrice = salesTag(product.tags || [], activeBrand.id);
		const groupModifiers = product.groupModifiers.filter(
			(modifier) => !hiddenModifierGroups.includes(modifier.id)
		);
		const labels = productsTags?.isTagsAvailable
			? getProductTags(product.id, productsTags)
			: null;

		let { price } = product;

		if (externalMenu.length > 0) {
			const prod = externalMenu.find((item) => product.id === item.iikoItemId);

			if (prod?.itemSizes?.[0]?.prices?.[0]?.price) {
				price = prod.itemSizes[0].prices[0].price;
			}
		}

		filtredProducts.push({
			...product,
			price,
			oldPrice,
			groupModifiers,
			labels,
		});
		// } else {
		// 	hiddenProductsList.push(product);
		// }
	}

	const sortedCategories = [...filtredCategories].sort((a, b) =>
		a.order > b.order ? 1 : -1
	);

	// console.log("sortedCategories", sortedCategories);

	return {
		filtredProducts,
		filtredCategories: sortedCategories,
	};
};
