import React, { useEffect, useState } from "react";
import { withFirebase } from "../../firebase";
import { withRouter, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import PickupControl from "./pickupControl";

const PickupControlContainer = (props) => {
	const location = useLocation();
	const cities = useSelector((state) => state.shops.shops);

	const currentCity = JSON.parse(localStorage.getItem("chosenCity"));
	const currentBrand = JSON.parse(localStorage.getItem("chosenBrand"));
	const isDebug = JSON.parse(localStorage.getItem("isDebug"));

	// const deliveryTerminals =
	// 	cities && cities[currentCity]?.brands[currentBrand]?.deliveryTerminals;

	const [terminals, setTerminals] = useState([]);

	const getTerminals = () => {
		props.firebase.bd
			.ref(
				`${
					isDebug ? "debug" : ""
				}/settings/city/${currentCity}/brands/${currentBrand}/deliveryTerminals`
			)
			.on("value", (snapshot) => {
				setTerminals(snapshot.val() || []);
			});
	};

	useEffect(() => {
		getTerminals();
	}, [location.pathname]);

	const onSubmit = () => {
		const isValidTerminals = !!terminals.find((item) => item.isActive === true);
		if (isValidTerminals) {
			props.firebase.bd
				.ref(
					`${
						isDebug ? "debug" : ""
					}/settings/city/${currentCity}/brands/${currentBrand}/deliveryTerminals`
				)
				.update(terminals)
				.then(() => {
					alert("Готово");
				})
				.catch((error) => {
					console.error("Error updating terminals:", error);
					alert("Ошибка при обновлении данных");
				});
		} else {
			alert("Мінімум один термінал повинен бути активний");
		}
	};

	// useEffect(() => {
	// 	deliveryTerminals && setTerminals(deliveryTerminals);
	// }, [deliveryTerminals]);

	return (
		<div>
			{terminals && (
				<PickupControl
					terminals={terminals}
					setTerminals={setTerminals}
					saveData={onSubmit}
				/>
			)}
		</div>
	);
};

export default withRouter(withFirebase(PickupControlContainer));
