import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { withFirebase } from '../../firebase';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { updateShops, updateLoadControl } from '../../reducers/shops';
import { addKeyShop } from '../../reducers/workingHours';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import ModalComponent from '../../components/ModalComponent';

import TableForm from './TableForm';
import useStyles from './styles';

const WorkTimeComponent = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const cities = useSelector(state => state.shops.shops);
    const currentCity = JSON.parse(localStorage.getItem("chosenCity"));
	const currentBrand = JSON.parse(localStorage.getItem("chosenBrand"));
    const isDebug = JSON.parse(localStorage.getItem('isDebug'))

    const checkoutScreen = cities && cities[currentCity]?.brands[currentBrand]?.checkoutScreen
    const deliveryTerminals = cities && cities[currentCity]?.brands[currentBrand]?.deliveryTerminals

    const [initialValues, setInitialValues] = useState(null);
    const isMin600 = useMediaQuery('(max-width: 599px)');
    const isMax600 = useMediaQuery('(min-width: 600px)');

    const [openModal, setOpenModal] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [dataTableForm, setDataTableForm] = useState(null);

    const handleClose = () => {
        setOpenModal(false);
        setIsSubmit(false);
    };

    const handleClickSubmit = () => {
        setOpenModal(false);
        setIsSubmit(true);
    };

    useEffect(() => {
        if (isSubmit && !openModal) {
           console.log('Вы подтвердили');

           saveLabelControl(dataTableForm);
        }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSubmit, openModal]);

    useEffect(() => {
        if (!initialValues && checkoutScreen) {
            if (Object.keys(checkoutScreen).length > 0) {
                const { orderTimeIntervalDelivery, orderTimeIntervalPickup } = checkoutScreen;

                setInitialValues({
                    orderTimeIntervalDelivery,
                    orderTimeIntervalPickup
                });
            }
        }
    }, [checkoutScreen]);


    const onSubmit = (data) => {
        console.log('--- onSubmit ---', data);

        setOpenModal(true);
        setDataTableForm(data);
        
    };

    const saveLabelControl = (data) => {
        let daliveryTerminalsData = [ ...deliveryTerminals]
        let sendData = {
            orderTimeIntervalDelivery: Number(data.orderTimeIntervalDelivery),
            orderTimeIntervalPickup: Number(data.orderTimeIntervalPickup)
        };
        
        deliveryTerminals.map((terminal, index) => {
            let workingHoursCity = [];
            console.log('terminal ***', terminal);
            terminal.workingHours.map((item, index) => {
                const { begin, end, deliveryEnd } = item;
                let newDeliveryEnd = moment(end, 'HH:mm');
                let changedDeliveryEnd = moment(deliveryEnd, 'HH:mm');
                let oldOrderTimeIntervalDelivery = moment(end, 'HH:mm');
                newDeliveryEnd = newDeliveryEnd.add(Number(data.orderTimeIntervalDelivery), 'minutes').format('HH:mm');
                oldOrderTimeIntervalDelivery = oldOrderTimeIntervalDelivery.add(Number(checkoutScreen.orderTimeIntervalDelivery), 'minutes').format('HH:mm');
                // changedDeliveryEnd = changedDeliveryEnd.add(checkoutScreen.orderTimeIntervalDelivery - data.orderTimeIntervalDelivery, 'minutes').format('HH:mm');

                if (begin === '00:00' && end === '00:00') {
                    newDeliveryEnd = '24:00';
                }

                // let interval = Math.abs(checkoutScreen.orderTimeIntervalDelivery - data.orderTimeIntervalDelivery);
 
                // if (checkoutScreen.orderTimeIntervalDelivery > data.orderTimeIntervalDelivery) {
                //     changedDeliveryEnd = changedDeliveryEnd.subtract(interval, 'minutes').format('HH:mm');
                // }

                // if (checkoutScreen.orderTimeIntervalDelivery < data.orderTimeIntervalDelivery) {
                //     changedDeliveryEnd = changedDeliveryEnd.add(interval, 'minutes').format('HH:mm');
                // }

                console.log('deliveryEnd', deliveryEnd);
                console.log('newDeliveryEnd', newDeliveryEnd);
                console.log('changedDeliveryEnd', changedDeliveryEnd);
                console.log('=========');

                // if (deliveryEnd !== oldOrderTimeIntervalDelivery) {
                //     newDeliveryEnd = changedDeliveryEnd;
                // }

                // newDeliveryEnd = changedDeliveryEnd;

                const workingHoursCityData = { begin, end, deliveryEnd: newDeliveryEnd };

                if (terminal.workingHours.length > 1) {
                    workingHoursCityData['dayNumber'] = index + 1;
                }

                workingHoursCity.push(workingHoursCityData);
            });
            console.log('workingHoursCity', workingHoursCity);
            daliveryTerminalsData[index].workingHours = workingHoursCity;
        });

        
        if (data.orderTimeIntervalDelivery !== checkoutScreen.orderTimeIntervalDelivery) {
            props.firebase.bd.ref(`${isDebug ? 'debug' : ''}/settings/city/${currentCity}/brands/${currentBrand}/checkoutScreen`).update(sendData)
            .then(res => {
                dispatch(updateLoadControl(sendData));
                
                props.firebase.bd.ref(`${isDebug ? 'debug' : ''}/settings/city/${currentCity}/brands/${currentBrand}/deliveryTerminals`).update(daliveryTerminalsData)
                    .then(res => {
                        // dispatch(updateShops(dataCities));
                        addKeyShop(dispatch, null);
                    })
                    .catch(error => console.error(error))
            })
            .catch(err => console.error(err));
        }
    }

    return (    
        <div className={classes.root}>
            <ModalComponent
                title="Подтвердите действие!"
                description={`Вы подтверждаете сохранение изменений`} 
                open={openModal} 
                handleClose={handleClose} 
                handleClickSubmit={handleClickSubmit} />
            {isMin600 && <Typography className={classes.titleMobile} variant="h1">Контроль нагрузки</Typography>}
            {isMin600 && (
                <div className={classes.tabContent}>
                    <TableForm 
                        onSubmit={onSubmit}
                        preloader={props.preloader} 
                        initialValues={initialValues} />
                </div>
            )}
            {isMax600 && (
                <div className={classes.desctopWrapper}>
                    <Typography className={classes.title} variant="h1">Контроль нагрузки</Typography>
                    <TableForm 
                        onSubmit={onSubmit}
                        preloader={props.preloader} 
                        initialValues={initialValues} />
                </div>
            )}
        </div>
    )
}

export default withRouter(withFirebase(WorkTimeComponent));