import React from "react";

import { Switch, Route } from "react-router-dom";
import { withAuthentication } from "./sessions";

import * as ROUTES from "./constants/routes";
import DefaultTemplate from "./scenes/defaultScreen/";
import SignInPage from "./scenes/auth/authContainer";

const MainRouter = () => {
	return (
		<Switch>
			<Route path={ROUTES.DEFAULT} render={() => <DefaultTemplate />} />
			<Route path={ROUTES.SIGN_IN} render={() => <SignInPage />} />
			<Route path={"*"} render={() => <DefaultTemplate />} />
		</Switch>
	);
};

export default withAuthentication(MainRouter);
