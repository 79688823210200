import React, { useState, useEffect } from 'react';
import moment from 'moment';
import TableForm from './TableForm';

let TableFormContainer = (props) => {
    const [open, setOpen] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [sendData, setSendData] = useState(null);
    // const [isDirty, setIsDirty] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
        setIsLoading(true);
    };

    const handleClose = () => {
        setOpen(false);
        setIsSubmit(false);
    };

    const handleClickSubmit = () => {
        setOpen(false);
        setIsSubmit(true);
    };

    useEffect(() => {
        if (isLoading && isSubmit && !open) {
            let workingHours = {};
            let count = 0;

            for (let day in sendData.days) {
                const time = sendData.days[day];
                const time1 = String(time).slice(0, 4);
                const time2 = String(time).slice(4, 8);
                let endTime = sendData.endTime[day].split(':');
                
                let firstTime = time1 !== '0000' ? moment(time1, 'kkmm').format('kk:mm'): '00:00';
                let secondTime = time2 !== '0000' ? moment(time2, 'kkmm').format('kk:mm'): '00:00';
                let deliveryEnd = moment(sendData.endTime[day], 'kkmm').format('kk:mm');
                let dayNumber = count + 1;

                // console.log('endTimeFormat *** === >>>', endTimeFormat);

                workingHours[count] = {
                    begin: firstTime,
                    end: secondTime,
                    deliveryEnd,
                    dayNumber,
                }
                count += 1;
            }

            props.onSubmit(workingHours);
            console.log('Вы подтвердили');
        }
    
        if (isLoading && !isSubmit && !open) {
            console.log('Вы отменили');
        }
    
    }, [isSubmit, open, isLoading]);

    return (
        <TableForm
            open={open}
            setSendData={setSendData}
            handleClickOpen={handleClickOpen}
            handleClose={handleClose}
            handleClickSubmit={handleClickSubmit}
            {...props}
        />
    )
}


export default TableFormContainer;