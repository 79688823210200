import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import ProductsForm from "./productsForm";
import { withFirebase } from "../../../firebase";
import { withRouter, useParams } from "react-router-dom";
import Preloader from "../preloader/preloader";
import axios from "axios";
import { updateProductsDispatch } from "../../../reducers/shops";

const AddProduct = (props) => {
	const dispatch = useDispatch();
	const login = useSelector((state) => state.auth.login);
	const defaultLanguage = useSelector((state) => state.shops.defaultLanguage);
	const languages = useSelector((state) => state.shops.languages);
	const cities = useSelector((state) => state.shops.shops);
	// const categories = useSelector((state) => state.shops.categories);

	const [sortLanguages, setSortLanguages] = useState([]);
	const [citiesSpot, setCitiesSpot] = useState(null);
	const [preloader, setPreloader] = useState(false);
	const [isOpenCities, setIsOpenCities] = useState(false);
	const [categories, setCategories] = useState([]);

	const brand = JSON.parse(localStorage.getItem("currentBrand"));
	const isDebug = JSON.parse(localStorage.getItem("isDebug"));
	const { brandId } = useParams();

	useEffect(() => {
		getLanguages();
	}, []);

	useEffect(() => {
		getCategories(brandId);
	}, [brandId]);

	const getCategories = (brandId) => {
		props.firebase.bd
			.ref(`${isDebug ? "debug" : ""}/EAppCRM/${brandId}`)
			.once("value", (snapshot) => {
				let val = snapshot.val();

				const nomenclature = val.nomenclature;

				const categories = nomenclature
					? nomenclature.categories.map((item) => ({
							...item,
							id: item.categoryId,
					  }))
					: [];

				setCategories(categories);
			});
	};

	const getLanguages = () => {
		let data = [...languages];

		if (data.length > 0 && data) {
			data = data.sort((a, b) => {
				if (a.key !== defaultLanguage) {
					return 1;
				} else {
					return -1;
				}
			});
		}

		setSortLanguages(data);
	};

	const handleChangeCitiesSpot = (data) => setCitiesSpot(data);
	const handleIsOpenCities = () => setIsOpenCities(!isOpenCities);

	const createProduct = (data) => {
		let languagesName = {};
		let languagesDescription = {};

		for (let index in sortLanguages) {
			let key = sortLanguages[index].key;
			let name = "name_" + key;
			let description = "description_" + key;

			if (data[name]) {
				languagesName[key] = data[name];
			}

			if (data[description]) {
				languagesDescription[key] = data[description];
			}
		}

		let spots = {};
		let allTerminals = brand.deliveryTerminals;

		// cities.map((city) => allTerminals.push(...city.deliveryTerminals));

		allTerminals.map((terminal, index) => {
			let spot = {
				price: "0",
				spot_id: terminal.id,
				visible: "0",
			};
			spots[index] = spot;
		});

		for (let key in spots) {
			const terminal = spots[key];

			// console.log("terminal", terminal);
			// console.log("citiesSpot", citiesSpot);
			// console.log("isOpenCities", isOpenCities);

			citiesSpot.map(({ id, visible }, index) => {
				if (String(terminal.spot_id) === String(id)) {
					console.log("### init ###");

					let spot = {
						price:
							data.firstPrice && !isOpenCities
								? String(Math.round(data.firstPrice * 100))
								: String(Math.round(data.terminals[terminal.spot_id] * 100)),
						oldPrice: data.oldPrice
							? String(Math.round(data.oldPrice * 100))
							: "",
						spotId: id,
						visible,
					};

					spots[key] = spot;
				}
			});
		}

		const productId = uuidv4();

		setPreloader(true);
		let product = {
			categoryId: categories[data.categories].categoryId,
			name: languagesName,
			description: languagesDescription,
			spots,
			productId,
			order: 0,
		};

		return product;
	};

	const onSubmit = (data) => {
		// console.log('data', data);

		let product = createProduct(data);

		let base_url =
			window.location.hostname == "localhost:3000"
				? "localhost"
				: window.location.hostname;

		let file = new FormData();
		file.append("photo", data.photo[0]);
		file.append("login", login);
		axios
			.post(`https://e-admin.com.ua/photo/index.php`, file)
			.then((res) => {
				setPreloader(false);

				const namePhoto = res.data.image_name.split(" ")[0];
				const isSuccess = res.data.check_upload_image;
				console.log("res ---- >>> ", res, isSuccess);

				product.photo = `https://e-admin.com.ua/photo/photo/uploads/${login}/${namePhoto}`;

				if (isSuccess) {
					setPreloader(true);
					props.firebase.bd
						.ref(
							`${isDebug ? "debug" : ""}/EAppCRM/${
								brand.id
							}/nomenclature/products`
						)
						.once("value")
						.then((snapshot) => {
							const products = snapshot.val() || [];

							products.push(product);

							props.firebase.bd
								.ref(
									`${isDebug ? "debug" : ""}/EAppCRM/${
										brand.id
									}/nomenclature/products`
								)
								.set(products)
								.then(() => {
									updateProductsDispatch(dispatch, products);
									alert("Готово!");
									props.history.push(
										`/page/menu/products/${brand.id}/edit/category${product.categoryId}`
									);
								})
								.catch((error) => {
									console.error("Error adding product:", error);
								});
						})
						.catch((error) => {
							console.error("Error retrieving products:", error);
							setPreloader(false);
						});
				} else {
					alert("Ошибка, попробуйте ещё раз!");
					setPreloader(false);
				}
			})
			.catch((error) => {
				alert("Ошибка, попробуйте ещё раз!!!");
				console.error(error);
				// setPreloader(false);
			});
	};

	return (
		<>
			<ProductsForm
				firebase={props.firebase}
				onSubmit={onSubmit}
				handleChangeCitiesSpot={handleChangeCitiesSpot}
				handleIsOpenCities={handleIsOpenCities}
				isOpenCities={isOpenCities}
				cities={cities}
				categories={categories}
				defaultLanguage={defaultLanguage}
				languages={sortLanguages}
			/>
			{preloader ? <Preloader /> : null}
		</>
	);
};

export default withFirebase(withRouter(AddProduct));
