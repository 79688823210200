import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { updateColumnsList } from '../../../reducers/clientTable'

import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import ListItemText from '@material-ui/core/ListItemText';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const SelectComponent = ({ name, options }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const columns = useSelector(state => state.clientTable.columns);

    const handleChange = (event) => {
        dispatch(updateColumnsList(event.target.value))
    };

    return (
        <FormControl className={classes.formControl}>
            <Select
                labelId={`${name}-mutiple-checkbox-label`}
                id={`${name}-mutiple-checkbox`}
                multiple
                value={columns}
                onChange={handleChange}
                input={<Input classes={{ inputRoot: classes.root }} />}
                renderValue={(selected) => 'Столбцы'}
                MenuProps={MenuProps}
            >
                {options.map((name, index) => (
                    <MenuItem disabled={index === 0} key={name} value={name}>
                        <Checkbox color="primary" checked={columns.indexOf(name) > -1} />
                        <ListItemText primary={name} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        // 
    },
    icon: {
        color: 'white',
        right: '10px',
    },
     formControl: {
        border: `1px solid ${theme.palette.primary.main}`,
        background: 'white',
        borderRadius: 5,
        boxSizing: 'border-box',
        height: 45,
        '& > div': {
            color: theme.palette.primary.main,
            padding: '0 12px 0 12px',
            height: 45,
        },
        '& > div > svg.MuiSelect-icon': {
            color: theme.palette.primary.main,
            right: 10,
        },
        '& > div > div.MuiSelect-select.MuiSelect-select': {
            // paddingRight: 0,
            background: 'white',
        },
         '& > div:after': {
             content: 'none',
         },
         '& > div:before': {
             content: 'none',
         },
     },
     inputRoot: {
         padding: '15px',
     }
}));

export default SelectComponent;