import React, { useState, useRef, useEffect } from "react";
import { useSelector, connect } from "react-redux";
import { Input, File, Textarea, Select } from "../ui/Input/";
import s from "./products.module.css";
import {
	reduxForm,
	reset,
	Field,
	isValid,
	isDirty,
	formValueSelector,
} from "redux-form";
import { required, numericality } from "redux-form-validators";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Popover from "../../../components/Popover";

import useStyles from "./styles";

import { validateImage, trim } from "./other";

let ProductsForm = (props) => {
	const { defaultLanguage, languages, categories } = props;
	const classes = useStyles();
	const inputEl = useRef(null);

	const [tabActive, setTabActive] = useState(0);
	const [selectedCategory, setSelectedCategory] = useState(null);
	const isOneCity = props.cities.length === 1;
	// const isOneTerminals =
	// 	isOneCity && props.cities[0].deliveryTerminals.length === 1;
	const isOneTerminals = isOneCity && props.terminals?.length === 1;
	console.log("props *** ", props);
	const [filtredTerminals, setFiltredTerminals] = useState([]);
	const [isValid, setIsValid] = useState(null);
	const [defaultPrice, setDefaultPrice] = useState(0);

	const [categoriesOrder, setCategoriesOrder] = useState([]);

	const isSalesPrice = useSelector(
		(state) => state.shops.adminPermission.salesProductsPrice
	);

	const handleTabClick = (count) => {
		let title = languages
			.find((item) => item.key === defaultLanguage)
			.label.split(" ");
		title = title[title.length - 1];
		if (count === 0) {
			setTabActive(count);
		}

		if (isValid) {
			setTabActive(count);
		} else {
			if (count !== 0) {
				alert(`Сначала добавьте товар на ${title} языке`);
			}
		}
	};
	const { isOpenCities, handleIsOpenCities } = props;

	const filterTerminals = () => {
		const filtredTerminals = [];
		let categorySpots = {};
		let categorySpotsData = categories[selectedCategory].spots;

		for (let key in categorySpotsData) {
			const categorySpot = categorySpotsData[key];

			if (categorySpot.visible === "1") {
				categorySpots[key] = categorySpot;
			}
		}

		for (let key in categorySpots) {
			const categorySpot = categorySpots[key];

			props.terminals.map((terminal) => {
				if (String(terminal.id) === String(categorySpot.spotId)) {
					filtredTerminals.push({ ...terminal, visible: categorySpot.visible });
					// terminal = { ...terminal, visible: "1" };
				}
			});
		}

		return filtredTerminals;
	};

	useEffect(() => {
		if (selectedCategory) {
			let isLoad = false;
			const categorySpots = categories[selectedCategory].spots;

			if (filtredTerminals.length === 0) {
				setFiltredTerminals(filterTerminals());
			}

			filtredTerminals.map((filtredTerminal) => {
				for (let key in categorySpots) {
					const categorySpot = categorySpots[key];

					if (filtredTerminal.id !== categorySpot.spot_id) {
						isLoad = true;
					}
				}
			});

			if (isLoad) {
				console.log("--load--");
				setFiltredTerminals(filterTerminals());
				isLoad = false;
			}
		}
	}, [selectedCategory, props.terminals]);

	useEffect(() => {
		setIsValid(props.valid);
	}, [props.valid]);

	useEffect(() => {
		if (
			!!props.selectedCategory &&
			selectedCategory !== props.selectedCategory
		) {
			setSelectedCategory(props.selectedCategory);
		}
	}, [selectedCategory, props.selectedCategory]);

	const handlePriceChange = (e) => {
		let val = e.target.value;

		if (defaultPrice !== val) {
			setDefaultPrice(val);
		}
	};

	const handleCheckboxChange = (e) => {
		let arr = [...filtredTerminals];
		let id = e.target.id;
		// let index = citiesState.findIndex(item => item.spot_id === id);
		let item = filtredTerminals[id];

		item = {
			...item,
			visible: item.visible == "0" ? "1" : "0",
		};

		arr[id] = item;

		setFiltredTerminals(arr);
	};

	const handleSubmitForm = (handleSubmit) => {
		const { handleChangeCitiesSpot } = props;

		handleChangeCitiesSpot(filtredTerminals);
		setSelectedCategory(null);
		setTabActive(0);
		// handleSubmit();

		// setDefaultPrice('');
		// reset('ProductsForm');
		// initCitiesState();
	};

	// const getCategoriesOrderData = () => {
	// 	const keyBrand = JSON.parse(localStorage.getItem("chosenBrand"));
	// 	const keyCity = JSON.parse(localStorage.getItem("chosenCity"));
	// 	const isDebug = JSON.parse(localStorage.getItem("isDebug"));

	// 	props.firebase.bd
	// 		.ref(
	// 			`${
	// 				isDebug ? "debug" : ""
	// 			}/settings/city/${keyCity}/brands/${keyBrand}/category/categoriesOrder`
	// 		)
	// 		.once("value", (snapshot) => {
	// 			const categoriesOrderData = snapshot.val();
	// 			setCategoriesOrder(categoriesOrderData);
	// 		});
	// };

	// useEffect(() => {
	// 	getCategoriesOrderData();
	// }, []);

	// const prepareCategories = (categories) => {
	// 	const sortedCategories = [];
	// 	for (const categoryId of categoriesOrder) {
	// 		const category = categories.find((cat) => cat.categoryId === categoryId);
	// 		if (category) {
	// 			sortedCategories.push({
	// 				id: category.categoryId,
	// 				name: category.name.uk,
	// 				...category,
	// 			});
	// 		}
	// 	}

	// 	if (sortedCategories.length > 0) return sortedCategories;

	// 	return categories;
	// };

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Paper className={s.modal}>
					<div className={s.wrapper}>
						<div className={s.title}>Добавить товар</div>
						<form
							className={s.formContainer}
							autoComplete="off"
							onSubmit={props.handleSubmit}
							initialValues={props.initialValues}
						>
							<Field
								trigger={"addProduct"}
								className={s.field}
								name={"categories"}
								defaultLanguage={props.defaultLanguage}
								option={props.categories}
								// citiesState={citiesState}
								selectedCategory={selectedCategory}
								setSelectedCategory={setSelectedCategory}
								component={Select}
								label={"Категория"}
								validate={required({
									msg: "Это поле обязательно для заполнения",
								})}
							/>

							<div className={classes.tabs}>
								{languages.map((item, index) => {
									let name = item.label.split(" ");
									name = name[name.length - 1];
									name = name.replace("ом", "ий");
									return (
										<div
											style={{
												background: tabActive === index ? "#326dff" : null,
											}}
											onClick={() => handleTabClick(index)}
											className={classes.tab}
										>
											{name}
										</div>
									);
								})}
							</div>

							<div className={classes.tabsContent}>
								{languages.map((item, index) => {
									let name = "name" + "_" + item.key;
									let description = "description" + "_" + item.key;
									return (
										<div
											style={{
												display: tabActive === index ? "block" : "none",
											}}
										>
											<Field
												name={name}
												component={Input}
												label="Название"
												validate={
													index === 0 &&
													required({
														msg: "Это поле обязательно для заполнения",
													})
												}
											/>
											<Field
												className={s.field}
												name={description}
												component={Textarea}
												label="Описание"
											/>
										</div>
									);
								})}
							</div>
							<Field
								name={`firstPrice`}
								typeField="text"
								component={Input}
								label={"Цена"}
								value={defaultPrice}
								onChange={handlePriceChange}
								disabled={isOpenCities}
								validate={
									!isOpenCities
										? [
												required({
													msg: "Это поле обязательно для заполнения",
												}),
												numericality({
													msg: "Поддерживаються только целые числа",
												}),
										  ]
										: null
								}
								type={"number"}
							/>
							{isSalesPrice && (
								<Field
									name={`oldPrice`}
									typeField="text"
									component={Input}
									label={"Цена без скидки (необязательное поле)"}
									value={""}
									type={"number"}
								/>
							)}
							<Field
								name={`photo`}
								component={File}
								ref={inputEl}
								label={"Фото"}
								validate={validateImage}
								type={"file"}
							/>

							{!isOneTerminals && (
								<>
									<div className={classes.openCitiesCheckbox}>
										<FormControlLabel
											control={
												<Checkbox
													checked={isOpenCities}
													onChange={handleIsOpenCities}
												/>
											}
											label="Разные цены в заведениях"
											className={s.citiesLabel}
										/>
									</div>
									<div
										className={classes.isOpenCities}
										style={{
											display: isOpenCities ? "block" : "none",
										}}
									>
										<div className={classes.headerWrapper}>
											<div className={classes.shop}>Заведение:</div>
											<div className={classes.price}>Цена:</div>
										</div>
										{filtredTerminals.map((item, index) => {
											return (
												<div className={classes.citiesPrice}>
													<div className={classes.citiesName}>
														<div className={classes.popoverWrapper}>
															<strong className={classes.nameLine}>
																{trim(item.name, 100)}
															</strong>
														</div>
													</div>
													<div className={classes.leftPanel}>
														<div className={classes.citiesFields}>
															<Field
																name={`terminals.${item.id}`}
																className={classes.citiesField}
																component={Input}
																defaultValue={defaultPrice}
																// label={'Цена*'}
																// type={'number'}
															/>
														</div>
														<div className={classes.citiesCheckbox}>
															<FormControlLabel
																control={
																	<Checkbox
																		id={index}
																		checked={item.visible === "1"}
																		onChange={handleCheckboxChange}
																	/>
																}
																label="Отображать"
																className={s.citiesLabel}
															/>
														</div>
													</div>
												</div>
											);
										})}
									</div>
								</>
							)}
							<div className={s.buttonWrapper}>
								<Button
									onClick={() => handleSubmitForm(props.handleSubmit)}
									type="submit"
								>
									Добавить
								</Button>
							</div>
						</form>
					</div>
				</Paper>
			</Grid>
		</Grid>
	);
};

const afterSubmit = (result, dispatch) => dispatch(reset("ProductsForm"));

const selector = formValueSelector("ProductsForm");
const mapStateToProps = (state, props) => {
	const initialValues = {
		terminals: {},
	};
	// const terminals = [];

	const keyCity = Number(JSON.parse(localStorage.getItem("chosenCity")));
	const keyBrand = Number(JSON.parse(localStorage.getItem("chosenBrand")));

	// const cities = state.shops.shops;

	// cities.map((city) => {
	// 	city.deliveryTerminals.map((terminal) => {
	// 		terminals.push({ ...terminal, cityId: city.spot_id });
	// 	});
	// });
	// const cities = state.shops.shops;

	const brand = state.shops?.shops[keyCity]?.brands[keyBrand];

	const terminals = brand?.deliveryTerminals.map((terminal) => ({
		...terminal,
		brandId: brand.id,
	}));

	terminals &&
		terminals.map((terminal, index) => {
			initialValues.terminals[terminal.id] = "0";
		});

	return {
		initialValues,
		terminals,
		selectedCategory: selector(state, "categories"),
		dirty: isDirty("ProductsForm")(state),
		valid: isValid("ProductsForm")(state),
	};
};

export default connect(
	mapStateToProps,
	null
)(
	reduxForm({
		form: "ProductsForm",
		enableReinitialize: true,
		onSubmitSuccess: afterSubmit,
	})(ProductsForm)
);
