import React, { useState, useEffect } from "react";
import Sales from ".";
import { withFirebase } from "../../../firebase";

const SalesContainer = (props) => {
	const [sales, setSales] = useState([]);
	const [preloader, setPreloader] = useState(true);
	const [isNewAdmin, setIsNewAdmin] = useState(false);

	useEffect(() => {
		// Проверка прав администратора и установка значения
		const adminPermission = JSON.parse(localStorage.getItem("adminPermission"));
		const newAdminStatus = adminPermission?.newAdmin || false;
		setIsNewAdmin(newAdminStatus);

		// Функция для получения данных о продажах
		const getSales = () => {
			const currentCity = JSON.parse(localStorage.getItem("chosenCity"));
			const currentBrand = JSON.parse(localStorage.getItem("chosenBrand"));

			// Определяем путь в зависимости от типа администратора
			const refPath = isNewAdmin
				? `settings/city/${currentCity}/brands/${currentBrand}/loyaltySettings/salesSystem`
				: `settings/city/${currentCity}/loyaltySettings/salesSystem`;

			props.firebase.bd.ref(refPath).on("value", (snapshot) => {
				let salesData = snapshot.val();
				console.log(isNewAdmin);
				console.log(salesData);
				salesData = salesData ? salesData : [];

				setSales(salesData);
				setPreloader(false);
			});
		};

		getSales();

		return () => {
			props.firebase.bd.ref().off();
		};
	}, [props.firebase, isNewAdmin]);

	const updateSaleStatus = (index, isActive) => {
		const currentCity = JSON.parse(localStorage.getItem("chosenCity"));
		const currentBrand = JSON.parse(localStorage.getItem("chosenBrand"));

		const refPath = isNewAdmin
			? `settings/city/${currentCity}/brands/${currentBrand}/loyaltySettings/salesSystem`
			: `settings/city/${currentCity}/loyaltySettings/salesSystem`;

		const updatedSales = sales.map((sale, i) =>
			i === index ? { ...sale, isActive } : sale
		);

		// Обновляем данные в Firebase
		props.firebase.bd.ref(refPath).set(updatedSales, (error) => {
			if (error) {
				console.error("Ошибка при обновлении данных:", error);
			} else {
				setSales(updatedSales);
			}
		});
	};

	return (
		<div>
			{preloader ? (
				<></>
			) : (
				<Sales sales={sales} updateSaleStatus={updateSaleStatus} />
			)}
		</div>
	);
};

export default withFirebase(SalesContainer);
