export const checkFields = (data) => {
    let newData = {}

    if (data.hasOwnProperty('name')) newData.name = data.name;
    if (data.hasOwnProperty('delivery')) newData.delivery = Number(data.delivery);
    if (data.hasOwnProperty('deliveryLimit')) newData.deliveryLimit = Number(data.deliveryLimit);
    if (data.hasOwnProperty('deliveryItemId')) newData.deliveryItemId = String(data.deliveryItemId);
    if (data.hasOwnProperty('deliveryFreeLimit')) newData.deliveryFreeLimit = Number(data.deliveryFreeLimit);

    return newData;
}

export const getInitialValues = (props) => {
    const {deliveryZone, shops, delivery} = props;

    let initialValues = {};

    if (delivery === 1) {
        initialValues = {
            delivery: shops.delivery,
            deliveryFreeLimit: shops.deliveryFreeLimit,
            deliveryLimit: shops.deliveryLimit,
        }
    }

    return initialValues;
}