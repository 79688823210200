import React, { Component } from "react";
import { connect } from "react-redux";
import { withFirebase } from "../../../firebase";

import Categories from "./categories";

import { updateVisibility } from "../../../reducers/shops";

class CategoriesContainer extends Component {
	constructor() {
		super();

		this.state = {
			adminPermission: {},
			products: [],
			hiddenProducts: [],
			hiddenCategories: [],
			hiddenModifierGroups: [],
			hiddenProductModification: [],
			stopList: [],
			preloader: false,
			isActiveCategoriesSnackbar: false,
			isActiveMenusSnackbar: false,
			isActiveModifierGroupSnackbar: false,
			isActiveProductModificationSnackbar: false,
			isActiveStopListSnackbar: false,
		};
	}

	componentDidMount() {
		this.getAdminPermission();
		this.getProducts();
	}

	getAdminPermission = () => {
		this.setState({ preloader: true });
		this.props.firebase.bd
			.ref(
				`${
					JSON.parse(localStorage.getItem("isDebug")) ? "debug" : ""
				}/adminPermission`
			)
			.once("value", (snapshot) => {
				let adminPermission = snapshot.val();

				if (adminPermission) {
					this.setState({ adminPermission, preloader: false });
				}

				this.setState({ preloader: false });
			});
	};

	getProducts = () => {
		this.setState({ preloader: true });
		this.props.firebase.bd
			.ref(
				`${JSON.parse(localStorage.getItem("isDebug")) ? "debug" : ""}/products`
			)
			.once("value", (snapshot) => {
				let products = snapshot.val();

				if (products) {
					this.setState({ products, preloader: false });
				}

				this.setState({ preloader: false });
			});
	};

	handleCategoriesSnackbar() {
		this.setState({ isActiveCategoriesSnackbar: true });

		setTimeout(() => {
			this.setState({ isActiveCategoriesSnackbar: false });
		}, 3000);
	}

	handleMenusSnackbar() {
		this.setState({ isActiveMenusSnackbar: true });

		setTimeout(() => {
			this.setState({ isActiveMenusSnackbar: false });
		}, 3000);
	}

	handleModifiersGroupSnackbar() {
		this.setState({ isActiveModifierGroupSnackbar: true });

		setTimeout(() => {
			this.setState({ isActiveModifierGroupSnackbar: false });
		}, 3000);
	}

	handleProductModifiersSnackbar() {
		this.setState({ isActiveProductModificationSnackbar: true });

		setTimeout(() => {
			this.setState({ isActiveProductModificationSnackbar: false });
		}, 3000);
	}

	handleStopListSnackbar() {
		this.setState({ isActiveStopListSnackbar: true });

		setTimeout(() => {
			this.setState({ isActiveStopListSnackbar: false });
		}, 3000);
	}

	updateVisibility = (list, params) => {
		console.log("updateVisibility", list, params);

		if (params === "hiddenCategories") {
			this.props.handleCategoriesMenu(true);
		} else if (params === "hiddenModifierGroups") {
			this.props.handleModifierGroupsMenu(true);
		} else if (params === "isActiveProductModificationSnackbar") {
			this.props.handleProductModificationMenu(true);
		} else {
			this.props.handleProductsMenu(true);
		}

		console.log("list, params === >>>", list, params);

		this.props.updateVisibility({ list, params });
	};

	changeVisibleProduct = (products) => {
		this.setState({ preloader: true });
		this.props.firebase.bd
			.ref(
				`${JSON.parse(localStorage.getItem("isDebug")) ? "debug" : ""}/products`
			)
			.update(products)
			.then((res) => {
				this.setState({
					preloader: false,
				});
			});
	};

	saveData = (type) => {
		const isCategories = type === "hiddenCategories" ? true : false;
		const isProducts = type === "hiddenProducts" ? true : false;
		const isModifierGroups = type === "hiddenModifierGroups" ? true : false;
		const isProductModification =
			type === "hiddenProductModification" ? true : false;

		const adminPermission = JSON.parse(localStorage.getItem("adminPermission"));

		const currentCity = JSON.parse(localStorage.getItem("chosenCity"));
		const currentBrand = JSON.parse(localStorage.getItem("chosenBrand"));
		const isDebug = JSON.parse(localStorage.getItem("isDebug"));

		if (adminPermission && adminPermission.newAdmin === true) {
			console.log("new");
			this.props.firebase.bd
				.ref(
					`${
						isDebug ? "debug" : ""
					}/settings/city/${currentCity}/brands/${currentBrand}/category/${type}`
				)
				.set(this.props[type])
				.then((res) => {
					this.setState({ preloader: false });
					if (isCategories) {
						this.props.updateVisibility({
							list: this.props.hiddenCategories,
							params: "hiddenCategories",
						});
						this.handleCategoriesSnackbar();
						this.props.handleCategoriesMenu(false);
						this.props.getDefaultHiddenCategories();
					} else if (isProducts) {
						this.props.updateVisibility({
							list: this.props.hiddenProducts,
							params: "hiddenProducts",
						});
						this.handleMenusSnackbar();
						this.props.handleProductsMenu(false);
						this.props.getDefaultHiddenProducts();
					} else if (isModifierGroups) {
						this.props.updateVisibility({
							list: this.props.hiddenModifierGroups,
							params: "hiddenModifierGroups",
						});
						this.handleModifiersGroupSnackbar();
						this.props.handleModifierGroupsMenu(false);
						this.props.getDefaulHiddenModifierGroups();
					} else if (isProductModification) {
						this.props.updateVisibility({
							list: this.props.hiddenProductModification,
							params: "hiddenProductModification",
						});
						this.handleProductModifiersSnackbar();
						this.props.handleProductModificationMenu(false);
						this.props.getDefaultHiddenProductModification();
					} else {
						// this.props.updateVisibility({list: this.props.stopList, params: 'stopList'});
						this.handleStopListSnackbar();
					}
				});
		} else {
			this.props.firebase.bd
				.ref(
					`${
						isDebug ? "debug" : ""
					}/settings/city/${currentCity}/brands/${currentBrand}/category/${type}`
				)
				.set(this.props[type])
				.then((res) => {
					this.setState({ preloader: false });
					if (isCategories) {
						this.props.updateVisibility({
							list: this.props.hiddenCategories,
							params: "hiddenCategories",
						});
						this.handleCategoriesSnackbar();
						this.props.handleCategoriesMenu(false);
						this.props.getDefaultHiddenCategories();
					} else if (isProducts) {
						this.props.updateVisibility({
							list: this.props.hiddenProducts,
							params: "hiddenProducts",
						});
						this.handleMenusSnackbar();
						this.props.handleProductsMenu(false);
						this.props.getDefaultHiddenProducts();
					} else if (isModifierGroups) {
						this.props.updateVisibility({
							list: this.props.hiddenModifierGroups,
							params: "hiddenModifierGroups",
						});
						this.handleModifiersGroupSnackbar();
						this.props.handleModifierGroupsMenu(false);
						this.props.getDefaulHiddenModifierGroups();
					} else if (isProductModification) {
						this.props.updateVisibility({
							list: this.props.hiddenProductModification,
							params: "hiddenProductModification",
						});
						this.handleProductModifiersSnackbar();
						this.props.handleProductModificationMenu(false);
						this.props.getDefaultHiddenProductModification();
					} else {
						// this.props.updateVisibility({list: this.props.stopList, params: 'stopList'});
						this.handleStopListSnackbar();
					}
				});
		}
	};

	render() {
		return (
			<>
				<Categories
					adminPermission={this.state.adminPermission}
					hiddenCategories={this.props.hiddenCategories}
					hiddenProducts={this.props.hiddenProducts}
					hiddenModifierGroups={this.props.hiddenModifierGroups}
					hiddenProductModification={this.props.hiddenProductModification}
					stopList={this.props.stopList}
					products={this.state.products}
					changeVisibleProduct={this.changeVisibleProduct}
					updateVisibility={this.updateVisibility}
					loadStatus={this.state.preloader}
					isActiveCategoriesSnackbar={this.state.isActiveCategoriesSnackbar}
					isActiveMenusSnackbar={this.state.isActiveMenusSnackbar}
					isActiveModifierGroupSnackbar={this.isActiveModifierGroupSnackbar}
					isActiveStopListSnackbar={this.state.isActiveStopListSnackbar}
					saveData={this.saveData}
				/>
			</>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		shops: state.shops.shops,
		hiddenCategories: state.shops.hiddenCategories,
		hiddenProducts: state.shops.hiddenProducts,
		hiddenModifierGroups: state.shops.hiddenModifierGroups,
		hiddenProductModification: state.shops.hiddenProductModification,
		stopList: state.shops.stopList,
	};
};

export default connect(mapStateToProps, { updateVisibility })(
	withFirebase(CategoriesContainer)
);
