import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import CloseIcon from '@material-ui/icons/Close';
import CreateModalPromocodesForm from './CreateModalPromocodesForm';
import EditModalPromocodesForm from './EditModalPromocodesForm';

const DialogComponent = ({ 
    open,
    count,
    setCount, 
    handleClose,
    editPromocode, 
    onSubmit,
    dataForm,
    createPromocodeSubmit
}) => {
    const classes = useStyles();

    return (
        <Dialog classes={{
            paper: classes.dialogPaper,
        }} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <div className={classes.iconsWrapper}>
                <CloseIcon onClick={() => handleClose()} className={classes.closeIcon} />
                <DialogTitle className={classes.dialogTitle} id="form-dialog-title">
                    {editPromocode ? 'Редактировать промоакцию' : 'Создать промоакцию'}
                </DialogTitle>
            </div>
            <DialogContent className={classes.dialogContent}>
                {editPromocode 
                    ? <EditModalPromocodesForm 
                        id={editPromocode}
                        dataForm={dataForm} 
                        onSubmit={onSubmit} 
                        count={count} 
                        handleClose={handleClose}
                        setCount={setCount} /> 
                    : <CreateModalPromocodesForm 
                        // dataForm={dataForm} 
                        onSubmit={createPromocodeSubmit} 
                        count={count} 
                        handleClose={handleClose}
                        setCount={setCount} />  
                }
            </DialogContent>
        </Dialog>
    )
};

const useStyles = makeStyles(theme => ({
    dialogPaper: {
        width: '80%',
        maxWidth: 1057,
        padding: 24,
        [theme.breakpoints.down('600')]: {
            padding: 16,
            width: '100%',
            margin: '0 10px',
        },
    },
    dialogContent: {
        padding: 0,
    },
    dialogTitle: {
        padding: 0,
        '& h2': {
            fontSize: 16,
            fontWeight: 'normal',
            lineHeight: '25px',
        }
    },
    iconsWrapper: {
        display: 'flex',
    },
    closeIcon: {
        marginRight: 16,
        color: '#476282',
        '&:hover': {
            color: 'black',
            cursor: 'pointer',
        }
    },
}));

export default DialogComponent;