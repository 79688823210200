import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: 16,
    width: '100%',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  select: {
      '& .MuiSelect-selectMenu': {
        whiteSpace: 'pre-wrap',
      },
    zIndex: '9999',
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const  MultipleSelect = (props) => {
  const classes = useStyles();
  const cities = useSelector(state => state.shops.shops);
  const { selectedCities, setSelectedCities, changeCity } = props;
  const trim = (str, size) => str.length > size ? str.slice(0, size - 3) + '…' : str;

  const handleChange = (event) => {
    changeCity(event.target.value);
  };

  return (
    <div>
      <FormControl className={classes.formControl}>
        {/* <InputLabel id="mutiple-checkbox-label">Выберите город из списка</InputLabel>  */}
        <Select
          labelId="mutiple-checkbox-label"
          id="mutiple-checkbox"
          className={classes.select}
          multiple
          value={selectedCities}
          onChange={handleChange}
          onClose={props.changeSelectClose}
          onOpen={props.changeSelectOpen}
          input={<Input />}
          renderValue={(selected) => selected.map(name => <Chip key={name} label={name} className={classes.chip} />)}
          MenuProps={MenuProps}
        >
          {cities.map(({ key, id, address }) => 
            <MenuItem key={key} value={address}>
                <Checkbox checked={selectedCities.indexOf(address) > -1} color="primary" />
                <ListItemText primary={trim(address, 30)} />
            </MenuItem>
          )}
        </Select>
      </FormControl>
    </div>
  );
}

export default MultipleSelect;