import React from 'react';
import Promotion from '../promotion/promotionContainer';

const DefaultPage = () => {
    return (
        <div>
            <Promotion />
        </div>
    )
}

export default DefaultPage;