import React, { Component } from "react";
import Categories from "./categories";
import { withFirebase } from "../../firebase";
import Preloader from "./preloader/preloader";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
	updateCategories,
	updateProducts,
	updateVisibility,
	updateCategoriesOrder,
} from "../../reducers/shops";

class CategoriesContainer extends Component {
	constructor() {
		super();
		this.state = {
			categories: [],
			languages: [],
			hiddenCategories: [],
			defaultHiddenCategories: null,
			deliveryTerminals: null,
			deliveryTerminalsCheckBox: null,
			language: "",
			defaultLanguage: "",
			modal: false,
			preloader: false,
			activeCategory: null,
			languageModal: false,
		};
	}

	componentDidMount() {
		this.getLanguages();
		this.getCategories(this.props.match.params.brandId);
		this.getDefaultHiddenCategories();
		this.getDeliveryTerminalsByCity();
	}

	componentDidUpdate(prevProps) {
		// Проверяем, изменился ли параметр id в URL
		if (this.props.match.params.brandId !== prevProps.match.params.brandId) {
			// Выполняем необходимые действия при изменении id
			const newId = this.props.match.params.brandId;
			this.getCategories(newId);
		}
	}

	getDeliveryTerminalsByCity = () => {
		const cityId = "0";

		this.setState({ preloader: true });
		this.props.firebase.bd
			.ref(
				`${
					JSON.parse(localStorage.getItem("isDebug")) ? "debug" : ""
				}/settings/City/0/deliveryTerminals`
			)
			.once("value", (snapshot) => {
				let deliveryTerminals = snapshot.val();

				this.setState({ deliveryTerminals, preloader: false });
			});
	};

	handleDeliveryTerminalsCheckBox = (data) => {
		this.setState({ deliveryTerminalsCheckBox: data });
	};

	getDefaultHiddenCategories = () => {
		const currentCity = JSON.parse(localStorage.getItem("chosenCity"));
		const currentBrand = JSON.parse(localStorage.getItem("chosenBrand"));
		const isDebug = JSON.parse(localStorage.getItem("isDebug"));

		this.setState({ preloader: true });

		this.props.firebase.bd
			.ref(
				`${
					isDebug ? "debug" : ""
				}/settings/city/${currentCity}/brands/${currentBrand}/category/hiddenCategories`
			)
			.once("value", (snapshot) => {
				let defaultHiddenCategories = snapshot.val();

				this.setState({ defaultHiddenCategories, preloader: false });
			});
	};

	getLanguages = () => {
		let defaultLanguage = this.props.defaultLanguage;

		if (!defaultLanguage || defaultLanguage.length === 0) {
			this.setState({ preloader: true });
			this.props.firebase.bd
				.ref(
					`${
						JSON.parse(localStorage.getItem("isDebug")) ? "debug" : ""
					}/adminPermission/defaultLanguage`
				)
				.once("value", (snapshot) => {
					let val = snapshot.val();

					defaultLanguage = val;

					this.setState({ defaultLanguage, preloader: false });
				});
		}

		// let languages = this.props.languages;
		let languages = [];
		if (!languages || languages.length === 0) {
			this.setState({ preloader: true });
			this.props.firebase.bd
				.ref(
					`${
						JSON.parse(localStorage.getItem("isDebug")) ? "debug" : ""
					}/adminPermission/languages`
				)
				.once("value", (snapshot) => {
					let val = snapshot.val();

					languages = val.sort(function (a, b) {
						if (defaultLanguage) {
							if (a.key !== defaultLanguage) {
								return 1;
							} else {
								return -1;
							}
						}
					});

					this.setState({ languages, preloader: false });
				});
		} else {
			languages = languages.sort(function (a, b) {
				if (defaultLanguage) {
					if (a.key !== defaultLanguage) {
						return 1;
					} else {
						return -1;
					}
				}
			});
			this.setState({ languages });
		}
	};

	getCategories = (brandId) => {
		let languages = this.props.languages;
		this.setState({ preloader: true });

		this.props.firebase.bd
			.ref(
				`${
					JSON.parse(localStorage.getItem("isDebug")) ? "debug" : ""
				}/EAppCRM/${brandId}/nomenclature/categories`
			)
			.once("value", (snapshot) => {
				let categoriesData = snapshot.val();

				const categories = categoriesData
					? categoriesData.map((item) => ({
							...item,
							id: item.categoryId,
					  }))
					: [];

				let categoryList = categories;
				let list = categories;
				let languageList = [];

				this.setState({ categories: list, preloader: false });

				this.props.updateCategories(categoryList);
			});
	};

	hiddenCategory = (data, open) => {
		// console.log("data", data, open);
		const adminPermission = JSON.parse(localStorage.getItem("adminPermission"));
		const currentCity = JSON.parse(localStorage.getItem("chosenCity"));
		const currentBrand = JSON.parse(localStorage.getItem("chosenBrand"));
		const isDebug = JSON.parse(localStorage.getItem("isDebug"));

		if (adminPermission && adminPermission.newAdmin === true) {
			if (open) {
				this.setState({ preloader: true, defaultHiddenCategories: data });
				this.props.updateVisibility({ list: data, params: "hiddenCategories" });
				this.props.firebase.bd
					.ref(
						`${
							isDebug ? "debug" : ""
						}/settings/city/${currentCity}/brands/${currentBrand}/category/hiddenCategories`
					)
					.update(data)
					.then((res) => {
						this.setState({
							preloader: false,
						});
					});
			}

			if (!open) {
				this.setState({ preloader: true, defaultHiddenCategories: data });
				this.props.updateVisibility({ list: data, params: "hiddenCategories" });
				this.props.firebase.bd
					.ref(
						`${
							isDebug ? "debug" : ""
						}/settings/city/${currentCity}/brands/${currentBrand}/category/hiddenCategories`
					)
					.set(data)
					.then((res) => {
						this.setState({
							preloader: false,
						});
					});
			}
		} else {
			if (open) {
				this.setState({ preloader: true, defaultHiddenCategories: data });
				this.props.updateVisibility({ list: data, params: "hiddenCategories" });
				this.props.firebase.bd
					.ref(
						`${
							isDebug ? "debug" : ""
						}/settings/city/${currentCity}/brands/${currentBrand}/category/hiddenCategories`
					)
					.update(data)
					.then((res) => {
						this.setState({
							preloader: false,
						});
					});
			}

			if (!open) {
				this.setState({ preloader: true, defaultHiddenCategories: data });
				this.props.updateVisibility({ list: data, params: "hiddenCategories" });
				this.props.firebase.bd
					.ref(
						`${
							isDebug ? "debug" : ""
						}/settings/city/${currentCity}/brands/${currentBrand}/category/hiddenCategories`
					)

					.set(data)
					.then((res) => {
						this.setState({
							preloader: false,
						});
					});
			}
		}
	};

	onSubmit = (data) => {
		console.log("data onSubmit", data);

		const { deliveryTerminalsCheckBox, deliveryTerminals } = this.state;
		const isDebug = JSON.parse(localStorage.getItem("isDebug"));
		let spots = {};

		deliveryTerminals.map((item, index) => {
			if (!deliveryTerminalsCheckBox) {
				spots[item.id] = { visible: "1" };
			} else {
				spots[item.id] = {
					visible: !!deliveryTerminalsCheckBox[item.id] ? "1" : "0",
				};
			}
		});

		// console.log('spots', spots);

		let category = {
			icon: "default",
			spots,
			name: {
				ru: data.ru ? data.ru : null,
				uk: data.uk ? data.uk : null,
				en: data.en ? data.en : null,
				pl: data.pl ? data.pl : null,
			},
		};

		this.setState({ preloader: true });
		this.props.firebase.bd
			.ref(`${isDebug ? "debug" : ""}/categories`)
			.push(category)
			.then((res) => {
				let categories = {};

				for (let key in this.state.categories) {
					let item = this.state.categories[key];

					categories[item.id] = item;
				}

				let data = {
					...categories,
					[res.key]: {
						...category,
						id: res.key,
					},
				};

				console.log("data Submit productsContainer --->>>", data);

				this.props.updateCategories(data);
				this.getCategories();
				this.setState({ preloader: false });
			})
			.catch((error) => {
				console.error(error);
			});
		this.statusModal();
	};

	deleteCategory = (id, name) => {
		console.log("id, name", id, name);
		const brand = JSON.parse(localStorage.getItem("currentBrand"));
		const isDebug = JSON.parse(localStorage.getItem("isDebug"));
		if (window.confirm(`Вы хотите удалить категорию ${name}?`)) {
			if (
				window.confirm(
					`При удалении категории будут удалены все товары! Удалить?`
				)
			) {
				this.props.firebase.bd
					.ref(
						`${isDebug ? "debug" : ""}/EAppCRM/${
							brand.id
						}/nomenclature/categories`
					)
					.once("value")
					.then((snapshot) => {
						const categories = snapshot.val() || [];

						const updatedCategories = categories.filter(
							(item) => item.categoryId !== id
						);

						this.props.firebase.bd
							.ref(
								`${isDebug ? "debug" : ""}/EAppCRM/${
									brand.id
								}/nomenclature/products`
							)
							.once("value")
							.then((snapshot) => {
								const productList = snapshot.val() || [];

								const updatedProducts = productList.filter(
									(item) => item.categoryId !== id
								);

								console.log("productList **** ", productList);
								console.log("updatedProducts **** ", updatedProducts);

								this.props.firebase.bd
									.ref(
										`${isDebug ? "debug" : ""}/EAppCRM/${
											brand.id
										}/nomenclature/categories`
									)
									.set(updatedCategories)
									.then(() => {
										this.props.firebase.bd
											.ref(
												`${isDebug ? "debug" : ""}/EAppCRM/${
													brand.id
												}/nomenclature/products`
											)
											.set(updatedProducts)
											.then(() => {
												if (this.props.categories) {
													let categories = {};
													let products = {};

													for (let key in this.props.categories) {
														let item = this.props.categories[key];

														if (key !== id) {
															categories[key] = { ...item, id: key };
														}
													}

													for (let key in this.props.products) {
														const product = this.props.products[key];

														if (product.menuCategoryId !== id) {
															products[key] = product;
														}
													}

													const categoriesOrderData = updatedCategories.map(
														(item) => item.categoryId
													);

													const updHiddenCategories =
														categoriesOrderData.filter((item) =>
															this.props.hiddenCategories.includes(item)
														);

													const adminPermission = JSON.parse(
														localStorage.getItem("adminPermission")
													);

													const currentCity = JSON.parse(
														localStorage.getItem("chosenCity")
													);
													const currentBrand = JSON.parse(
														localStorage.getItem("chosenBrand")
													);

													if (
														adminPermission &&
														adminPermission.newAdmin === true
													) {
														this.props.firebase.bd
															.ref(
																`${
																	isDebug ? "debug" : ""
																}/settings/city/${currentCity}/brands/${currentBrand}/category/categoriesOrder`
															)
															.set(categoriesOrderData)
															.then((res) => {
																this.props.firebase.bd
																	.ref(
																		`${
																			isDebug ? "debug" : ""
																		}/settings/city/${currentCity}/brands/${currentBrand}/category/hiddenCategories`
																	)
																	.set(updHiddenCategories)
																	.then((res) => {
																		this.props.updateCategoriesOrder(
																			categoriesOrderData
																		);
																		this.props.updateCategories(categories);
																		this.props.updateProducts(products);
																		this.getCategories(brand.id);
																		this.setState({ preloader: false });
																	})
																	.catch((err) => {});
															})
															.catch((error) => {
																console.error(error);
															});
													} else {
														this.props.firebase.bd
															.ref(
																`${
																	isDebug ? "debug" : ""
																}/settings/city/${currentCity}/brands/${currentBrand}/category/categoriesOrder`
															)
															.set(categoriesOrderData)
															.then((res) => {
																this.props.firebase.bd
																	.ref(
																		`${
																			isDebug ? "debug" : ""
																		}/settings/city/${currentCity}/brands/${currentBrand}/category/hiddenCategories`
																	)
																	.set(updHiddenCategories)
																	.then((res) => {
																		this.props.updateCategoriesOrder(
																			categoriesOrderData
																		);
																		this.props.updateCategories(categories);
																		this.props.updateProducts(products);
																		this.getCategories(brand.id);
																		this.setState({ preloader: false });
																	})
																	.catch((err) => {});
															})
															.catch((error) => {
																console.error(error);
															});
													}
												}
											});
										alert("Готово");
									});
							});
					})
					.catch((res) => {
						console.log("res", res);
						alert("Перезагрузите страницу и попробуйте еще раз!");
					});

				const ref = this.props.firebase.bd.ref(
					`${isDebug ? "debug" : ""}/products`
				);
				var deleteAfterDate = ref.orderByChild("menuCategoryId").equalTo(id);
				deleteAfterDate
					.once("value")
					.then(function (snapshot) {
						var updates = {};
						snapshot.forEach(function (child) {
							updates[child.key] = null;
						});
						ref.update(updates);
					})
					.then((res) => {})
					.catch((res) => {});
			}
		}
	};

	editCategory = (data) => {
		console.log("editCategory", data);

		const { deliveryTerminalsCheckBox, deliveryTerminals } = this.state;
		const isDebug = JSON.parse(localStorage.getItem("isDebug"));

		let spots = [];

		deliveryTerminals.map((item, index) => {
			if (!deliveryTerminalsCheckBox) {
				spots[item.id] = { visible: "1" };
			} else {
				spots[item.id] = {
					visible: !!deliveryTerminalsCheckBox[item.id] ? "1" : "0",
				};
			}
		});

		// console.log('spots', spots);

		let categoryNew = {};

		for (let key in this.props.languages) {
			let languagesKey = this.props.languages[key].key;
			if (data[languagesKey]) {
				categoryNew[languagesKey] = data[languagesKey];
			}
		}

		let category = { name: categoryNew, spots, icon: "default" };

		this.setState({ preloader: true });
		this.props.firebase.bd
			.ref(`${isDebug ? "debug" : ""}/categories/${data.id}`)
			.update(category)
			.then((res) => {
				this.setState({ preloader: false });
				let categories = {};

				for (let key in this.props.categories) {
					let item = this.props.categories[key];

					if (key === data.id) {
						categories[key] = category;
					} else {
						categories[key] = item;
					}
				}

				this.props.updateCategories(categories);
				this.getCategories();
				this.setState({ preloader: false });
			})
			.catch((error) => {
				console.error(error);
			});
	};

	statusModal = () => {
		this.setState({
			modal: this.state.modal ? false : true,
		});
	};

	statusLanguageModal = () => {
		this.setState({
			languageModal: this.state.languageModal ? false : true,
		});
	};

	render() {
		// console.log("state (products) ->", this.state);
		// console.log('props (products) ->', this.props);
		return (
			<>
				<Categories
					onSubmit={this.onSubmit}
					categories={this.state.categories}
					activeCategory={this.state.activeCategory}
					hiddenCategories={this.props.hiddenCategories}
					handleCheckBox={this.handleDeliveryTerminalsCheckBox}
					defaultHiddenCategories={this.state.defaultHiddenCategories}
					deliveryTerminals={this.state.deliveryTerminals}
					hiddenCategory={this.hiddenCategory}
					languageModal={this.state.languageModal}
					languages={this.state.languages}
					firebase={this.props.firebase}
					defaultLanguage={
						this.props.defaultLanguage
							? this.props.defaultLanguage
							: this.state.defaultLanguage
					}
					statusLanguageModal={this.statusLanguageModal}
					deleteCategory={this.deleteCategory}
					editCategory={this.editCategory}
					modal={this.state.modal}
					statusModal={this.statusModal}
				/>
				{this.state.preloader ? <Preloader /> : null}
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		hiddenCategories: state.shops.hiddenCategories,
		defaultLanguage: state.shops.defaultLanguage,
		categories: state.shops.categories,
		products: state.shops.products,
		languages: state.shops.languages,
		categoriesOrder: state.shops.categoriesOrder,
	};
};

export default connect(mapStateToProps, {
	updateCategories,
	updateProducts,
	updateVisibility,
	updateCategoriesOrder,
})(withFirebase(withRouter(CategoriesContainer)));
