import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import CloseIcon from '@material-ui/icons/Close';
import AddBannerModalForm from './AddBannerModalForm';
import EditBannerModalForm from './EditBannerModalForm'

const DialogComponent = ({ 
    open,
    handleClose,
    isEditBanner, 
    addBannerSubmit,
    editBannerSubmit,
    images,
    setImages,
    editBannerIndex
}) => {
    const classes = useStyles();
    
    return (
        <Dialog classes={{
            paper: classes.dialogPaper,
        }} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <div className={classes.iconsWrapper}>
                <CloseIcon onClick={() => handleClose()} className={classes.closeIcon} />
                <DialogTitle className={classes.dialogTitle} id="form-dialog-title">
                    {isEditBanner ? 'Редактировать баннер' : 'Добавить баннер'}
                </DialogTitle>
            </div>
            <DialogContent className={classes.dialogContent}>
                {isEditBanner 
                    ? <EditBannerModalForm 
                        bannerIndex={editBannerIndex}
                        handleSubmit={editBannerSubmit} 
                        handleClose={handleClose}
                        images={images}
                        setImages={setImages}
                        /> 
                    : <AddBannerModalForm                     
                        handleSubmit={addBannerSubmit} 
                        handleClose={handleClose}
                        images={images}
                        setImages={setImages}
                       />  
                }
            </DialogContent>
        </Dialog>
    )
};

const useStyles = makeStyles(theme => ({
    dialogPaper: {
        width: '80%',
        maxWidth: 1057,
        padding: 24,
        [theme.breakpoints.down('600')]: {
            padding: 16,
            width: '100%',
            margin: '0 10px',
        },
    },
    dialogContent: {
        padding: 0,
    },
    dialogTitle: {
        padding: 0,
        '& h2': {
            fontSize: 16,
            fontWeight: 'normal',
            lineHeight: '25px',
        }
    },
    iconsWrapper: {
        display: 'flex',
    },
    closeIcon: {
        marginRight: 16,
        color: '#476282',
        '&:hover': {
            color: 'black',
            cursor: 'pointer',
        }
    },
}));

export default DialogComponent;