import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { updateSearchValue, addClientTable, addClientRows, addAllClients } from '../../../reducers/clientTable';

import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from './assets/search';

const Search = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const allClients = useSelector(state => state.clientTable.allClients);
    const [searchData, setSearchData] = useState('');

    useEffect(() => {
        if (searchData.length > 2) {
            let isNumber = function isNumber(value) {
                return typeof value === 'number' && isFinite(value);
            }
            let formatSearch = !isNumber(searchData) ? searchData.toLocaleLowerCase() : searchData;
            let searchResult = [];

            allClients.forEach(client => {
                for (let key in client) {
                    const element = client[key];

                    if (typeof element === 'string') {
                        const elementFormat = element.toLowerCase();
                        // const result = elementFormat.search(`/${formatSearch}/g`);
                        // console.log('result -->>>', result);
                        if (elementFormat.indexOf(formatSearch) !== -1) {
                            searchResult.push(client);
                        }
                    }
                }
            });

            let rowsSearchResults = [...searchResult];

            if (rowsSearchResults.length > 25) {
                rowsSearchResults.length = 25;
            }


            dispatch(addAllClients(searchResult));
            dispatch(addClientRows(rowsSearchResults));
        }
        if (searchData.length === 0) {
            closeSubmit();
        }
    }, [searchData]);

    const handleSearchInput = (e) => {
        const value  = e.target.value;

        setSearchData(value);
    }

    const searchSubmit = () => {
        if (searchData.length > 2) {
            dispatch(addClientTable([]));
            props.getFindToSearch(searchData);
            dispatch(updateSearchValue(searchData));
        }
    }

    const closeSubmit = () => {
        dispatch(updateSearchValue(''));
        props.getClientsToFirebase();
        setSearchData('');
    }

    return (
        <FormControl variant="filled" className={classes.search}>
            {searchData.length > 3 && <CloseIcon className={classes.closeIcon} onClick={closeSubmit} />}
            <SearchIcon className={classes.searchIcon} onClick={searchSubmit} />
            <Tooltip title="Введите не менее 3 символов" arrow placement="top" open={searchData && searchData.length < 4}>
                <OutlinedInput 
                    onChange={handleSearchInput}
                    value={searchData}
                    className={classes.textField}
                    color="primary"
                    classes={{
                        root: classes.root,
                        focused: classes.focused,
                    }}
                    id="search-input"  
                    variant="outlined" 
                    placeholder="Найти"
                    aria-label="search" 
                />
            </Tooltip>
        </FormControl>
    )
}

const useStyles = makeStyles(theme => ({
    search: {
        position: 'relative',
        marginRight: 30,
        '@media screen and (max-width: 490px)': {
            marginRight: 0,
            width: '100%',
        },
    },
    searchIcon: {
        position: 'absolute',
        cursor: 'pointer',
        top: 13,
        right: 10,
        width: 18,
        height: 18,
        zIndex: 10,
        '& > svg': {
            fill: '#707781'
        },
        '& > svg:hover': {
            fill: theme.palette.primary.main,
        }
    },
    closeIcon: {
        position: 'absolute',
        cursor: 'pointer',
        top: 13,
        right: 40,
        width: 18,
        height: 18,
        zIndex: 10,
        fill: 'red',
        '&:hover': {
            fill: '#707781'
        }
    },
    root: {
        width: 360,
        '@media screen and (max-width: 1200px)': {
            width: 220,
        },
        '@media screen and (max-width: 760px)': {
            width: 160,
        },
        '@media screen and (max-width: 650px)': {
            width: '100%',
        },
        '&  #search-input': {
            padding: '13px 14px',
        }, 
    },
    focused: {
        boxShadow: '3px 0 16px rgba(0, 0, 0, .15)',
    },
    popper: {
        background: 'red',
    }
}));

export default Search;