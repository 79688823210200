const ADD_All_CLIENTS = 'ADD-All-CLIENTS';
const ADD_CLIENT_TABLE = 'ADD-CLIENT-TABLE';
const ADD_CLIENT_ROWS = 'ADD-CLIENT-ROWS';
const REMOVE_TABLE = 'REMOVE-TABLE';
const CHECKED_TABLE_INPUT = 'CHECKED_TABLE_INPUT';
const CHECKED_ALL_TABLE_INPUT = 'CHECKED_ALL_TABLE_INPUT';

const UPDATE_SELECT_TYPE_VALUE = 'UPDATE-SELECT-TYPE-VALUE';
const UPDATE_SEARCH_VALUE = 'UPDATE-SEARCH-VALUE';
const UPDATE_COLUMNS = 'UPDATE-COLUMNS';

const initialState = {
    allClients: [],
    table: [],
    rows: [],
    columns: ['Имя', 'Телефон', 'Город', 'Дата установки', 'Пол', 'Скидка', 'Платформа'],
    selectType: 1,
    searchValue: '',
    isAllChecked: false
}

const ClientTable = (state = initialState, action) => {
    switch(action.type) {
        case ADD_All_CLIENTS: {
            return {
                ...state,
                allClients: action.data,
            }
        }
        case ADD_CLIENT_TABLE: {
            return {
                ...state,
                table: action.data
            }
        }
        case ADD_CLIENT_ROWS: {
            return {
                ...state,
                rows: action.rows
            }
        }
        case REMOVE_TABLE: {
            return {
                ...state,
                table: action.data
            }
        }
        case CHECKED_TABLE_INPUT: {
            const { phone, checked } = action.data;
            const allClients = [ ...state.allClients ];
            const rows = [ ...state.rows ];
            allClients.find(t => t.phone === phone).checked = checked;
            rows.find(t => t.phone === phone).checked = checked;

            return { ...state, allClients, rows };
        }
        case CHECKED_ALL_TABLE_INPUT: {
            const allClients = []; 
            const rows = [];
            state.allClients.forEach(t => allClients.push({ ...t, checked: action.checked }));
            state.rows.forEach(t => rows.push({ ...t, checked: action.checked }));

            return { ...state, allClients, rows };
        }
        case UPDATE_SELECT_TYPE_VALUE: {
            return {
                ...state,
                selectType: action.data
            }
        }
        case UPDATE_SEARCH_VALUE: {
            return {
                ...state,
                searchValue: action.searchValue
            }
        }
        case UPDATE_COLUMNS: {
            return {
                ...state,
                columns: action.list
            }
        }
        default:
            return state;
    }
}

export const addAllClients = (data) => ({type: ADD_All_CLIENTS, data});

export const removeTable = (data) => ({type: REMOVE_TABLE, data});
export const addClientTable = (data) => ({type: ADD_CLIENT_TABLE, data});
export const addClientRows = (rows) => ({type: ADD_CLIENT_ROWS, rows});
export const checkedTableInput = ({ phone, checked }) => ({type: CHECKED_TABLE_INPUT, data: { phone, checked }});
export const checkedAllTableInput = (checked) => ({type: CHECKED_ALL_TABLE_INPUT, checked});

export const updateSelectType = (data) => ({type: UPDATE_SELECT_TYPE_VALUE, data});
export const updateSearchValue = (searchValue) => ({type: UPDATE_SEARCH_VALUE, searchValue});
export const updateColumnsList = (list) => ({type: UPDATE_COLUMNS, list});

export default ClientTable;