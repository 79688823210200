import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import Loading from "../../../components/Loading";
import Button from "@material-ui/core/Button";
import ModalComponent from "../../../components/ModalComponent";

import { trim } from "../other";

import dndIcon from "../../../assets/img/dnd.svg";
import {
	EyeIcon,
	EyeOffIcon,
} from "../../products/categories/listCategories/icons";

import useStyles from "./styles";
import LinkIcon from "@material-ui/icons/Link";

const CategoriesSort = (props) => {
	const {
		openModal,
		categoriesList,
		setOpenModal,
		handleClose,
		handleClickSubmit,
		onDragEnd,
		hiddenCategories,
		handleToggleHidden,
		submitData,
	} = props;

	const classes = useStyles();

	const [copiedId, setCopiedId] = useState(null);

	const copyText = async (id) => {
		try {
			await navigator.clipboard.writeText(id);
			setCopiedId(id);
			setTimeout(() => setCopiedId(null), 2000);
		} catch (err) {
			console.error("Failed to copy: ", err);
		}
	};

	return (
		<div>
			<ModalComponent
				title="Подтвердите действие!"
				description={`Хотите изменить сортировку товаров?`}
				open={openModal}
				handleClose={handleClose}
				handleClickSubmit={handleClickSubmit}
			/>
			<div className={classes.sortModeProducts}>
				<DragDropContext onDragEnd={onDragEnd}>
					<Droppable droppableId="sortMode">
						{(provided, snapshot) => (
							<div
								{...provided.droppableProps}
								ref={provided.innerRef}
								// style={getListStyle(snapshot.isDraggingOver)}
							>
								{categoriesList &&
									categoriesList.map((item, index) => {
										return (
											<Draggable
												key={index}
												draggableId={String(index)}
												index={index}
											>
												{(provided, snapshot) => (
													<div
														key={index}
														className={classes.bannerWrap}
														ref={provided.innerRef}
														{...provided.draggableProps}
														{...provided.dragHandleProps}
													>
														<div key={index} className={classes.banner}>
															<div className={classes.bannerContentWrap}>
																{/* <div className={classes.imageWrap}>
                                                                <div 
                                                                    className={classes.image}
                                                                    style={{ backgroundImage: `url(${item.photo})` }}
                                                                ></div>
                                                            </div> */}
																<div className={classes.titleWrap}>
																	<div className={classes.title}>
																		{trim(item.name, 20)}
																	</div>
																</div>
															</div>
															<div className={classes.controlWrap}>
																<div
																	className={classes.copyCategory}
																	onClick={() => copyText(item.id)}
																>
																	<div
																		className={`${classes.copyLinkIcon} ${
																			copiedId === item.id &&
																			classes.copyLinkIconActive
																		}`}
																	>
																		<LinkIcon />{" "}
																	</div>
																	<div
																		className={`${classes.copyLinkText} ${
																			copiedId === item.id &&
																			classes.copyLinkTextActive
																		}`}
																	>
																		{copiedId === item.id
																			? "Скопировано"
																			: "Копировать ID"}
																	</div>
																</div>
																<div className={classes.eye}>
																	<span
																		onClick={() => handleToggleHidden(item.id)}
																	>
																		{hiddenCategories.includes(item.id) ? (
																			<EyeOffIcon
																				style={{
																					fill: "black",
																					cursor: "pointer",
																				}}
																			/>
																		) : (
																			<EyeIcon
																				style={{
																					fill: "black",
																					cursor: "pointer",
																				}}
																			/>
																		)}
																	</span>
																</div>
															</div>
														</div>
														<div className={classes.dnd}>
															<img src={dndIcon} alt="dnd" />
														</div>
													</div>
												)}
											</Draggable>
										);
									})}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
				{!categoriesList && <Loading />}
			</div>
			<div className={classes.buttonWrap}>
				<Button
					className={classes.button}
					onClick={() => setOpenModal(true)}
					variant="contained"
					color="primary"
					disabled={!submitData}
				>
					Сохранить
				</Button>
			</div>
		</div>
	);
};

export default CategoriesSort;
