import React, { Component } from "react";

import DisableApp from "./disableApp";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { updateKeyShop, editShops, updateSettings } from "../../reducers/shops";
import { withFirebase } from "../../firebase";

class DisableAppContainer extends Component {
	constructor() {
		super();

		this.state = {
			checked: true,
			keyShop: null,
		};
	}

	componentDidMount() {
		this.props.updateKeyShop(this.props.match.params.city);
		this.setState({
			keyShop: this.props.match.params.city,
		});
	}

	updateStatusApp = (status) => {
		const adminPermission = JSON.parse(localStorage.getItem("adminPermission"));

		const brand = JSON.parse(localStorage.getItem("currentBrand"));
		const keyCity = Number(JSON.parse(localStorage.getItem("chosenCity")));
		const keyBrand = Number(JSON.parse(localStorage.getItem("chosenBrand")));
		const isDebug = JSON.parse(localStorage.getItem("isDebug"));

		if (adminPermission && adminPermission.newAdmin === true) {
			let value =
				status !== null
					? status
					: brand.appSettings.isOrderAvailable === false
					? false
					: true;
			// this.props.editShops({
			// 	key: keyBrand,
			// 	params: "isOrderAvailable",
			// 	value,
			// });
			this.props.firebase.bd
				.ref(
					`${
						isDebug ? "debug" : ""
					}/settings/city/${keyCity}/brands/${keyBrand}/appSettings`
				)
				.update({ isOrderAvailable: value });
			localStorage.setItem(
				"currentBrand",
				JSON.stringify({
					...brand,
					appSettings: { ...brand.appSettings, isOrderAvailable: value },
				})
			);
		} else {
			let value = this.props.shops[this.props.match.params.city].orderAvailable
				? false
				: true;
			this.props.editShops({
				key: this.props.match.params.city,
				params: "orderAvailable",
				value,
			});
			this.props.firebase.bd
				.ref(
					`${isDebug ? "debug" : ""}/settings/City/${
						this.props.match.params.city
					}/`
				)
				.update({ orderAvailable: value });
		}
	};

	updateAlert = (data) => {
		const adminPermission = JSON.parse(localStorage.getItem("adminPermission"));

		const brand = JSON.parse(localStorage.getItem("currentBrand"));
		const keyCity = Number(JSON.parse(localStorage.getItem("chosenCity")));
		const keyBrand = Number(JSON.parse(localStorage.getItem("chosenBrand")));
		const isDebug = JSON.parse(localStorage.getItem("isDebug"));
		// console.log(currentCity, currentBrand);

		if (adminPermission && adminPermission.newAdmin === true) {
			if (data.text !== brand.appSettings.closedSpotAlertDescription) {
				this.props.updateSettings({
					params: "closedSpotAlertDescription",
					value: data.text,
				});
				this.props.firebase.bd
					.ref(
						`${
							isDebug ? "debug" : ""
						}/settings/city/${keyCity}/brands/${keyBrand}/appSettings`
					)
					.update({ closedSpotAlertDescription: data.text });
			}
		} else {
			if (data.text !== this.props.appSettings.closedSpotAlertDescription) {
				this.props.updateSettings({
					params: "closedSpotAlertDescription",
					value: data.text,
				});
				this.props.firebase.bd
					.ref(`${isDebug ? "debug" : ""}/settings/appSettings/`)
					.update({ closedSpotAlertDescription: data.text });
			}
		}
	};

	render() {
		return (
			<>
				<DisableApp
					updateStatusApp={this.updateStatusApp}
					keyShop={this.props.match.params.city}
					closedAlert={this.props.closedAlert}
					shops={this.props.shops[this.props.match.params.city]}
					alert={this.props.appSettings.closedSpotAlertDescription}
					editShops={this.editShops}
					updateAlert={this.updateAlert}
					isDebug={this.props.isDebug}
				/>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		shops: state.shops.shops,
		appSettings: state.shops.appSettings,
	};
};

export default connect(mapStateToProps, {
	updateKeyShop,
	editShops,
	updateSettings,
})(withRouter(withFirebase(DisableAppContainer)));
