import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    width: 328,
  },
  hiddenNumber: {
    top: 15,
    right: 5,
    width: 70,
    height: 30,
    zIndex: 10,
    position: 'absolute',
    background: 'white',
    [theme.breakpoints.down("600")]: {
      display: 'none',
    }
  },
}));

export default function CustomizedInputs(
  { 
    input, 
    meta: { touched, error, warning, invalid }, 
    ...props}
  ) {
  const classes = useStyles();
  const {defaultValue, freeShipping, paidShipping, initialDefaultValue} = props;

  useEffect(() => {
    if (input.name === 'delivery' || input.name === 'deliveryFreeLimit') {
      if (input.name === 'delivery' && freeShipping || input.name === 'deliveryFreeLimit' && paidShipping) {
        input.onChange(0);
      } else {
        input.onChange(initialDefaultValue);
      }
    }
  }, [defaultValue]);

  return (
    <div className={classes.root}>
        <div className={classes.hiddenNumber}></div>
        <TextField
          disabled={props.disabled}
          className={classes.inputStyle}
          label={props.placeholder}
          error={touched && invalid}
          helperText={touched && error}
          // value={input.name === 'delivery' && defaultValue ? "0" : input.value}
          {...input}
          {...props}
        />
      {touched &&
        ((error && <span>{error.phone}</span>) ||
          (warning && <span>{warning.phone}</span>))}
      </div>
  )
};