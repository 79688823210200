import React, { Component } from "react";

import { connect } from "react-redux";
import { withRouter } from "react-router";
import { withFirebase } from "../../../firebase";

import Promocodes from "./index";

class PromocodesContainer extends Component {
	constructor() {
		super();

		this.state = {
			preloader: false,
			promocodes: null,
			keyShop: null,
			currentCity: null,
			currentBrand: null,
		};
	}

	componentDidMount() {
		this.setState({
			keyShop: this.props.match.url,
		});

		this.getFieldsPromoCodes();
	}

	getFieldsPromoCodes = () => {
		let url = this.props.match.url.split("/");
		url = url.find((item) => item === "0" || parseInt(item));

		const currentCity = JSON.parse(localStorage.getItem("chosenCity"));
		const currentBrand = JSON.parse(localStorage.getItem("chosenBrand"));
		const isDebug = JSON.parse(localStorage.getItem("isDebug"));

		this.props.firebase.bd
			.ref(
				`${
					isDebug ? "debug" : ""
				}/settings/city/${currentCity}/brands/${currentBrand}/loyaltySettings/promocodes`
			)
			.on("value", (snapshot) => {
				let promocodes = snapshot.val();

				this.setState({ promocodes, keyShop: url, currentCity, currentBrand });
			});
	};

	changeChecker = (value, id) => {
		console.log("id (changeChecker) --- >", id);
		const isDebug = JSON.parse(localStorage.getItem("isDebug"));
		this.setState({ preloader: true });
		this.props.firebase.bd
			.ref(
				`${isDebug ? "debug" : ""}/settings/city/${
					this.state.currentCity
				}/brands/${this.state.currentBrand}/loyaltySettings/promocodes/${id}`
			)
			.update({ isAvailable: value })
			.then((res) => {
				this.setState({ preloader: false });
			});
	};

	deletePromocode = (id) => {
		console.log("=== Delete promocode ===", id);
		const isDebug = JSON.parse(localStorage.getItem("isDebug"));
		this.setState({ preloader: true });
		this.props.firebase.bd
			.ref(
				`${isDebug ? "debug" : ""}/settings/city/${
					this.state.currentCity
				}/brands/${this.state.currentBrand}/loyaltySettings/promocodes`
			)
			.child(id)
			.remove()
			.then((res) => {
				this.setState({ preloader: false });
			});
	};

	createPromocode = (data) => {
		console.log("=== Create promocode data  ===", data);
		const isDebug = JSON.parse(localStorage.getItem("isDebug"));
		const key = Object.keys(data)[0];
		const prmocodeData = data[key];

		this.setState({ preloader: true });
		this.props.firebase.bd
			.ref(
				`${isDebug ? "debug" : ""}/settings/city/${
					this.state.currentCity
				}/brands/${this.state.currentBrand}/loyaltySettings/promocodes/${key}`
			)
			.set(prmocodeData)
			.then((res) => {
				this.setState({ preloader: false });
				this.getFieldsPromoCodes();
			});
	};

	updatePromocode = (data) => {
		console.log("=== Submit data (promocodes) ===", data);
		const isDebug = JSON.parse(localStorage.getItem("isDebug"));
		this.setState({ preloader: true });
		this.props.firebase.bd
			.ref(
				`${isDebug ? "debug" : ""}/settings/city/${
					this.state.currentCity
				}/brands/${this.state.currentBrand}/loyaltySettings/promocodes`
			)
			.set(data)
			.then((res) => {
				this.setState({ preloader: false });
				this.getFieldsPromoCodes();
			});
	};

	render() {
		return (
			<>
				<Promocodes
					onSubmit={this.updatePromocode}
					loadStatus={this.state.preloader}
					promocodes={this.state.promocodes}
					changeChecker={this.changeChecker}
					createPromocode={this.createPromocode}
					deletePromocode={this.deletePromocode}
				/>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		shops: state.shops.shops,
		appSettings: state.shops.appSettings,
	};
};

export default connect(
	mapStateToProps,
	null
)(withRouter(withFirebase(PromocodesContainer)));
