import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";

const styles = (theme) => ({
	root: {
		position: "absolute",
		bottom: 25,
		left: 15,
		right: 15,
	},
});

class AlertComponent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			status: this.props.status,
		};
	}

	render() {
		const { classes } = this.props;
		return (
			<div className={classes.root}>
				<Collapse in={this.props.status}>
					<Alert
						color={this.props.type}
						severity={this.props.type}
						action={
							<IconButton
								aria-label="close"
								color="inherit"
								size="small"
								onClick={() => this.props.hideAlert()}
							>
								<CloseIcon fontSize="inherit" />
							</IconButton>
						}
					>
						{this.props.text}
					</Alert>
				</Collapse>
			</div>
		);
	}
}

export default withStyles(styles, { withTheme: true })(AlertComponent);
