import React from 'react';
import { makeStyles } from '@material-ui/core/styles'

// import Popover from '@material-ui/core/Popover';
import { Typography } from '@material-ui/core';

// import {
//     InfoIcon
// } from './TableComponents';

const PopoverComponent = () => {
    const classes = useStyles();
    // const [anchorEl, setAnchorEl] = useState(null);

    // const handlePopoverOpen = (event) => {
    //     setAnchorEl(event.currentTarget);
    // };

    // const handlePopoverClose = (props) => {
    //     setAnchorEl(null);
    // };

    // const open = Boolean(anchorEl);

    return (
        <div className={classes.lastTimeWorkLabelWrap}>
            <Typography className={classes.totalTimeFooter}>Последнее время приема доставки:</Typography>
            {/* <InfoIcon
                ariaOwns={open ? 'mouse-over-popover' : undefined}
                ariaHaspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                className={classes.footerLastTimeWorkLabelIcon} />
            <Popover
                id="mouse-over-popover"
                className={classes.popover}
                classes={{
                    paper: classes.paper,
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Typography>Скрытый текст</Typography>
            </Popover> */}
        </div>
    )
}

const useStyles = makeStyles({
    totalTimeFooter: {
        position: 'relative',
        top: 0,
        color: '#000000',
        fontSize: 14,
        textAlign: 'left',
        fontWeight: 500,
        lineHeight: '16px',
        letterSpacing: '0.003em',
    }, 
    footerLastTimeWorkLabelIcon: {
        marginLeft: 0,
        position: 'absolute',
        top: 'calc(50% - 8px)',
        right: -8,
    },
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: 3,
    },
    lastTimeWork: {
        marginTop: 10,
        marginBottom: 25,
        display: 'flex',
    },
    lastTimeWorkLabelWrap: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    lastTimeWorkLabel: {

    },
    lastTimeWorkLabelIcon: {
        width: 20,
        height: 20,
        marginLeft: 10,
    },
  });

export default PopoverComponent;