import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { withFirebase } from '../../../firebase';
import { addClientTable, addClientRows, addAllClients } from '../../../reducers/clientTable';

import Header from './header';
import Footer from './footer';
import Navigation from './Navigation';
import Table from './Table';

const TableClientList = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const table = useSelector(state => state.clientTable.table);
    const allClients = useSelector(state => state.clientTable.allClients);
    const rows = useSelector(state => state.clientTable.rows);
    const selectType = useSelector(state => state.clientTable.selectType);
    const isAllChecked = useSelector(state => state.clientTable.isAllChecked);
    const state = useSelector(state => state);

    const isDebug = JSON.parse(localStorage.getItem('isDebug'))

    console.log('state -->>>>', state);

    const [allCheckBox, setAllCheckBox] = useState(false);
    const [preload, setPreload] = useState(false);

    const getTypeFormat = (type) => {
        switch (type) {
            case 2:
                return 'phone';
            case 3:
                return 'city';
            default:
                return 'name'
        }
    }

    const getFindToSearch = (searchValue) => {
        setPreload(true);
        const clientsRef = props.firebase.bd.ref(`${isDebug ? 'debug' : ''}/clients`);

        let searchQuery = selectType !== 2
            ? clientsRef.orderByChild(getTypeFormat(selectType)).startAt(searchValue).endAt(searchValue + "\uf8ff")
            : clientsRef.child(searchValue);

        searchQuery.once('value', function (snapshot) {
            let value = snapshot.val();
            setPreload(false);

            let arrRows = [];

            if (selectType !== 2) {
                for (let key in value) {
                    const itemRow = { ...value[key], phone: key, checked: false };
                    arrRows.push(itemRow);
                }
            } else {
                arrRows.push({ ...value, phone: searchValue, checked: false });
            }

            if (arrRows.length > 0) {
                let firstKey = arrRows[0].phone;
                
                dispatch(addClientTable(arrRows));
                
                if (arrRows.length >= 25) {
                    let newArrRows = [...arrRows];
                    newArrRows.length = 25;
                    let lastKey = arrRows[newArrRows.length - 1].phone;

                    dispatch(addClientRows(newArrRows));
                    // dispatch(updateFirstKey(firstKey));
                    // dispatch(updateLastKey(lastKey));
                }

                if (arrRows.length < 25) {
                    dispatch(addClientRows(arrRows));
                    // dispatch(updateFirstKey(firstKey));
                    // dispatch(updateLastKey(null));
                }

                setPreload(false);
            }

            if (arrRows.length === 0) {
                dispatch(addClientTable([]));
                setPreload(false);
            }

        });
    }

    const getClientsToFirebase = () => {
        setPreload(true);
        const clientsRef = props.firebase.bd.ref(`${isDebug ? 'debug' : ''}/clients`);
        let pageQuery = clientsRef.orderByKey();

        pageQuery.once('value', function (snapshot) {
            let allClients = [];

            snapshot.forEach(function (childSnapshot) {
                let phone = childSnapshot.key;
                let data = childSnapshot.val();

                let itemData = { phone, ...data, checked: false };

                allClients.push(itemData);
            });

            let rowsClients = [...allClients];
            rowsClients.length = 25;

            dispatch(addAllClients(allClients));
            dispatch(addClientRows(rowsClients));
            setPreload(false);
        });
    }

    // const getLimitClients = (count) => {
    //     setPreload(true);
    //     const clientsRef = props.firebase.bd.ref('clients');
    //     let pageQuery = clientsRef.orderByKey().limitToFirst(count);

    //     pageQuery.once('value', function (snapshot) {
    //         let arrRows = [];

    //         snapshot.forEach(function (childSnapshot) {
    //             let phone = childSnapshot.key;
    //             let data = childSnapshot.val();

    //             let itemData = { phone, ...data, checked: false };

    //             arrRows.push(itemData);
    //         });


    //         dispatch(addClientTable(arrRows));
    //         dispatch(addClientRows(arrRows));
    //         setPreload(false);
    //     });
    // }

    const getNextClients = (count, key) => {
        console.log('--- getNextClients ---');
        setPreload(true);
        const clientsRef = props.firebase.bd.ref(`${isDebug ? 'debug' : ''}/clients`);
        let pageQuery = clientsRef.orderByKey().startAt(key).limitToFirst(count + 1);

        pageQuery.once('value', function (snapshot) {
            let arrRows = [];

            snapshot.forEach(function (childSnapshot) {
                let phone = childSnapshot.key;
                let data = childSnapshot.val();

                const isChecked = allClients.find(it => Number(it.phone) === Number(phone)).checked;
                // console.log('isChecked', isChecked)

                let itemData = { phone, ...data, checked: isChecked ? isChecked : isAllChecked };

                arrRows.push(itemData);

            });

            if (arrRows.length > 1) {
                let firstKey = key;
                let lastKey = arrRows[arrRows.length - 1].phone;
                arrRows = arrRows.filter(t => !!t);

                if (arrRows.length > 25 && count >= 25) {
                    arrRows.pop(); // deleted last key
                    let updateRows = [...table, ...arrRows];

                    dispatch(addClientTable(updateRows));
                    dispatch(addClientRows(arrRows));
                    // dispatch(updateFirstKey(firstKey));
                    // dispatch(updateLastKey(lastKey));
                    setPreload(false)
                }

                if (arrRows.length < 25 || count > arrRows.length) {
                    let updateRows = [...table, ...arrRows];

                    dispatch(addClientTable(updateRows));
                    dispatch(addClientRows(arrRows));
                    // dispatch(updateFirstKey(firstKey));
                    // dispatch(updateLastKey(null));
                    setPreload(false)
                }
            }
        });
    }

    useEffect(() => {
        getClientsToFirebase();
    }, []);

    // console.log('rows', rows);
    // console.log('table', table);

    return (
        <div className={classes.root}>
            <Header count={allClients.length} />
            <Navigation
                rows={rows}
                table={table}
                setAllCheckBox={setAllCheckBox}
                getFindToSearch={getFindToSearch}
                getClientsToFirebase={getClientsToFirebase}
            />
            <Table
                allCheckBox={allCheckBox}
                setAllCheckBox={setAllCheckBox}
                preloader={preload}
                rows={rows}
            />
            <Footer
                // getLimitClients={getLimitClients}
                getNextClients={getNextClients}
                setPreload={setPreload}
            />
        </div>
    )
}

const useStyles = makeStyles({
    root: {
        marginTop: 16,
        padding: '24px 24px 16px 16px',
        border: '0.5px solid #DADCE0',
        borderRadius: 5,
    }
})

export default withFirebase(TableClientList);