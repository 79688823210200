import React, { useState, useEffect } from 'react';
import { InputField, CustomInputField } from './inputFields';

const CityFields = (props) => {
    // const {initialValues} = props;
    const {initialValues, freeShipping, setFreeShipping, paidShipping, setPaidShipping } = props;
    const [count, setCount] = useState(0);

    console.log('initialValues zone --->>>', initialValues);
    // console.log('freeShipping', freeShipping);
    // console.log('paidShipping', paidShipping);
    
    useEffect(() => {
        if (count === 0 && initialValues) {
            const { deliveryFreeLimit, deliveryLimit, delivery } = initialValues;
            if (delivery === 0 && deliveryFreeLimit > 0) {
                setFreeShipping(true);
                setPaidShipping(false);
                setCount(count + 1);
            } else if (delivery > 0 && deliveryFreeLimit === 0) {
                setFreeShipping(false);
                setPaidShipping(true);
                setCount(count + 1);
            } else {
                setFreeShipping(false);
                setPaidShipping(false);
                setCount(count + 1);
            }
        }

        if (count === 0 && !initialValues) {
            setFreeShipping(true);
            setPaidShipping(false);
            setCount(count + 1);
        }

    }, [initialValues]);

    const handleFreeShipping = (event) => {
        const checked = event.target.checked;

        setPaidShipping(!checked);
        setFreeShipping(checked);
    };

    const handlePaidShipping = (event) => {
        const checked = event.target.checked;

        setPaidShipping(checked);
        setFreeShipping(!checked);
    };

    return (
        <div className={props.className}>
            <InputField
                name="deliveryLimit" 
                title="Минимальная сумма для заказа"
                placeholder="Введите сумму мин. заказа"
                validMsg="Введите сумму заказа" />
            <CustomInputField
                name="deliveryFreeLimit"
                checked={paidShipping}
                paidShipping={paidShipping}
                freeShipping={freeShipping}
                initialDefaultValue={initialValues && initialValues.deliveryFreeLimit ? initialValues.deliveryFreeLimit : 0}
                handleCheckedChange={handlePaidShipping}
                title="Бесплатная доставка от"
                popover={false}
                checkBox={true}
                placeholder="Введите сумму мин. доставки"
                validMsg="Введите сумму минимальной доставки"
                checkboxTitle="Всегда платная доставка" />

            <CustomInputField
                name="delivery"
                checked={freeShipping}
                paidShipping={paidShipping}
                freeShipping={freeShipping}
                initialDefaultValue={initialValues && initialValues.delivery ? initialValues && initialValues.delivery : 0 }
                handleCheckedChange={handleFreeShipping}
                title="Стоимость доставки"
                popover={false}
                checkBox={true}
                placeholder="Введите стоимость доставки"
                validMsg="Введите стоимость доставки"
                checkboxTitle="Бесплатная доставка" />
        </div>
    )
}

export default CityFields;