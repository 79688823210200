import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useDispatch } from "react-redux";
import { withFirebase } from "../../../firebase";
import { withRouter } from "react-router-dom";

import { reorder } from "../other";
import {
	updateCategoriesOrder,
	updateBrandData,
} from "../../../reducers/shops";
import CategoriesSort from "./categoriesSort";

const Categories = (props) => {
	const { categories, categoriesOrder, hiddenCategories } = props;
	const dispatch = useDispatch();

	const [openModal, setOpenModal] = useState(false);
	const [submitData, setSubmitData] = useState(null);
	const [categoriesList, setCategoriesList] = useState([]);

	const handleClose = useCallback(() => setOpenModal(false), []);
	const handleClickSubmit = useCallback(() => {
		setOpenModal(false);
		handleSubmit(submitData); // Подготовка к отправке данных при подтверждении
	}, [submitData]);

	const sortedAndRemainingCategories = useMemo(() => {
		if (categories?.length > 0) {
			const sortedCategories = categoriesOrder
				.map((orderId) => categories.find((cat) => cat.id === orderId))
				.filter((cat) => cat);
			const remainingCategories = categories
				.filter((cat) => !categoriesOrder.includes(cat.id))
				.sort((a, b) => a.order - b.order);
			return sortedCategories.concat(remainingCategories);
		}
	}, [categories, categoriesOrder]);

	useEffect(() => {
		setCategoriesList(sortedAndRemainingCategories);
	}, [sortedAndRemainingCategories]);

	const onDragEnd = (result) => {
		if (!result.destination) {
			return;
		}

		let data = reorder(
			categoriesList,
			result.source.index,
			result.destination.index
		);

		let sortData = [];
		data.forEach((item) => sortData.push(item.id));

		setCategoriesList(data);
		setSubmitData(sortData);
	};

	const handleToggleHidden = useCallback(
		(categoryId) => {
			const newHiddenCategories = hiddenCategories.includes(categoryId)
				? hiddenCategories.filter((id) => id !== categoryId)
				: [...hiddenCategories, categoryId];

			const keyCity = JSON.parse(localStorage.getItem("chosenCity"));
			const keyBrand = JSON.parse(localStorage.getItem("chosenBrand"));
			const isDebug = JSON.parse(localStorage.getItem("isDebug"));
			const path = `${
				isDebug ? "debug" : ""
			}/settings/city/${keyCity}/brands/${keyBrand}/category/hiddenCategories`;

			props.firebase.bd
				.ref(path)
				.set(newHiddenCategories)
				.then(() => {
					console.log("Hidden categories updated successfully");
					dispatch(
						updateBrandData({
							shopKey: keyCity,
							brandKey: keyBrand,
							path: "category.hiddenCategories",
							value: newHiddenCategories,
						})
					);
				})
				.catch((error) => {
					console.error("Error updating hidden categories: ", error);
				});
		},
		[hiddenCategories, dispatch, props.firebase]
	);

	const handleSubmit = useCallback(
		(data) => {
			if (!data || data.length === 0) {
				console.log("No data to submit");
				return;
			}
			// Обновление данных в Firebase и Redux
			const keyCity = JSON.parse(localStorage.getItem("chosenCity"));
			const keyBrand = JSON.parse(localStorage.getItem("chosenBrand"));
			const isDebug = JSON.parse(localStorage.getItem("isDebug"));

			const path = `${
				isDebug ? "debug" : ""
			}/settings/city/${keyCity}/brands/${keyBrand}/category/categoriesOrder`;

			props.firebase.bd
				.ref(path)
				.set(data)
				.then(() => {
					dispatch(
						updateBrandData({
							shopKey: keyCity,
							brandKey: keyBrand,
							path: "category.categoriesOrder",
							value: data,
						})
					);
					dispatch(updateCategoriesOrder(data));
					setSubmitData(null);
					alert("Готово!");
				})
				.catch((error) => {
					console.error("Error updating data: ", error);
				});
		},
		[dispatch, props.firebase]
	);

	return (
		<CategoriesSort
			openModal={openModal}
			categoriesList={categoriesList}
			handleClose={handleClose}
			handleClickSubmit={handleClickSubmit}
			onDragEnd={onDragEnd}
			setOpenModal={setOpenModal}
			hiddenCategories={hiddenCategories}
			handleToggleHidden={handleToggleHidden}
			submitData={submitData}
		/>
	);
};

export default withRouter(withFirebase(Categories));
