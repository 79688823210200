import React, { useState } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {required} from 'redux-form-validators';
import { Field, reduxForm } from 'redux-form';

import Button from '../../../../components/Button';
import Popover from '../../../../components/Popover'
import Loading from '../../../../components/Loading';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import SnackbarContent from '@material-ui/core/SnackbarContent';

import CustomizedInputs from '../../../../components/UIform';
import useStyles from '../productsForm/styles';

const ModifierGroupsForm = (props) => {
    const { handleSubmit } = props;
    const classes = useStyles();
    const isMin600 = useMediaQuery('(max-width: 599px)');

    let hiddenModifierGroups = [];

    if (props.hiddenModifierGroups) {
        hiddenModifierGroups = [...props.hiddenModifierGroups];
      }

        const handleDelete = (chipToDelete) => () => {
          let list = props.hiddenModifierGroups.filter((chip) => chip !== chipToDelete);
          props.updateVisibility(list, 'hiddenModifierGroups');
        };
  
        const addChip = (data) => {
          let status = hiddenModifierGroups.find((chip) => chip === data.id);
          if(status === undefined){
              let list = [...props.hiddenModifierGroups, data.id];
              props.updateVisibility(list, 'hiddenModifierGroups');
              props.reset();
            
          } else {
              alert('Данный ID уже есть в списке')
          }
        };
  
        const saveData = () => {
          props.saveData('hiddenModifierGroups')
        }

    return(
    <div className={classes.productsWrapper}>
     <div className={classes.headerWrap}>
        <Typography variant="h6" className={classes.title}>Группа модификаторов</Typography>
        <Popover className={classes.popover} title="" hiddenTitle="Введите ID групового модификатора который хотите скрыть" />
      </div>
     <div className={classes.container}>
     <div className={classes.row}>
     <div className={classes.tagContainer}>
     {hiddenModifierGroups.map((data, index) => {
        return (
          <li key={index}>
            <Chip
              label={data}
              onDelete={handleDelete(data)}
              className={classes.chip}
            />
             {isMin600 && <hr className={classes.hr} />}
            <hr className={classes.hr} />
          </li>
        );
      })}
       </div>
    <form onSubmit={handleSubmit} className={classes.formContainer} autoComplete='off'>
        <Field  name="id"
                className={classes.input}
                component={CustomizedInputs}
                type="text"
                placeholder="Введите ID модификатора"
                rows={'1'}
                disabled={props.loadStatus ? true : false}
                validate={required({msg: 'Это поле обязательно для заполнения'})}/>

            <Button text={props.loadStatus ? 
                <Loading size={22} color={'#fff'}/> : 'Добавить в список'} 
                            disabled={props.loadStatus ? true : false}
                             handleClick={handleSubmit(addChip)}
                             className={classes.addToList} />
            <div className={classes.bt}>
            <Button text={props.loadStatus ? 
                <Loading size={22} color={'#fff'}/> : 'Сохранить'} 
                            disabled={props.loadStatus ? true : false}
                             handleClick={saveData} 
                             className={classes.saveButton} />
            </div>
    </form>
    {props.isActiveModifierGroupSnackbar && 
            <SnackbarContent className={classes.snackbar} message="Данные успешно сохранены!" />}
    </div>
    </div>
    </div>
    )
}

export default reduxForm({
    form: 'ModifierGroupsForm', 
  })(ModifierGroupsForm);