import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { withFirebase } from "../../../../firebase";
import { useSelector } from "react-redux";

import SortProducts from "./sortProducts";

import { reorder } from "../other";

const SortModeProducts = (props) => {
	const categories = useSelector((state) => state.shops.categories);
	const hiddenCategories = useSelector((state) => state.shops.hiddenCategories);
	const language = useSelector((state) => state.shops.defaultLanguage);

	const [openModal, setOpenModal] = useState(false);
	const [isSubmit, setIsSubmit] = useState(false);
	const [submitData, setSubmitData] = useState(false);
	const [products, setProducts] = useState(null);
	const [categoriesList, setCategoriesList] = useState(null);
	const [activeCategoryId, setActiveCategoryId] = useState(null);
	const [activeProducts, setActiveProducts] = useState(null);
	const [categoriesOrder, setCategoriesOrder] = useState(null);

	const brand = JSON.parse(localStorage.getItem("currentBrand"));
	const isDebug = JSON.parse(localStorage.getItem("isDebug"));

	const handleClose = () => {
		setOpenModal(false);
		setIsSubmit(false);
	};

	const handleClickSubmit = () => {
		setOpenModal(false);
		setIsSubmit(true);
	};

	const getProducts = () => {
		props.firebase.bd
			.ref(
				`${isDebug ? "debug" : ""}/EAppCRM/${brand.id}/nomenclature/products`
			)
			.once("value", (snapshot) => {
				let data = snapshot.val();
				if (!products) setProducts(data);
			});
	};

	const getCategoriesOrder = () => {
		const keyCity = Number(JSON.parse(localStorage.getItem("chosenCity")));
		const keyBrand = Number(JSON.parse(localStorage.getItem("chosenBrand")));

		props.firebase.bd
			.ref(
				`${
					isDebug ? "debug" : ""
				}/settings/city/${keyCity}/brands/${keyBrand}/category/categoriesOrder`
			)
			.once("value", (snapshot) => {
				let data = snapshot.val();
				if (!categoriesOrder) setCategoriesOrder(data);
			});
	};

	const getActiveProducts = () => {
		let count = 0;
		let sendProducts = [];
		let activeProductsArr = [];
		// const categoryId = props.match.params.id;

		for (let key in products) {
			activeProductsArr.push({ ...products[key] });
		}

		activeProductsArr = activeProductsArr
			.filter((item) => item.categoryId === activeCategoryId)
			.sort((a, b) => (a.order > b.order ? 1 : -1));

		// activeProductsArr.map(item => {
		//     console.log('item', item.menuCategoryId === activeCategoryId);
		// });

		activeProductsArr.map((item) => {
			sendProducts.push({ ...item, order: item.order ? item.order : count });
			count += 1;
		});

		setActiveProducts(sendProducts);
	};

	const getCategoriesList = () => {
		let listData = [];

		for (let key in categories) {
			// let item = { id: key, name: categories[key].name[language] };
			let item = {
				id: categories[key].categoryId,
				name: categories[key].name[language],
			};

			listData.push(item);
		}

		// учитываем порядок категорий при отображении
		const sortedCategories = listData.sort(
			(a, b) => categoriesOrder.indexOf(a.id) - categoriesOrder.indexOf(b.id)
		);

		if (listData.length > 0) setCategoriesList(sortedCategories);
		// if (listData.length > 0) setCategoriesList(listData);
	};

	useEffect(() => {
		if (isSubmit) {
			handleSubmit(submitData);
			setOpenModal(false);
			setSubmitData(false);
		}
	}, [isSubmit]);

	useEffect(() => {
		let selectedCategoryId = props.location.pathname;
		selectedCategoryId = selectedCategoryId.split("/");

		selectedCategoryId = selectedCategoryId[
			selectedCategoryId.length - 1
		].replace("category", "");
		// const firstCategory = Object.keys(categories)[0];
		const firstCategory = categories[0]?.categoryId;

		if (activeCategoryId !== selectedCategoryId) {
			setActiveCategoryId(selectedCategoryId);
			setActiveProducts(null);
		}

		if (
			products &&
			(firstCategory === selectedCategoryId || selectedCategoryId === ":id")
		) {
			setActiveCategoryId(firstCategory);
			getActiveProducts();
		}
	}, [activeCategoryId, props.location.pathname, products]);

	useEffect(() => {
		if (!products && activeCategoryId) getProducts();
		if (!activeProducts && activeCategoryId && products) getActiveProducts();
	}, [activeCategoryId, products]);

	useEffect(() => {
		if (!categoriesList && categories && categoriesOrder) getCategoriesList();
		if (!categoriesOrder) getCategoriesOrder();
	}, [categoriesList, categories, getCategoriesList]);

	const onDragEnd = (result) => {
		if (!result.destination) {
			return;
		}

		let data = reorder(
			activeProducts,
			result.source.index,
			result.destination.index
		);

		let sortData = {};

		data.map((item, index) => {
			sortData[item.productId] = { ...item, order: index };
		});

		setActiveProducts(data);
		setSubmitData(Object.values(sortData));
		// setSubmitData(sortData);
	};

	const clearStateByProducts = () => {
		setIsSubmit(false);
		setProducts(null);
		setActiveProducts(null);
	};

	const replaceProductsByProductId = (originalProducts, newProducts) => {
		const updatedProducts = [];

		for (const originalProduct of originalProducts) {
			const newProduct = newProducts.find(
				(product) => product.productId === originalProduct.productId
			);

			if (newProduct) {
				updatedProducts.push(newProduct);
			} else {
				updatedProducts.push(originalProduct);
			}
		}

		return updatedProducts;
	};

	const handleSubmit = (data) => {
		const updatedProducts = replaceProductsByProductId(products, data);

		console.log("updatedProducts ---> ", updatedProducts);

		if (!data) {
			clearStateByProducts();
		} else {
			props.firebase.bd
				.ref(
					`${isDebug ? "debug" : ""}/EAppCRM/${brand.id}/nomenclature/products`
				)
				.update(updatedProducts)
				.then((res) => {
					clearStateByProducts();
				})
				.catch((error) => {
					console.log("error", error);
				});
		}
	};

	return (
		<SortProducts
			openModal={openModal}
			setOpenModal={setOpenModal}
			handleClose={handleClose}
			handleClickSubmit={handleClickSubmit}
			categoriesList={categoriesList}
			categoriesOrder={categoriesOrder}
			onDragEnd={onDragEnd}
			activeProducts={activeProducts}
			language={language}
			hiddenCategories={hiddenCategories}
			isChangedData={submitData}
		/>
	);
};

export default withRouter(withFirebase(SortModeProducts));
