import React, { useEffect, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link,
	NavLink,
	withRouter,
} from "react-router-dom";

import { withFirebase } from "../../../../firebase";

import Products from "./index";
import * as ROUTERS from "../../../../constants/routes";

const ProductsRoute = (props) => {
	const categories = useSelector((state) => state.shops.categories);
	const brand = JSON.parse(localStorage.getItem("currentBrand"));

	const [firstCategory, setFirstCategory] = useState();

	// const firstCategory = Object.keys(categories)[0];
	// const firstCategory = categories[0]?.categoryId;

	const getCategoriesOrderData = () => {
		const keyBrand = JSON.parse(localStorage.getItem("chosenBrand"));
		const keyCity = JSON.parse(localStorage.getItem("chosenCity"));
		const isDebug = JSON.parse(localStorage.getItem("isDebug"));

		props.firebase.bd
			.ref(
				`${
					isDebug ? "debug" : ""
				}/settings/city/${keyCity}/brands/${keyBrand}/category/categoriesOrder`
			)
			.once("value", (snapshot) => {
				const categoriesOrderData = snapshot.val();
				if (categoriesOrderData) {
					setFirstCategory(categoriesOrderData[0]);
				} else {
					setFirstCategory(categories[0]?.categoryId);
				}
			});
	};

	const { history, location } = props;

	useEffect(() => {
		getCategoriesOrderData();
	}, []);

	useEffect(() => {
		if (firstCategory) {
			history.push(
				`/page/menu/products/${brand.id}/sort-mode/products/category/${firstCategory}`
			);
		}
	}, [firstCategory]);

	return (
		<>
			<div></div>
		</>
	);
};

export default withFirebase(withRouter(ProductsRoute));
