import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import LinkIcon from "@material-ui/icons/Link";
import * as clipboard from "clipboard-polyfill/text";

import EyeIcon from "../../../assets/img/eye";
import EyeHiddenIcon from "../../../assets/img/eyeHidden";

import s from "./edit.module.css";

class cardProduct extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isActiveAlert: false,
			copyProductId: null,
		};
	}

	copyText = (id) => {
		console.log("id", id);
		clipboard
			.writeText(id)
			.then(() => this.setState({ isActiveAlert: true, copyProductId: id }))
			.catch(() => console.log("Error write text!"));
		setTimeout(
			() => this.setState({ isActiveAlert: false, copyProductId: null }),
			2000
		);
	};

	render() {
		let item = this.props.item;
		let language = this.props.language;
		const trim = (str) => (str.length > 100 ? str.slice(0, 97) + "..." : str);
		let isActive =
			this.props.defaultHiddenProducts &&
			this.props.defaultHiddenProducts.includes(item.id);
		let key = Object.keys(item.spots)[0];

		let price =
			item.spots &&
			item.spots.filter((spot) => spot.visible === "1") &&
			item.spots.filter((spot) => spot.visible === "1")[0] &&
			item.spots.filter((spot) => spot.visible === "1")[0].price / 100;
		return (
			<div
				className={s.product}
				style={{ maxWidth: this.props.open ? "33%" : "25%" }}
			>
				<div className={s.row}>
					<div>
						<div
							className={s.imgProduct}
							style={{
								backgroundImage: `url(${item.photo})`,
							}}
						>
							{/* <img className={s.imgPhoto} src={item.photo}/> */}
						</div>
						<div className={s.wraper}>
							<div className={s.info}>
								<div className={s.item}>
									<div className={s.titleWrap}>
										<span>Наименование</span>
										<div onClick={() => this.props.handleOffProduct(item.id)}>
											{!isActive && (
												<EyeIcon
													style={{
														fill: "black",
														cursor: "pointer",
													}}
												/>
											)}
											{isActive && (
												<EyeHiddenIcon
													style={{
														fill: "black",
														cursor: "pointer",
													}}
												/>
											)}
										</div>
										<div
											className={s.copyProduct}
											title={`Скопировать ID товара ${item.id}`}
											onClick={() => this.copyText(item.id)}
										>
											<div
												className={`${s.copyLinkIcon} ${
													this.state.isActiveAlert === item.id &&
													s.copyLinkIconActive
												}`}
											>
												<LinkIcon />{" "}
											</div>
										</div>
									</div>
									<div className={s.titleText}>
										{item && item.name && item.name[language]
											? item.name[language]
											: "Описание отсутствует"}
									</div>
								</div>
								<div className={s.item}>
									<span>Описание</span>
									<div className={s.description}>
										{item.description && item.description[language]
											? trim(item.description[language])
											: "Описание отсутствует"}
									</div>
								</div>
								<div className={s.item}>
									<span>Цена</span>
									{price ? price : "0"}
								</div>
							</div>
						</div>
					</div>
					<div className={s.buttonWrappers}>
						<div
							className={s.button}
							onClick={() => this.props.statusEditModal(item.id)}
						>
							Редактировать
						</div>
						<div
							className={s.button}
							onClick={() =>
								this.props.deleteProduct(item.id, item.name[language])
							}
						>
							Удалить
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default cardProduct;
