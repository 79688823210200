import React from 'react';
import { makeStyles } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const SelectComponent = ({ name, stateValue, handleChange, options, label, className }) => {
    const classes = useStyles();
    return (
        <FormControl className={`${classes.formControl} ${className}`}>
            <Select
                labelId={`${name}-label`}
                displayEmpty
                classes={{ 
                    root: classes.root,
                    icon: classes.icon,
                    focused: classes.focused,
                }}
                id={name}
                value={stateValue}
                onChange={handleChange}
            >
                {/* <MenuItem style={{ display: 'none' }} value={1}><div className={classes.firstInput}>{label}</div></MenuItem> */}
                {options.map(({id, name}) => (
                    <MenuItem value={id}><div className={classes.firstInput}>{name}</div></MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        // background: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        background: 'white',
        borderRadius: 5,
        lineHeight: "35px",
        fontSize: 14,
        color: theme.palette.primary.main,
        textAlign: 'center',
        boxSizing: 'border-box',
        '&.MuiSelect-select.MuiSelect-select': {
            padding: '4px 29px 4px 10px',
            borderRight: 'none',
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            borderColor: 'rgba(0, 0, 0, 0.23)',
            color: 'rgba(0, 0, 0, 0.23)',
            fontWeight: 400,
            fontSize: 14,
        },
        // '&.MuiInputBase-root': {
        //     borderRadius: 5,
        //     background: 'red',
        // }
    },
    focused: {
        borderRadius: 5,
        background: 'white',
    },
    icon: {
        color: '#ccc',
        right: '10px',
    },
     formControl: {
        background: 'white',
        borderRadius: 5,
         '& > div:after': {
             content: 'none',
         },
         '& > div:before': {
             content: 'none',
         },
     }
}));

export default SelectComponent;