import { makeStyles } from '@material-ui/core';

export default makeStyles({
    root: {
        marginTop: '24px',
        marginBottom: '0px',
    },
    workTimeWrap: {
        marginBottom: 24,
    },
    labelWrap: {
        display: 'block',
        marginBottom: '8px',
        '& > label': {
            fontSize: 13,
            lineHeight: 1,
            fontWeight: 400,
            color: '#333',
        }
    },
    calendarField: {
        width: 140,
        textAlign: 'center',
        height: 30,
        fontSize: 16,
        lineHeight: '30px',
        border: '1px solid #DADCE0',
        boxSizing: 'border-box',
        borderRadius: 5,
        overflow: 'hidden',
        '& .MuiInput-input': {
            textAlign: 'center',
            padding: '5px 0 7px',
            color: '#476282',
        },
        '& .MuiInput-input:disabled': {
            background: '#f5f5f5',
            border: '1px solid transparent',
        },
        '& .MuiInput-underline:after': {
            content: 'none'
        },
        '& .MuiInput-underline:before': {
            content: 'none'
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            content: 'none'
        },
        '& + div': {
            fontSize: 12,
            lineHeight: 2,
            color: 'red',
        }
    },
    textAreaField: {
        width: '100%',
        '& > .MuiInputBase-multiline': {
            border: '1px solid #DADCE0',
            borderRadius: 5,
            padding: '12px 6px',
            '&:after': {
                display: 'none'
            },
            '&:before': {
                display: 'none'
            }
        }
    },
    button: {
        background: '#326dff',
      borderRadius: 3,
      border: 0,
      color: 'white',
      height: 45,
      minWidth: 180,
      width: 'auto',
      fontSize: 14,
      marginTop: 10,
      boxShadow: 'none',
      '&:hover': {
        background: '#326dffcf',
        boxShadow: 'none',
      }
    },
    textField: {
        fontSize: 14,
        lineHeight: 1.5,
        fontWeight: 400,
        color: '#326dff',
    }
});