import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withFirebase } from "../../../firebase";
import EditProducts from "./editProducts";
import Preloader from "../preloader/preloader";
import axios from "axios";
import {
	updateVisibility,
	deleteProduct,
	updateCategories,
} from "../../../reducers/shops";

class EditProductsContainer extends Component {
	constructor() {
		super();

		this.state = {
			cities: [],
			categories: [],
			activeCategory: null,
			products: [],
			defaultHiddenProducts: null,
			languageModal: false,
			languages: [],
			language: "",
			defaultLanguage: "",
			editModal: false,
			editId: null,
			preloader: false,
			categoriesOrder: [],
		};
	}

	componentDidMount() {
		this.getLanguages();
		this.getCategoriesOrderData();

		this.getCategories(this.props.match.params.brandId);
		this.getDefaultHiddenProducts();
		// this.updateCategory();
	}

	componentDidUpdate(prevProps) {
		// Проверяем, изменился ли параметр id в URL
		if (this.props.match.params.brandId !== prevProps.match.params.brandId) {
			// Выполняем необходимые действия при изменении id
			const newId = this.props.match.params.brandId;
			this.getCategories(newId);
		}
	}

	getDefaultHiddenProducts = () => {
		const currentCity = JSON.parse(localStorage.getItem("chosenCity"));
		const currentBrand = JSON.parse(localStorage.getItem("chosenBrand"));
		this.setState({ preloader: true });

		this.props.firebase.bd
			.ref(
				`${
					JSON.parse(localStorage.getItem("isDebug")) ? "debug" : ""
				}/settings/city/${currentCity}/brands/${currentBrand}/category/hiddenProducts`
			)
			.once("value", (snapshot) => {
				let defaultHiddenProducts = snapshot.val();

				this.setState({ defaultHiddenProducts, preloader: false });
			});
	};

	getCategoriesOrderData = () => {
		const keyBrand = JSON.parse(localStorage.getItem("chosenBrand"));
		const keyCity = JSON.parse(localStorage.getItem("chosenCity"));
		const isDebug = JSON.parse(localStorage.getItem("isDebug"));

		this.props.firebase.bd
			.ref(
				`${
					isDebug ? "debug" : ""
				}/settings/city/${keyCity}/brands/${keyBrand}/category/categoriesOrder`
			)
			.once("value", (snapshot) => {
				const categoriesOrder = snapshot.val();
				this.setState({ categoriesOrder });
			});
	};

	getDefaultLanguage = () => {
		this.setState({ preloader: true });
		this.props.firebase.bd
			.ref(
				`${
					JSON.parse(localStorage.getItem("isDebug")) ? "debug" : ""
				}/adminPermission/defaultLanguage`
			)
			.once("value", (snapshot) => {
				let defaultLanguage = snapshot.val();

				console.log("defaultLanguage", defaultLanguage);

				if (defaultLanguage) {
					this.setState({
						defaultLanguage,
						language: defaultLanguage,
						preloader: false,
					});
				}

				this.setState({ preloader: false });
			});
	};

	getLanguages = () => {
		let defaultLanguage = this.state.defaultLanguage;

		if (!defaultLanguage || defaultLanguage.length === 0) {
			this.setState({ preloader: true });
			this.props.firebase.bd
				.ref(
					`${
						JSON.parse(localStorage.getItem("isDebug")) ? "debug" : ""
					}/adminPermission/defaultLanguage`
				)
				.once("value", (snapshot) => {
					let val = snapshot.val();

					defaultLanguage = val;

					this.setState({ defaultLanguage, preloader: false });
				});
		}

		// let languages = this.props.languages;
		let languages = [];

		if (!languages || languages.length === 0) {
			this.setState({ preloader: true });
			this.props.firebase.bd
				.ref(
					`${
						JSON.parse(localStorage.getItem("isDebug")) ? "debug" : ""
					}/adminPermission/languages`
				)
				.once("value", (snapshot) => {
					let val = snapshot.val();

					languages = val.sort(function (a, b) {
						if (defaultLanguage) {
							if (a.key !== defaultLanguage) {
								return 1;
							} else {
								return -1;
							}
						}
					});

					this.setState({ languages, preloader: false });
					// this.getCategories();
				});
		} else {
			languages = languages.sort(function (a, b) {
				if (defaultLanguage) {
					if (a.key !== defaultLanguage) {
						return 1;
					} else {
						return -1;
					}
				}
			});
			this.setState({ languages });
			// this.getCategories();
		}
	};

	getCategories = (brandId) => {
		let items = this.props.categories;
		let defaultLanguage = this.props.defaultLanguage;

		if (defaultLanguage) {
			// if (items.length === 0) {
			this.setState({ preloader: true });

			this.props.firebase.bd
				.ref(
					`${
						JSON.parse(localStorage.getItem("isDebug")) ? "debug" : ""
					}/EAppCRM/${brandId}/nomenclature/categories`
				)
				.once("value", (snapshot) => {
					let categories = snapshot.val();

					const list = categories
						? categories.map((item) => ({
								name: item.name[defaultLanguage],
								id: item.categoryId,
						  }))
						: [];

					const sortedCategories = [];
					for (const categoryId of this.state.categoriesOrder) {
						const category = categories.find(
							(cat) => cat.categoryId === categoryId
						);

						if (category) {
							sortedCategories.push({
								id: category.categoryId,
								name: category.name[defaultLanguage],
							});
						}
					}

					if (sortedCategories.length > 0) {
						this.setState({
							categories: sortedCategories,
							preloader: false,
						});
						this.updateCategory(
							this.props.match.params.id
								? this.props.match.params.id
								: sortedCategories[0].id
						);
					} else {
						this.setState({ categories: list, preloader: false });
						this.updateCategory(this.props.match.params.id);
					}
				});
		}
	};

	hiddenCategory = (data, open) => {
		const currentCity = JSON.parse(localStorage.getItem("chosenCity"));
		const currentBrand = JSON.parse(localStorage.getItem("chosenBrand"));
		const isDebug = JSON.parse(localStorage.getItem("isDebug"));

		if (open) {
			console.log("+");
			this.setState({ preloader: true });
			this.props.firebase.bd
				.ref(
					`${
						isDebug ? "debug" : ""
					}/settings/city/${currentCity}/brands/${currentBrand}/category/hiddenCategories`
				)
				.update(data)
				.then((res) => {
					this.setState({ preloader: false });
				});
		}

		if (!open) {
			console.log("-");
			this.setState({ preloader: true, hiddenProducts: data });
			this.props.firebase.bd
				.ref(
					`${
						isDebug ? "debug" : ""
					}/settings/city/${currentCity}/brands/${currentBrand}/category/hiddenCategories`
				)
				.set(data)
				.then((res) => {
					this.setState({
						preloader: false,
					});
				});
		}
	};

	changeVisibleProduct = (data, id) => {
		console.log("data, id", data, id);

		this.setState({ preloader: true });
		this.props.firebase.bd
			.ref(
				`${
					JSON.parse(localStorage.getItem("isDebug")) ? "debug" : ""
				}/products/${id}`
			)
			.update(data)
			.then((res) => {
				this.setState({
					preloader: false,
				});
			});
	};

	hiddenProduct = (data) => {
		console.log("data to hidden products", data);

		const currentCity = JSON.parse(localStorage.getItem("chosenCity"));
		const currentBrand = JSON.parse(localStorage.getItem("chosenBrand"));
		const isDebug = JSON.parse(localStorage.getItem("isDebug"));

		this.setState({ preloader: true, defaultHiddenProducts: data });
		this.props.updateVisibility({ list: data, params: "hiddenProducts" });
		this.props.firebase.bd
			.ref(
				`${
					isDebug ? "debug" : ""
				}/settings/city/${currentCity}/brands/${currentBrand}/category/hiddenProducts`
			)
			.set(data)
			.then((res) => {
				this.setState({
					preloader: false,
				});
			});
	};

	updateCategory = (id) => {
		console.log("-- upd category ---");

		console.log(id);
		// let categoryId = Object.keys(this.props.categories)[0];
		let categoryId = this.props.categories[0]?.categoryId;
		let pathId = this.props.match.params.id;
		pathId = pathId ? pathId : null;

		categoryId = categoryId ? categoryId : pathId;
		id = id ? id : categoryId;

		const brand = JSON.parse(localStorage.getItem("currentBrand"));
		const isDebug = JSON.parse(localStorage.getItem("isDebug"));

		let self = this;
		if (categoryId) {
			self.setState({ preloader: false });
			self.props.firebase.bd
				.ref(
					`${isDebug ? "debug" : ""}//EAppCRM/${brand.id}/nomenclature/products`
				)
				.orderByChild("categoryId")
				.equalTo(id)
				.once("value", function (snapshot) {
					let items = snapshot.val();
					let list = [];

					for (let item in items) {
						items[item].id = items[item].productId;

						list.push(items[item]);
					}

					self.setState({
						activeCategory: id,
						products: list.reverse(),
						preloader: false,
					});
				});
		}
	};

	statusLanguageModal = () => {
		this.setState({
			languageModal: this.state.languageModal ? false : true,
		});
	};

	deleteProduct = (id, name) => {
		if (window.confirm(`Вы хотите удалить товар ${name}?`)) {
			let index = this.state.products.findIndex((x) => x.id === id);
			const updateProducts = this.state.products.filter((_, i) => i !== index);

			console.log("id -->>", id);

			const brand = JSON.parse(localStorage.getItem("currentBrand"));
			const isDebug = JSON.parse(localStorage.getItem("isDebug"));
			this.setState({
				products: updateProducts,
			});

			this.props.firebase.bd
				.ref(
					`${isDebug ? "debug" : ""}//EAppCRM/${brand.id}/nomenclature/products`
				)
				.once("value")
				.then((snapshot) => {
					const products = snapshot.val() || [];

					const updatedProducts = products.filter(
						(item) => item.productId !== id
					);

					this.props.firebase.bd
						.ref(
							`${isDebug ? "debug" : ""}/EAppCRM/${
								brand.id
							}/nomenclature/products`
						)
						.set(updatedProducts)
						.then(() => {
							this.props.deleteProduct(id);
							alert("Готово");
						});
				})
				.catch((res) => {
					alert("У вас нет доступа");
				});
		}
	};

	statusEditModal = (id) => {
		this.setState({
			editModal: this.state.editModal ? false : true,
			editId: id,
		});
	};

	changeActiveCategory = (activeCategory) => {
		this.setState({
			activeCategory,
			preloader: false,
		});
	};

	onSubmit = (data) => {
		console.log("data --- >>> ", data);

		let languagesName = {};
		let languagesDescription = {};
		const isDebug = JSON.parse(localStorage.getItem("isDebug"));

		for (let index in this.state.languages) {
			let key = this.state.languages[index].key;
			let name = "name_" + key;
			let description = "description_" + key;

			if (data[name]) {
				languagesName[key] = data[name];
			}

			if (data[description]) {
				languagesDescription[key] = data[description];
			}
		}

		this.setState({ preloader: true });

		let self = this;

		let product = this.state.products.find((x) => x.id === this.state.editId);
		let index = this.state.products.findIndex(
			(x) => x.id === this.state.editId
		);

		let id = product.id;

		product = {
			...product,
			name: languagesName,
			description: languagesDescription,
			menuCategoryId: data.categories,
			spots: {
				...product.spots,
				0: {
					...product.spots[0],
					price: String(Number(data.firstPrice) * 100),
				},
			},
		};

		product.id = null;
		product.open = null;

		if (data.photo) {
			let file = new FormData();
			file.append("photo", data.photo[0]);
			file.append("login", this.props.login);
			axios
				.post(`https://e-admin.com.ua/photo/index.php`, file)
				.then((res) => {
					const namePhoto = res.data.image_name.split(" ")[0];
					const isSuccess = res.data.check_upload_image;

					if (isSuccess) {
						product = {
							...product,
							namePhoto: namePhoto,
							photo: `https://e-admin.com.ua/photo/photo/uploads/${this.props.login}/${namePhoto}`,
						};
						self.props.firebase.bd
							.ref(`${isDebug ? "debug" : ""}/products/${id}`)
							.update(product)
							.then((res) => {
								product.id = id;
								self.state.products[index] = product;
							})
							.then((res) => {
								self.statusEditModal();
								self.setState({ preloader: false });
								alert("Готово");
							})
							.catch((res) => {
								console.log("error 1", res);
								self.setState({ preloader: false });
								alert("Произошла ошибка, попробуйте еще");
							});
					} else {
						console.log("error 2", res);
						alert("Ошибка, попробуйте ещё раз!");
						self.setState({ preloader: false });
					}
				})
				.catch((res) => {
					console.log("error 3", res);
					alert("Ошибка, попробуйте ещё раз!");
					self.setState({ preloader: false });
				});
		} else {
			this.props.firebase.bd
				.ref(`${isDebug ? "debug" : ""}/products/${id}`)
				.update(product)
				.then((res) => {
					product.id = id;
					self.state.products[index] = product;
				})
				.then((res) => {
					self.statusEditModal();
					self.setState({ preloader: false });
					alert("Готово");
				})
				.catch((res) => {
					self.setState({ preloader: false });
					console.log("error", res);
					alert("Произошла ошибка, попробуйте еще");
				});
		}
	};

	render() {
		// console.log("state (editProducts) -- >>>", this.state);
		// console.log('loader', this.state.loader);
		// console.log('props -->>', this.props);

		return (
			<>
				{this.state.preloader ? (
					<Preloader />
				) : (
					<EditProducts
						categories={this.state.categories}
						changeActiveCategory={this.changeActiveCategory}
						cities={this.props.cities}
						changeVisibleProduct={this.changeVisibleProduct}
						hiddenCategories={this.props.hiddenCategories}
						hiddenProducts={this.props.hiddenProducts}
						defaultHiddenProducts={this.state.defaultHiddenProducts}
						hiddenProduct={this.hiddenProduct}
						getCategories={this.getCategories}
						activeCategory={this.state.activeCategory}
						updateCategory={this.updateCategory}
						products={this.state.products}
						languageModal={this.state.languageModal}
						defaultLanguage={this.props.defaultLanguage}
						language={this.props.defaultLanguage}
						languages={this.props.languages}
						statusLanguageModal={this.statusLanguageModal}
						deleteProduct={this.deleteProduct}
						editModal={this.state.editModal}
						editId={this.state.editId}
						statusEditModal={this.statusEditModal}
						onSubmit={this.onSubmit}
						open={this.props.open}
					/>
				)}
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		login: state.auth.login,
		cities: state.shops.shops,
		categories: state.shops.categories,
		languages: state.shops.languages,
		defaultLanguage: state.shops.defaultLanguage,
		hiddenCategories: state.shops.hiddenCategories,
		hiddenProducts: state.shops.hiddenProducts,
	};
};

export default connect(mapStateToProps, { updateVisibility, deleteProduct })(
	withFirebase(withRouter(EditProductsContainer))
);
