import React, { useState } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles } from "@material-ui/core/styles";

import TabMobile from "../../../components/TabMobile";
import CategoriesForm from "./categoriesForm";
import ProductsForm from "./productsForm";
import ModifierGroupsForm from "./modifierGroupsForm";
import ProductModifcationForm from "./productModifcationForm";
import StopListForm from "./stopListForm";
import Typography from "@material-ui/core/Typography";

const PopoverComponent = (props) => {
	const classes = useStyles();
	const [active, setActive] = useState(0);
	const {
		isActiveCategoriesSnackbar,
		isActiveStopListSnackbar,
		isActiveModifierGroupSnackbar,
		isActiveProductModificationSnackbar,
		isActiveMenusSnackbar,
		updateVisibility,
		hiddenCategories,
		hiddenProducts,
		hiddenModifierGroups,
		hiddenProductModification,
		loadStatus,
		saveData,
		stopList,
		adminPermission,
	} = props;
	// console.log("props ---> ", props);
	const isMin600 = useMediaQuery("(max-width: 599px)");
	const isMax600 = useMediaQuery("(min-width: 600px)");

	const handleTabs = (el) => {
		const id = el.target.id;
		setActive(Number(id));
	};

	return (
		<div>
			<div className={classes.tabWrapper}>
				{isMin600 && (
					<Typography className={classes.titleMobile} variant="h1">
						Стоп-лист
					</Typography>
				)}
				<div className={classes.tabContent}>
					{isMin600 && (
						<>
							<TabMobile
								data={["Категории", "Товары"]}
								active={active}
								handleTabs={handleTabs}
							/>

							{active === 0 && (
								<CategoriesForm
									hiddenCategories={hiddenCategories}
									updateVisibility={updateVisibility}
									loadStatus={loadStatus}
									isActiveCategoriesSnackbar={isActiveCategoriesSnackbar}
									saveData={saveData}
								/>
							)}
							{active === 1 && (
								<ProductsForm
									hiddenProducts={hiddenProducts}
									changeVisibleProduct={props.changeVisibleProduct}
									products={props.products}
									updateVisibility={updateVisibility}
									isActiveMenusSnackbar={isActiveMenusSnackbar}
									loadStatus={loadStatus}
									saveData={saveData}
								/>
							)}

							{active === 1 && adminPermission.stopList && (
								<ProductsForm
									hiddenProducts={hiddenProducts}
									changeVisibleProduct={props.changeVisibleProduct}
									products={props.products}
									updateVisibility={updateVisibility}
									isActiveMenusSnackbar={isActiveMenusSnackbar}
									loadStatus={loadStatus}
									saveData={saveData}
								/>
							)}

							{adminPermission.hiddenModifierGroupsControl && (
								<>
									<Typography className={classes.titleMobile}>
										Группа модификаторов
									</Typography>
									<ModifierGroupsForm
										hiddenModifierGroups={hiddenModifierGroups}
										updateVisibility={updateVisibility}
										isActiveModifierGroupSnackbar={
											isActiveModifierGroupSnackbar
										}
										loadStatus={loadStatus}
										saveData={saveData}
									/>
								</>
							)}

							{adminPermission.hiddenProductModificationControl && (
								<>
									<Typography className={classes.titleMobile}>
										Товар модификатор
									</Typography>
									<ProductModifcationForm
										hiddenProductModification={hiddenProductModification}
										updateVisibility={updateVisibility}
										isActiveProductModificationSnackbar={
											isActiveProductModificationSnackbar
										}
										loadStatus={loadStatus}
										saveData={saveData}
									/>
								</>
							)}
						</>
					)}
					{isMax600 && (
						<div className={classes.desctopWrapper}>
							<Typography className={classes.title} variant="h1">
								Стоп-лист
							</Typography>
							<CategoriesForm
								hiddenCategories={hiddenCategories}
								updateVisibility={updateVisibility}
								loadStatus={loadStatus}
								isActiveCategoriesSnackbar={isActiveCategoriesSnackbar}
								saveData={saveData}
							/>
							<ProductsForm
								hiddenProducts={hiddenProducts}
								changeVisibleProduct={props.changeVisibleProduct}
								products={props.products}
								updateVisibility={updateVisibility}
								isActiveMenusSnackbar={isActiveMenusSnackbar}
								loadStatus={loadStatus}
								saveData={saveData}
							/>
							{adminPermission.hiddenModifierGroupsControl && (
								<ModifierGroupsForm
									hiddenModifierGroups={hiddenModifierGroups}
									updateVisibility={updateVisibility}
									isActiveModifierGroupSnackbar={isActiveModifierGroupSnackbar}
									loadStatus={loadStatus}
									saveData={saveData}
								/>
							)}
							{adminPermission.hiddenProductModificationControl && (
								<ProductModifcationForm
									hiddenProductModification={hiddenProductModification}
									updateVisibility={updateVisibility}
									isActiveProductModificationSnackbar={
										isActiveProductModificationSnackbar
									}
									loadStatus={loadStatus}
									saveData={saveData}
								/>
							)}

							{adminPermission.stopList && (
								<StopListForm
									stopList={stopList}
									changeVisibleProduct={props.changeVisibleList}
									products={props.products}
									updateVisibility={updateVisibility}
									isActiveStopListSnackbar={isActiveStopListSnackbar}
									loadStatus={loadStatus}
									saveData={saveData}
								/>
							)}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

const useStyles = makeStyles((theme) => ({
	tabWrapper: {},
	desctopWrapper: {
		padding: "24px 16px 24px 16px",
		background: "white",
		border: " 0.5px solid #DADCE0",
		boxSizing: "border-box",
		borderRadius: "5px 5px 0px 0px",
	},
	tabContent: {
		[theme.breakpoints.down("600")]: {
			padding: "16px 8px",
			background: "white",
			border: "1px solid #E5E5E5",
			borderRadius: 10,
		},
	},
	title: {
		marginBottom: 16,
		fontSize: 22,
		fontWeight: "bold",
		lineHeight: "29px",
	},
	titleMobile: {
		marginTop: 16,
		marginBottom: 8,
		fontSize: 22,
		fontWeight: "bold",
		lineHeight: "29px",
	},
}));

export default PopoverComponent;
