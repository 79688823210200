import React, { Component } from "react";
import { updatePushNotifications, updateShops } from "../../reducers/shops";

import Push from "./push";
import { withFirebase } from "../../firebase";
import AlertComponent from "../../components/AlertComponent";
import { connect } from "react-redux";

class PushContainer extends Component {
	constructor() {
		super();
		this.state = {
			loadStatus: false,
			phone: [],
			key: null,
			alertText: "tres",
			alertShow: false,
			alertType: "error",
			productPush: false,
			phonesList: [],
			delay: 0,
			isCheckedTime: false,
		};
	}

	componentDidMount() {
		this.getProductPushKey();
	}

	getProductPushKey = () => {
		this.setState({ loadStatus: true });
		this.props.firebase.bd
			.ref(
				`${
					JSON.parse(localStorage.getItem("isDebug")) ? "debug" : ""
				}/adminPermission/productPush`
			)
			.on("value", (snapshot) => {
				let productPush = snapshot.val();

				this.setState({ productPush, loadStatus: false });
			});
	};

	updatePhonesList = (data) => {
		this.setState((prevState) => ({
			phonesList: data,
		}));
	};

	sendPush = async (data) => {
		const { brandId, cityId, phone, audience, text, product, file } = data;
		const isDebug = JSON.parse(localStorage.getItem("isDebug"));

		let topic = "/topics/";
		let self = this;

		let keyPush = this.props.notificationKey;

		let notification = {
			body: text,
			// title: ""
			// если будет нужен тайтл для пушей то передавать его здесь
		};

		this.setState({
			loadStatus: true,
		});

		if (audience == "favorite") {
			topic += phone.replace(/-/g, "");
		} else if (audience === "city") {
			topic += `city_${cityId}`;
		} else if (audience === "brand") {
			topic += `brand_${brandId}`;
		} else if (audience === "all") {
			topic += "all";
		} else {
			topic += "all";
		}

		console.log("phones list ----- > ", this.state.phonesList);

		const phonesString = this.state.phonesList
			.map((item) => "'" + item + "' in topics")
			.join(" || ");

		let body =
			audience !== "multiUser"
				? {
						topic: topic,
						notification,
				  }
				: {
						notification,
						condition: phonesString,
				  };

		if (product) {
			// body = {
			// 	...body,
			// 	notification: {
			// 		...notification,
			// 		click_action: `${this.props.bundleId}.FIREBASE_DATA`,
			// 		title: "",
			// 	},
			// 	data: {
			// 		product_id: product,
			// 		body: "",
			// 		title: "",
			// 	},
			// };
			body = {
				...body,
				notification: {
					...notification,
				},
				data: {
					product_id: product,
				},
				android: {
					notification: {
						click_action: `${this.props.bundleId}.FIREBASE_DATA`,
					},
				},
			};
		}

		if (audience === "multiUser") console.log("body --- >>>", body);

		console.log("body JSON =>", JSON.stringify(body));
		console.log("Authorization", "key=" + keyPush);
		console.log("audience -->>>", audience);

		// fetch("https://fcm.googleapis.com/fcm/send", {
		// 	method: "POST",
		// 	headers: {
		// 		Authorization: "key=" + keyPush,
		// 		"Content-Type": "application/json",
		// 	},
		// 	body: JSON.stringify(body),
		// })

		let delay =
			this.state.delay !== 0
				? new Date(this.state.delay).getTime() - Date.now()
				: 0;

		if (audience == "multiUser") {
			const chunkSize = 5;
			const phoneChunks = this.chunkArray(this.state.phonesList, chunkSize);

			// Функция для отправки одного чанка
			const sendChunk = async (chunk) => {
				const condition = chunk
					.map((item) => `'${item}' in topics`)
					.join(" || ");
				let body = {
					notification,
					condition,
				};

				if (product) {
					body = {
						...body,
						data: {
							product_id: product,
						},
						android: {
							notification: {
								click_action: `${this.props.bundleId}.FIREBASE_DATA`,
							},
						},
					};
				}

				console.log("body --- >>>", body);
				console.log("body JSON =>", JSON.stringify(body));

				if (!this.state.isCheckedTime && delay > 60000) {
					fetch(
						`https://epayment.com.ua/api/notifications/new/${this.props.bundleId}?delay=${delay}`,
						{
							method: "POST",
							headers: {
								"Content-Type": "application/json",
							},
							body: JSON.stringify(body),
						}
					).catch((error) => {
						// Обработка ошибки, если запрос не удался
						console.error("Ошибка при отправке запроса:", error);
						// Здесь можно, например, уведомить пользователя о неудаче
					});

					// Уведомляем пользователя об успешной отправке уведомления
					this.setState({
						alertText: "Ваше уведомление успешно отправлено!",
						alertShow: true,
						alertType: "success",
						loadStatus: false,
					});

					return;
				}

				try {
					const response = await fetch(
						`https://epayment.com.ua/api/notifications/new/${this.props.bundleId}?delay=`,
						{
							method: "POST",
							headers: {
								"Content-Type": "application/json",
							},
							body: JSON.stringify(body),
						}
					);

					if (!response.ok) {
						throw new Error("Network response was not ok");
					}
				} catch (error) {
					console.log("error in multiUser chunk --->>> ", error);
					throw error;
				}
			};

			if (!this.state.isCheckedTime && delay > 60000) {
				Promise.all(phoneChunks.map(sendChunk));
				return;
			}

			try {
				// Отправляем все чанки параллельно
				await Promise.all(phoneChunks.map(sendChunk));

				// После успешной отправки всех чанков
				this.setState({
					alertText: "Ваше уведомление успешно отправлено!",
					alertShow: true,
					alertType: "success",
					loadStatus: false,
				});
			} catch (error) {
				// Если хоть один из запросов завершился ошибкой
				this.setState({
					alertText: "При отправке произошла ошибка, попробуйте еще раз!",
					alertShow: true,
					alertType: "error",
					loadStatus: false,
				});
			}
		} else {
			if (delay > 60000) {
				fetch(
					`https://epayment.com.ua/api/notifications/new/${this.props.bundleId}?delay=${delay}`,
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify(body),
					}
				).catch((error) => {
					// Обработка ошибки, если запрос не удался
					console.error("Ошибка при отправке запроса:", error);
					// Здесь можно, например, уведомить пользователя о неудаче
				});

				// Уведомляем пользователя об успешной отправке уведомления
				this.setState({
					alertText: "Ваше уведомление успешно отправлено!",
					alertShow: true,
					alertType: "success",
					loadStatus: false,
				});

				return;
			}
			fetch(
				`https://epayment.com.ua/api/notifications/new/${this.props.bundleId}`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(body),
				}
			)
				.then(function (response) {
					const dateTime = new Date().getTime();
					const timestamp = Math.floor(dateTime / 1000);
					var key = parseInt(timestamp / 86400);

					if (audience == "favorite") {
						self.setState({
							alertText: "Ваше уведомление успешно отправлено!",
							alertShow: true,
							alertType: "success",
							loadStatus: false,
						});
					}

					if (audience == "multiUser") {
						self.setState({
							alertText: "Ваше уведомление успешно отправлено!",
							alertShow: true,
							alertType: "success",
							loadStatus: false,
						});
					}

					if (audience == "city") {
						// const city = self.props.cities.find((t) => t.spot_id === cityId);
						const city = self.props.cities.find((t) => t.id === cityId);
						console.log("city ---> ", city);
						const cityKeyShop = city.key;

						// let keyData =
						// 	city.pushNotifications && city.pushNotifications[key]
						// 		? city.pushNotifications[key]
						// 		: [];
						// keyData = [...keyData, text];

						// console.log('url', `settings/City/${cityKeyShop}/pushNotifications/${key}`);
						// console.log('city', city);
						// console.log('keyData', keyData);

						// self.props.firebase.bd
						// 	.ref(
						// 		`settings/сity/${cityKeyShop}/brands/${cityKeyShop}/pushNotifications/${key}`
						// 	)
						// 	.update(keyData)
						// 	.then((response) => {
						// 		let pushNotificationsData = { ...city.pushNotifications };
						// 		pushNotificationsData[key] = keyData;

						// 		self.props.updatePushNotifications(
						// 			cityKeyShop,
						// 			pushNotificationsData
						// 		);
						// 		self.setState({
						// 			alertText: "Ваше уведомление успешно отправлено!",
						// 			alertShow: true,
						// 			alertType: "success",
						// 			loadStatus: false,
						// 		});
						// 	})
						// 	.catch((error) => {
						// 		console.log(error);
						// 	});

						city.brands.forEach((brand, brandIndex) => {
							let keyData =
								brand.pushNotifications && brand.pushNotifications[key]
									? brand.pushNotifications[key]
									: [];

							keyData = [...keyData, text];

							self.props.firebase.bd
								.ref(
									`${
										isDebug ? "debug" : ""
									}/settings/city/${+cityKeyShop}/brands/${brandIndex}/pushNotifications/${key}`
								)
								.update(keyData)
								.then((response) => {
									self.setState({
										alertText: "Ваше уведомление успешно отправлено!",
										alertShow: true,
										alertType: "success",
										loadStatus: false,
									});
									self.props.firebase.bd
										.ref(`${isDebug ? "debug" : ""}/settings/city`)
										.on("value", (snapshot) => {
											let city = snapshot.val();
											let updatedCity = city.map((item, index) => {
												return {
													...item,
													key: index,
												};
											});
											self.props.updateShops(updatedCity);
										});
								})
								.catch((error) => {
									console.log(error);
								});
						});
					}

					if (audience == "brand") {
						const citiesList = self.props.cities;
						let brandIndex = -1;
						let cityIndex = -1;
						let brand;

						for (let index = 0; index < citiesList.length; index++) {
							const obj = citiesList[index];

							brand = obj.brands.find((b) => +b.id === +brandId);

							if (brand) {
								brandIndex = obj.brands.indexOf(brand);
								cityIndex = index;
								break;
							}
						}

						let keyData =
							brand.pushNotifications && brand.pushNotifications[key]
								? brand.pushNotifications[key]
								: [];
						keyData = [...keyData, text];

						self.props.firebase.bd
							.ref(
								`${
									isDebug ? "debug" : ""
								}/settings/city/${cityIndex}/brands/${brandIndex}/pushNotifications/${key}`
							)
							.update(keyData)
							.then((response) => {
								self.setState({
									alertText: "Ваше уведомление успешно отправлено!",
									alertShow: true,
									alertType: "success",
									loadStatus: false,
								});
								self.props.firebase.bd
									.ref(`${isDebug ? "debug" : ""}/settings/city`)
									.on("value", (snapshot) => {
										let city = snapshot.val();
										let updatedCity = city.map((item, index) => {
											return {
												...item,
												key: index,
											};
										});
										self.props.updateShops(updatedCity);
									});
							})
							.catch((error) => {
								console.log(error);
							});
					}

					if (audience == "all" || audience == undefined) {
						const cities = self.props.cities;

						// let citiesData = [ ...cities ];

						cities.forEach((city, index) => {
							// const notificatonKey = city.pushNotifications && city.pushNotifications[key] ? city.pushNotifications[key] : [];
							// let pushNotifications = city.pushNotifications ? city.pushNotifications : {};
							// pushNotifications = { ...pushNotifications, [key]: [ ...notificatonKey, text ] };

							// let keyData =
							// 	city.pushNotifications && city.pushNotifications[key]
							// 		? city.pushNotifications[key]
							// 		: [];
							// keyData = [...keyData, text];

							// citiesData[index] = {
							//     ...citiesData[index],
							//     pushNotifications: {
							//         ...pushNotifications,
							//         [key]: [ ...notificatonKey, text ]
							//     }
							// }

							// self.props.firebase.bd
							// 	.ref(`settings/City/${index}/pushNotifications/${key}`)
							// 	.update(keyData)
							// 	.then((response) => {
							// 		self.setState({
							// 			alertText: "Ваше уведомление успешно отправлено!",
							// 			alertShow: true,
							// 			alertType: "success",
							// 			loadStatus: false,
							// 		});
							// 	})
							// 	.catch((error) => {
							// 		console.log(error);
							// 	});

							city.brands.forEach((brand, brandIndex) => {
								let keyData =
									brand.pushNotifications && brand.pushNotifications[key]
										? brand.pushNotifications[key]
										: [];
								keyData = [...keyData, text];

								self.props.firebase.bd
									.ref(
										`${
											isDebug ? "debug" : ""
										}/settings/city/${index}/brands/${brandIndex}/pushNotifications/${key}`
									)
									.update(keyData)
									.then((response) => {
										self.setState({
											alertText: "Ваше уведомление успешно отправлено!",
											alertShow: true,
											alertType: "success",
											loadStatus: false,
										});
										self.props.firebase.bd
											.ref(`${isDebug ? "debug" : ""}/settings/city`)
											.on("value", (snapshot) => {
												let city = snapshot.val();
												let updatedCity = city.map((item, index) => {
													return {
														...item,
														key: index,
													};
												});
												self.props.updateShops(updatedCity);
											});
									})
									.catch((error) => {
										console.log(error);
									});
							});
						});

						// console.log('citiesData Data ==>>>>', citiesData);
						// self.props.firebase.bd.ref(`settings/City/${cityKeyShop}/pushNotifications/${key}`).update(keyData)
						//     .then(response => {
						//         self.setState({
						//             alertText: 'Ваше уведомление успешно отправлено!',
						//             alertShow: true,
						//             alertType: 'success',
						//             loadStatus: false
						//         })
						//     })
						//     .catch(error => {
						//         console.log(error);
						//     });
					}
				})
				.catch(function (error) {
					console.log("error --->>> ", error);
					self.setState({
						alertText: "При отправке произошла ошибка, попробуйте еще раз!",
						alertShow: true,
						alertType: "error",
						loadStatus: false,
					});
				});
		}
	};

	chunkArray = (array, chunkSize) => {
		const chunks = [];
		for (let i = 0; i < array.length; i += chunkSize) {
			chunks.push(array.slice(i, i + chunkSize));
		}
		return chunks;
	};

	addPhone = (data) => {
		let phoneList = this.state.phone;
		phoneList.push(data.phone);
		this.setState({
			phone: phoneList,
		});
	};

	deletePhone = (key) => {
		let phoneList = this.state.phone;
		phoneList.splice(key, 1);
		this.setState({
			phone: phoneList,
		});
	};

	hideAlert = () => {
		this.setState({
			alertShow: false,
		});
	};

	updateDelay = (newDelay) => {
		this.setState({ delay: newDelay });
	};

	updateChekedTime = (params) => {
		this.setState({ isCheckedTime: params });

		console.log(this.state.isCheckedTime);
	};

	render() {
		return (
			<>
				<Push
					onSubmit={this.sendPush}
					productPush={this.state.productPush}
					loadStatus={this.state.loadStatus}
					phone={this.state.phone}
					addPhone={this.addPhone}
					deletePhone={this.deletePhone}
					updatePhonesList={this.updatePhonesList}
					updateDelay={this.updateDelay}
					updateCheckedTime={this.updateChekedTime}
				/>
				{this.state.alert}
				<AlertComponent
					type={this.state.alertType}
					text={this.state.alertText}
					status={this.state.alertShow}
					hideAlert={this.hideAlert}
				/>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	console.log("state --- >>>", state);
	return {
		cities: state.shops.shops,
		notificationKey: state.shops.mainSettings?.notificationKey,
		bundleId: state.shops.mainSettings?.bundleId,
	};
};

export default connect(mapStateToProps, {
	updatePushNotifications,
	updateShops,
})(withFirebase(PushContainer));
