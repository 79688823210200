import React from 'react';
import { reduxForm, reset } from 'redux-form';
import {connect, useSelector} from 'react-redux';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import ModalComponent from '../../../components/ModalComponent';
import FormControlGroup from '../FormControlGroup';
import InputInfoWorkingTime from '../InputInfoWorkingTime';
import InputDeliveryEndWorkingTime from '../InputDeliveryEndWorkingTime';
import PopoverComponent from '../Popover';
import Button from '../../../components/Button';

import {
    BodyTableCell,
    FooterTableCell,
    HeadTableCell
} from '../TableComponents';

import { daysArr } from './other';
import useStyles from './styles';
import { TableCell } from '@material-ui/core';

let TableForm = (props) => {

    const { 
        open,
        handleSubmit,
        handleClose,
        handleClickSubmit,
        handleClickOpen,
        setSendData,
     } = props;

    const classes = useStyles();
    const dataForm = useSelector(state => state.workingHours.dataForm);
    return (
        <div>
            <form className={classes.form} onSubmit={handleSubmit} autoComplete='off'>
                <ModalComponent
                        title="Подтвердить действие!"
                        description="Вы подтверждаете сохранение данных?" 
                        open={open} 
                        handleClose={handleClose} 
                        handleClickSubmit={handleClickSubmit} />
                <TableContainer className={classes.tableContainer} component={Paper}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                {daysArr.map((day, index) => 
                                    <HeadTableCell key={index}>{day}</HeadTableCell >
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow className={classes.tablerow}>
                                {Object.keys(dataForm).length > 0 && daysArr.map((day, count) => {
                                    return (
                                        <BodyTableCell key={count}>
                                            <FormControlGroup 
                                                data={dataForm[day]} 
                                                name={day}
                                                count={count + 1} />
                                            <InputInfoWorkingTime 
                                                name={`days.${day}`} 
                                                data={dataForm[day]} />
                                        </BodyTableCell>
                                    )
                                })}
                            </TableRow >
                            <TableRow>
                                <TableCell colSpan={7}  component="th" scope="row" className={classes.footerTableCell}>
                                        <PopoverComponent title="Последнее время приема доставки:" />
                                </TableCell>
                            </TableRow>
                            <TableRow className={classes.footerTableRow}>
                                {Object.keys(dataForm).length > 0 && daysArr.map((day, count) => {
                                    return (
                                        <FooterTableCell key={count}>
                                            <InputDeliveryEndWorkingTime
                                                name={`endTime.${day}`} 
                                                data={dataForm[day]}
                                                orderTimeIntervalDelivery={props.orderTimeIntervalDelivery}
                                                count={count + 1} />
                                        </FooterTableCell>
                                    )
                                })}
                            </TableRow >
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className={classes.buttonWrapper}>
                    <Button
                        fontSize={22}
                        color={'#fff'}
                        className={classes.button} 
                        handleClick={handleSubmit(data => {
                           if (props.valid) {
                                setSendData(data);
                                handleClickOpen();
                           }
                        })}
                        disabled={props.preloader ? true : false}
                        text="Сохранить"
                    />
                </div>
            </form>
        </div>
    )
}

const afterSubmit = (_, dispatch) => dispatch(reset('TableForm'));

const mapStateToProps = (state) => {
    const {dataForm} = state.workingHours;

    let days = {};
    let endTime = {};
    let initialValues = {};

    // console.log('dataForm', dataForm);

    if (dataForm) {
        for (let key in dataForm) {
            days[key] = dataForm[key].begin.replace(':', '') + dataForm[key].end.replace(':', '');
            endTime[key] = dataForm[key].deliveryEnd.replace(':', '') === '0000' ? '2400': dataForm[key].deliveryEnd.replace(':', '');
        }
    }

    initialValues = {
        days,
        endTime
    }

    // console.log('initialValues *** .>>', initialValues);

    return { initialValues };
}

TableForm = connect(mapStateToProps, null)(reduxForm({
    form: 'TableForm',
    onSubmitSuccess: afterSubmit,
    enableReinitialize: true
})(TableForm));

export default TableForm;