import React from 'react';
import { Field, reduxForm } from 'redux-form'

import Button from '@material-ui/core/Button'; 
import Loading from '../../../components/Loading';
import useStyles from './styles';

const required = val => !val ? 'Обязательно для заполнения' : null;
const isNumber = val => val < 5 ? 'Введите число больше или равно 5' : null;

const renderField = ({ 
    input, 
    meta, 
    ...rest 
  }) => {
    return (
      <div>
          <input
            value={input.value}
            onChange={input.onChange} 
            {...rest} />
            {meta.touched && meta.error && <div>{meta.error}</div>}
      </div>
    )
}

let TableForm = (props) => {
    const classes = useStyles();
    const { handleSubmit } = props

    return (    
        <div className={classes.root}>
            <form onSubmit={props.handleSubmit} initialValues={props.initialValues}>

                <div className={classes.workTimeWrap}>
                    <div className={classes.labelWrap}>
                        <label>Интервал времени для доставки (мин)</label>
                    </div>
                    <Field 
                        className={classes.calendarField}
                        name="orderTimeIntervalDelivery"
                        component={renderField}
                        type="number"
                        placeholder=""
                        label=""
                        validate={[required, isNumber]}
                    />
                </div>

                <div className={classes.workTimeWrap}>
                    <div className={classes.labelWrap}>
                        <label>Интервал времени для самовывоза (мин)</label>
                    </div>
                    <Field 
                        className={classes.calendarField}
                        name="orderTimeIntervalPickup"
                        component={renderField}
                        type="number"
                        placeholder=""
                        label=""
                        validate={[required, isNumber]}
                    />
                </div>

                <Button 
                    onClick={handleSubmit} 
                    className={classes.button} 
                    disabled={props.preloader ? true : false}
                    variant="contained" 
                    color="primary">
                        {props.preloader ? <Loading size={22} color={'#fff'}/>  : 'Сохранить'}
                </Button>   
            </form>
        </div>
    )
}

TableForm = reduxForm({
    form: 'WorkingHours', 
    enableReinitialize : true
})(TableForm);

export default TableForm;