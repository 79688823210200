import React, { Component } from "react";

import { connect } from "react-redux";
import { withRouter } from "react-router";
import { withFirebase } from "../../../firebase";

import { editShops } from "../../../reducers/shops";
import Products from "./index";

class ProductsContainer extends Component {
	constructor() {
		super();

		this.state = {
			preloader: false,
			keyShop: null,
			saleFirstOrders: null,
			noDiscountForCategorieIds: null,
			noDiscountForProductIds: null,
		};
	}

	componentDidMount() {
		this.getKeyShop();
		this.getSalesFirstOrder();
	}

	getKeyShop = () => {
		let id = this.props.match.path.split("/");
		id = id[id.length - 1];

		this.setState({ keyShop: id });
	};

	getSalesFirstOrder = () => {
		const currentCity = JSON.parse(localStorage.getItem("chosenCity"));
		const currentBrand = JSON.parse(localStorage.getItem("chosenBrand"));
		const isDebug = JSON.parse(localStorage.getItem("isDebug"));

		this.props.firebase.bd
			.ref(
				`${
					isDebug ? "debug" : ""
				}/settings/city/${currentCity}/brands/${currentBrand}/loyaltySettings/saleFirstOrder`
			)
			.on("value", (snapshot) => {
				let saleFirstOrders = snapshot.val();

				saleFirstOrders = saleFirstOrders ? saleFirstOrders : [];

				this.setState({
					noDiscountForCategorieIds: saleFirstOrders.noDiscountForCategoriesIds
						? saleFirstOrders.noDiscountForCategoriesIds
						: [],
					noDiscountForProductIds: saleFirstOrders.noDiscountForProductsIds
						? saleFirstOrders.noDiscountForProductsIds
						: [],
				});
			});
	};

	editShops = (value, params) => {
		// let data = {
		// 	key: this.state.keyShop,
		// 	value,
		// 	params,
		// };
		// console.log("editShops", data);

		if (params === "noDiscountForCategorieIds") {
			// this.props.handleCategoriesPromocode(true);
			this.setState({ noDiscountForCategorieIds: value });
		} else {
			// this.props.handleProductsPromocode(true);
			this.setState({ noDiscountForProductIds: value });
		}

		// this.props.editShops(data);
	};

	saveData = () => {
		const shops = this.props.shops[this.state.keyShop];
		const currentCity = JSON.parse(localStorage.getItem("chosenCity"));
		const currentBrand = JSON.parse(localStorage.getItem("chosenBrand"));
		const isDebug = JSON.parse(localStorage.getItem("isDebug"));

		let noDiscountForCategorieIds;
		let noDiscountForProductIds;

		// if (shops) {
		// noDiscountForCategorieIds = shops.noDiscountForCategorieIds;
		// noDiscountForProductIds = shops.noDiscountForProductIds;
		noDiscountForCategorieIds = this.state.noDiscountForCategorieIds;
		noDiscountForProductIds = this.state.noDiscountForProductIds;

		let noDiscountForCategorieIdsIsValid =
			noDiscountForCategorieIds === undefined ? [] : noDiscountForCategorieIds;
		let noDiscountForProductIdsIsValid =
			noDiscountForProductIds === undefined ? [] : noDiscountForProductIds;

		this.setState({ preloader: true });
		this.props.firebase.bd
			.ref(
				`${
					isDebug ? "debug" : ""
				}/settings/city/${currentCity}/brands/${currentBrand}/loyaltySettings/saleFirstOrder`
			)
			.update({
				noDiscountForCategoriesIds: noDiscountForCategorieIdsIsValid,
				noDiscountForProductsIds: noDiscountForProductIdsIsValid,
			})
			.then((res) => {
				this.setState({ preloader: false });
				this.props.handleCategoriesPromocode(false);
				this.props.handleProductsPromocode(false);
			});
		// }
	};

	render() {
		// const shop = this.props.shops[this.state.keyShop];
		return (
			<>
				<Products
					loadStatus={this.state.loadStatus}
					saveData={this.saveData}
					editShops={this.editShops}
					// noDiscountForCategorieIds={shop ? shop.noDiscountForCategorieIds : []}
					// noDiscountForProductIds={shop ? shop.noDiscountForProductIds : []}
					noDiscountForCategorieIds={this.state.noDiscountForCategorieIds}
					noDiscountForProductIds={this.state.noDiscountForProductIds}
					handleCategoriesPromocode={this.props.handleCategoriesPromocode}
					handleProductsPromocode={this.props.handleProductsPromocode}
				/>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		shops: state.shops.shops,
		appSettings: state.shops.appSettings,
	};
};

export default connect(mapStateToProps, { editShops })(
	withRouter(withFirebase(ProductsContainer))
);
