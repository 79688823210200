import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import BannerUploader from "./BannerUploader";

const useStyles = makeStyles((theme) => ({
	modal: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: 400,
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		padding: theme.spacing(4),
		display: "flex",
		flexDirection: "column",
		gap: theme.spacing(2),
		maxHeight: "90vh",
		overflow: "auto",
		[theme.breakpoints.down("sm")]: {
			// Медиа-запрос для экранов меньше small (600px)
			width: "90%", // Устанавливаем максимальную ширину для маленьких экранов
			top: 0,
			left: 0,
			right: 0,
			transform: "translate(0, 0)",
			padding: "16px",
		},
	},
	modalButton: {
		marginTop: theme.spacing(2),
	},
	formControl: {
		width: "100%",
	},
	labelList: {
		marginBottom: theme.spacing(2),
		overflowY: "scroll", // Прокрутка вн
	},
	labelItem: {
		display: "flex",
		justifyContent: "space-between",
		padding: theme.spacing(1),
		backgroundColor: theme.palette.grey[200],
		borderRadius: theme.shape.borderRadius,
		marginBottom: "1px",
	},
	labelRemoveButton: {
		cursor: "pointer",
		color: theme.palette.error.main,
	},
}));

const LabelModal = ({
	id,
	open,
	onClose,
	onSave,
	initialData,
	uploadImage,
	handleDeleteImg,
}) => {
	const classes = useStyles();
	const [labelName, setLabelName] = useState("");
	const [position, setPosition] = useState(1);
	const [productsIds, setProductsIds] = useState([]);
	const [currentId, setCurrentId] = useState("");
	const [image, setImageURL] = useState();
	const [pendingImage, setPendingImage] = useState(null);
	const [errorMes, setErrorMes] = useState("");

	console.log(initialData);

	useEffect(() => {
		if (initialData) {
			setLabelName(initialData.name);
			setPosition(initialData.position || 1);
			setImageURL(initialData.image || "");
			setProductsIds(initialData.productsIds || []);
		} else {
			setLabelName("");
			setPosition(1);
			setImageURL("");
			setProductsIds([]);
		}
	}, [initialData]);

	const handleAddLabel = () => {
		if (currentId.trim() === "") return; // Проверка на пустой ID
		setProductsIds([...productsIds, currentId]);
		setCurrentId(""); // Очистка поля ввода после добавления
	};

	const clearForm = () => {
		setLabelName("");
		setPosition(1);
		setImageURL("");
		setProductsIds([]);
		setCurrentId("");
		setPendingImage(null);
	};

	const handleRemoveLabel = (id) => {
		setProductsIds(productsIds.filter((label) => label !== id));
	};

	const handleImageUpload = (img) => {
		setPendingImage(img);
		setErrorMes(""); // Сохраняем выбранное изображение, но не загружаем его
	};

	const handleSave = async () => {
		if (!image && !pendingImage) {
			setErrorMes("Изображение обязательно для сохранения");
			return;
		}

		let finalImageURL = image;

		// Если изображение выбрано и еще не загружено
		if (pendingImage) {
			finalImageURL = await uploadImage(pendingImage);
		}

		const isActive =
			initialData?.isActive !== undefined ? initialData?.isActive : true;

		const labelData = {
			tagName: "",
			isActive: isActive,
			position,
			image: finalImageURL,
			productsIds,
		};
		onSave(labelData);
		clearForm();
		onClose();
	};

	const setMinHeigth = (amount) => {
		const heightBLock = 65;
		if (amount > 3) {
			return 3 * heightBLock + "px";
		}
		return amount * heightBLock + "px";
	};

	return (
		<Modal open={open} onClose={onClose}>
			<Box className={classes.modal}>
				<Typography variant="h6">
					{initialData ? "Редактировать лейбл" : "Создать новый лейбл"}
				</Typography>

				<FormControl className={classes.formControl}>
					<InputLabel>Позиция</InputLabel>
					<Select
						value={position}
						onChange={(e) => setPosition(e.target.value)}
					>
						{[1, 2, 3, 4].map((pos) => (
							<MenuItem key={pos} value={pos}>
								{pos}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<TextField
					label="Добавить ID товара"
					value={currentId}
					onChange={(e) => setCurrentId(e.target.value)}
					fullWidth
				/>
				<Button variant="contained" color="primary" onClick={handleAddLabel}>
					Добавить
				</Button>
				<Box
					className={classes.labelList}
					style={{ minHeight: setMinHeigth(productsIds.length) }}
				>
					{productsIds.map((id, i) => (
						<Box key={id} className={classes.labelItem}>
							<Typography>{id}</Typography>
							<Button
								className={classes.labelRemoveButton}
								onClick={() => handleRemoveLabel(id)}
							>
								Удалить
							</Button>
						</Box>
					))}
				</Box>
				<BannerUploader
					image={image}
					setImageURL={setImageURL}
					handleImageUpload={handleImageUpload}
					handleDeleteImg={handleDeleteImg}
					errorMes={errorMes}
				/>
				<Button
					variant="contained"
					color="primary"
					onClick={handleSave}
					className={classes.modalButton}
				>
					{initialData ? "Сохранить изменения" : "Создать"}
				</Button>
				<Button
					variant="outlined"
					onClick={onClose}
					className={classes.modalButton}
				>
					Отмена
				</Button>
			</Box>
		</Modal>
	);
};

export default LabelModal;
