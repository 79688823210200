import React, { useState } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { required } from 'redux-form-validators';
import { Field, reduxForm } from 'redux-form';

import Button from '../../../../components/Button';
import Loading from '../../../../components/Loading';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import SnackbarContent from '@material-ui/core/SnackbarContent';

import CustomizedInputs from '../../../../components/UIform';
import useStyles from './styles';

const ProductsForm = (props) => {
    const { handleSubmit } = props;
    const classes = useStyles();
    const [isDelete, setIsDelete] = useState(null);
    const [stopListData, setStopListData] = useState(props.stopList);
    const isMin600 = useMediaQuery('(max-width: 599px)');
    const isMax600 = useMediaQuery('(min-width: 600px)');

    console.log('stopListData -->>', stopListData);

    
    let stopList = [];
    
    if (props.stopList) {
      stopList = [...props.stopList];
    }

    console.log('props stopList -->>>', stopList);
    
      const handleDelete = (chipToDelete) => () => {
        let list = stopList.filter((chip) => chip !== chipToDelete);
        const newListData = { ...stopListData };
        delete newListData[chipToDelete];
        // let product = props.products[chipToDelete];
        // if (product && product.spots) {
        //   product.spots[0].visible = "1";
        // } 

        console.log('chipToDelete, products -->>>', chipToDelete, props.products);

        setIsDelete(true);
        setStopListData({
          ...newListData
        });

        props.updateVisibility(list, 'stopList');
      };

      const addChip = (data) => {
        // console.log('addChip', data)
        let status = stopList.find((chip) => chip === data.id);

        const newData = [];

        for (let key in stopListData) {
          newData.push(stopListData[key]);
        }
        newData.push(data.id);
        
        console.log('newData', newData);
        console.log('status', status);
        
        setIsDelete(false);
        setStopListData(newData);

        if(status === undefined){
          let list = [...stopList, data.id];
            props.updateVisibility(list, 'stopList');
            props.reset();
        } else {
            alert('Данный ID уже есть в списке')
        }
      };

      const saveData = () => {
        props.saveData('stopList');

        // if (stopList && isDelete) {
        //   props.changeVisibleStopList(stopList);
        // }

        // if (stopList && !isDelete) {
        //   props.changeVisibleStopList(stopList);
        // }
      }

    return(
    <div className={classes.productsWrapper}>
     <Typography variant="h6" className={classes.title}>Стоп лист</Typography>
     <div className={classes.container}>
      <div className={classes.row}>
        <div className={classes.tagContainer}>
          {stopList.map((data, index) => {
              return (
                <li key={index}>
                  <Chip
                    label={data}
                    onDelete={handleDelete(data)}
                    className={classes.chip}
                  />
                  {isMin600 && <hr className={classes.hr} />}
                  <hr className={classes.hr} />
                </li>
              );
            })}
          </div>
          <form onSubmit={handleSubmit} className={classes.formContainer} autoComplete='off'>
              <Field  name="id"
                      className={classes.input}
                      component={CustomizedInputs}
                      type="text"
                      placeholder="Введите ID товара"
                      rows={'1'}
                      disabled={props.loadStatus ? true : false}
                      validate={required({msg: 'Это поле обязательно для заполнения'})}/>

                  <Button text={props.loadStatus ? 
                      <Loading size={22} color={'#fff'}/> : 'Добавить в список'} 
                                  disabled={props.loadStatus ? true : false}
                                  handleClick={handleSubmit(addChip)}
                                  className={classes.addToList} />
                  <div className={classes.bt}>
                  <Button text={props.loadStatus ? 
                      <Loading size={22} color={'#fff'}/> : 'Сохранить'} 
                                  disabled={props.loadStatus ? true : false}
                                  handleClick={saveData} 
                                  className={classes.saveButton} />
                  </div>
          </form>
          {props.isActiveStopListSnackbar && 
                  <SnackbarContent className={classes.snackbar} message="Данные успешно сохранены!" />}
      </div>
    </div>
    </div>
    )
}

export default reduxForm({
    form: 'StopListForm', 
  })(ProductsForm);