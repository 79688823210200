import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Field } from "redux-form";
import { required } from "redux-form-validators";

import Checkbox from "@material-ui/core/Checkbox";
import Popover from "../Popover";
import CustomizedInputPhone from "./inputPhone";

export const InputField = ({
	title,
	type,
	name,
	placeholder,
	component,
	validMsg,
	validate,
	onChange,
	value,
	className,
}) => {
	const classes = useStyles();
	return (
		<div className={classes.fieldWrapper}>
			<div className={classes.fieldLabelText}>{title}</div>
			<Field
				id={name}
				name={name}
				className={className ? className : classes.fieldCity}
				component={component ? component : CustomizedInputPhone}
				placeholder={placeholder}
				type={type ? type : "number"}
				label=""
				value={value}
				onChange={onChange}
				validate={
					validate ? validate : !!validMsg ? [required({ msg: validMsg })] : []
				}
			/>
		</div>
	);
};

export const CustomInputField = ({
	title,
	checkboxTitle,
	popoverHiddenText,
	name,
	placeholder,
	validMsg,
	checked,
	handleCheckedChange,
}) => {
	const classes = useStyles();
	return (
		<div className={classes.fieldWrapper}>
			<div
				style={{ color: "rgba(0,0,0, .5)" }}
				htmlFor={name}
				className={classes.fieldText}
			>
				{title}
				<Popover
					className={classes.popever}
					title=""
					hiddenTitle={popoverHiddenText}
				/>
			</div>
			<Field
				id={name}
				name={name}
				className={classes.fieldCity}
				component={CustomizedInputPhone}
				placeholder={placeholder}
				disabled={checked}
				type="number"
				label=""
				validate={!checked ? [required({ msg: validMsg })] : []}
			/>
			<div className={classes.freeDelivery}>
				<Checkbox
					className={classes.freeCheckBox}
					id="freeCheckBox"
					name="freeCheckBox"
					color="primary"
					defaultChecked
					value={checked}
					onChange={handleCheckedChange}
				/>
				<label htmlFor="freeCheckBox" className={classes.freeText}>
					{checkboxTitle}
				</label>
			</div>
		</div>
	);
};

const useStyles = makeStyles((theme) => ({
	fieldText: {
		position: "relative",
	},
	popever: {
		position: "absolute",
		top: 0,
		right: 0,
	},
	fieldWrapper: {
		marginTop: 16,
		marginRight: 16,
		[theme.breakpoints.down("600")]: {
			width: "100%",
			marginRight: 0,
			"& > div": {
				width: "100%",
			},
		},
	},
	fieldCity: {
		marginTop: 8,
		[theme.breakpoints.down("600")]: {
			width: "100%",
		},
		"& input": {
			width: 328,
			height: 45,
			border: "1px solid #DADCE0",
			borderRadius: "5px",
			padding: "11px 50px 11px 16px",
			boxSizing: "border-box",
			[theme.breakpoints.down("600")]: {
				width: "100%",
			},
		},
		"& .MuiInput-underline:hover:not(.Mui-disabled):before": {
			content: "none",
		},
		"& .MuiInput-underline:after": {
			content: "none",
		},
		"& .MuiInput-underline:before": {
			content: "none",
		},
		"& .Mui-error input": {
			border: "1px solid red",
		},
	},
	freeDelivery: {
		display: "flex",
		alignItems: "center",
		alignContent: "center",
	},
	freeCheckBox: {
		padding: "5px 5px 5px 0",
		"& .MuiSvgIcon-root": {
			width: 25,
			height: 25,
			color: "#326DFF",
		},
	},
	freeText: {
		cursor: "pointer",
		fontSize: 14,
		lineHeight: "16px",
		color: "#476282",
	},
	fieldLabelText: {
		fontSize: 16,
	},
}));
