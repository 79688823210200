import React, { useEffect, useState } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Field, reduxForm, reset } from "redux-form";
import Button from "../../components/Button";
import CustomizedInputs from "../../components/UIform";
import Loading from "../../components/Loading";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import CustomInputTextarea from "../../components/UIform/inputTextarea";

let CategoriesForm = (props) => {
	const { handleSubmit, numbers, updateBlackList, text } = props;
	const classes = useStyles();
	const isMin600 = useMediaQuery("(max-width: 599px)");

	const [textValue, setTextValue] = useState();
	const [snackbarVisible, setSnackbarVisible] = useState(false);

	let [phoneNumbers, setPhoneNumbers] = useState([]);

	let [number, setNumber] = useState("");

	useEffect(() => {
		setTextValue(text);
	}, [text]);

	useEffect(() => {
		setPhoneNumbers(numbers);
	}, [numbers]);

	const handleDelete = (chipToDelete) => {
		let updatedList = phoneNumbers.filter((chip) => chip !== chipToDelete);
		setPhoneNumbers(updatedList);
		updateBlackList(updatedList, text);
	};

	const addChip = () => {
		let newCategory = number;
		if (newCategory && !phoneNumbers.includes(newCategory)) {
			setPhoneNumbers((prev) => [...prev, newCategory]);
			setNumber("");
			props.reset();
		} else if (number.length < 1) {
			alert("Введите значение");
		} else {
			alert("такой номер есть");
		}
	};

	const saveText = () => {
		updateBlackList(phoneNumbers, textValue);
		setSnackbarVisible(true);
		setTimeout(() => setSnackbarVisible(false), 3000);
	};

	return (
		<div className={classes.tabWrapper}>
			<div className={classes.desctopWrapper}>
				<Typography className={classes.title} variant="h1">
					Меню
				</Typography>
				<div className={classes.categoriesWrapper}>
					<Typography variant="h6" className={classes.title}>
						Номера
					</Typography>
					<div className={classes.container}>
						<div className={classes.row}>
							<div className={classes.tagContainer}>
								{phoneNumbers?.map((data, index) => (
									<li key={index}>
										<Chip
											label={data}
											onDelete={() => handleDelete(data)}
											className={classes.chip}
										/>
										{isMin600 && <hr className={classes.hr} />}
										<hr className={classes.hr} />
									</li>
								))}
							</div>
							<form
								onSubmit={handleSubmit(addChip)}
								className={classes.formContainer}
								autoComplete="off"
							>
								<Field
									name="id"
									className={classes.input}
									component={CustomizedInputs}
									type="number"
									placeholder="Введіть номер"
									disabled={props.loadStatus ? true : false}
									onChange={(e) => {
										setNumber(e.target.value);
									}}
								/>
								<Button
									text={
										props.loadStatus ? (
											<Loading size={22} color={"#fff"} />
										) : (
											"Додати в список"
										)
									}
									disabled={props.loadStatus ? true : false}
									handleClick={addChip}
									className={classes.addToList}
								/>

								<div>
									<Typography variant="h6" className={classes.subTitle}>
										Текст для черного списка
									</Typography>
									<CustomInputTextarea
										name="editableText"
										className={classes.input}
										type="text"
										placeholder="Введите текст для изменения"
										rows={"3"}
										label=""
										value={textValue}
										onChange={(e) => setTextValue(e.target.value)}
									/>
								</div>

								<div className={classes.bt}>
									<Button
										text={
											props.loadStatus ? (
												<Loading size={22} color={"#fff"} />
											) : (
												"Зберегти"
											)
										}
										disabled={props.loadStatus ? true : false}
										handleClick={handleSubmit(saveText)} // Save text separately
										className={classes.saveButton}
									/>
								</div>
							</form>
							{snackbarVisible && (
								<SnackbarContent
									className={classes.snackbar}
									message="Данні успішно збережені!"
								/>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const useStyles = makeStyles((theme) => ({
	desctopWrapper: {
		padding: "24px 16px 24px 16px",
		background: "white",
		border: " 0.5px solid #DADCE0",
		boxSizing: "border-box",
		borderRadius: "5px 5px 0px 0px",
	},
	categoriesWrapper: {
		marginTop: "16px",
		padding: "24px 24px 16px 16px",
		border: "0.5px solid #DADCE0",
		boxSizing: "border-box",
		borderRadius: "5px",
		[theme.breakpoints.down("600")]: {
			padding: "16px",
		},
	},
	snackbar: {
		marginTop: 16,
		"&.MuiSnackbarContent-root": {
			background: "rgba(50, 109, 255, .05)",
			boxShadow: "none",
			color: "black",
			width: "100%",
			[theme.breakpoints.down("600")]: {
				textAlign: "center",
				marginTop: 15,
			},
		},
		"& > .MuiSnackbarContent-message": {
			padding: "4px 0",
			[theme.breakpoints.down("600")]: {
				width: "100%",
			},
		},
	},
	title: {
		marginBottom: 10,
		fontSize: 22,
		lineHeight: "29px",
		fontWeight: "bold",
		[theme.breakpoints.down("600")]: {
			display: "none",
		},
	},
	subTitle: {
		marginBottom: 10,
		fontSize: 20,
		lineHeight: "29px",
		fontWeight: "bold",
		[theme.breakpoints.down("600")]: {
			display: "none",
		},
	},
	container: {
		position: "relative",
		display: "flex",
		flexWrap: "wrap",
		width: "100%",
	},
	row: {
		display: "flex",
		// margin: 15,
		flexWrap: "wrap",
		width: "100%",
		[theme.breakpoints.down("600")]: {
			margin: 0,
		},
	},
	chip: {
		margin: theme.spacing(0.5),
		backgroundColor: "#326dff",
		padding: "18px 4px",
		color: "#fff",
	},
	tagContainer: {
		width: "100%",
		display: "flex",
		flexWrap: "wrap",
		maxHeight: 200,
		overflowY: "auto",
		listStyle: "none",
		"& > li": {
			position: "relative",
		},
		"& > li > div > svg": {
			fill: "rgba(255, 255, 255, .5)",
		},
		"& > li > hr": {
			top: "13px",
			right: "-20px",
			position: "absolute",
			width: "20px",
			height: "1px",
			transform: "rotate(90deg)",
		},
		"& > li > div": {
			margin: "4px 10px",
		},
		"& > li:last-child > hr": {
			display: "none",
		},
		"&.MuiSvgIcon-root": {
			color: "rgba(255, 255, 255, 0.75)",
		},
		[theme.breakpoints.down("600")]: {
			padding: "15px 10px",
			flexDirection: "column",
			"& > li > hr": {
				display: "none",
			},
			"& > li:last-child": {
				"& > div": {
					marginBottom: 0,
				},
				"& > hr": {
					display: "none",
				},
			},
			"& > li > div": {
				position: "relative",
				width: "100%",
				display: "flex",
				justifyContent: "space-between",
				background: "#E0E9FF",
				borderRadius: "30px",
				color: "#326DFF",
				margin: 0,
				marginBottom: 10,
				"& > svg": {
					fill: "white",
				},
				"& .MuiChip-label": {
					fontSize: 12,
					fontWeight: 500,
					paddingLeft: "15px",
				},
				"&:hover": {
					background: "#326DFF",
					color: "white",
				},
			},
		},
	},
	formContainer: {
		width: "100%",
		// display: "flex",
		flexWrap: "wrap",
	},
	bt: {
		paddingLeft: 10,
		[theme.breakpoints.down("600")]: {
			paddingLeft: 0,
			marginTop: 80,
			width: "100%",
			textAlign: "center",
		},
	},
	addToList: {
		marginTop: 16,
		marginLeft: 16,
		textTransform: "initial",
		[theme.breakpoints.down("600")]: {
			margin: "0 auto 15px",
			marginTop: 16,
		},
	},
	saveButton: {
		position: "absolute",
		top: -48,
		right: 0,
		textTransform: "initial",
		background: "#E9F1FC",
		color: "#326DFF",
		minWidth: 125,
		height: 36,
		margin: 0,
		[theme.breakpoints.down("600")]: {
			position: "static",
		},
		"&:hover": {
			color: "white",
			background: "#326DFF",
		},
	},
	hr: {
		border: "1px dashed rgba(71, 98, 130, .25)",
		boxSizing: "border-box",
		marginLeft: "10px",
		marginRight: "10px",
	},
	input: {
		// [theme.breakpoints.down("600")]: {
		marginBottom: "20px",
		width: "328px",
		[theme.breakpoints.down("700")]: {
			width: "290px",
		},
		[theme.breakpoints.down("600")]: {
			width: "100%",
		},
		"& > .MuiInput-underline.Mui-error:after": {
			content: "none",
		},
		"& .MuiInput-underline:before": {
			content: "none",
		},
		"& .MuiInput-underline:after": {
			content: "none",
		},
		"& > div.MuiInput-root": {
			minHeight: 45,
			border: "1px solid #D6D6D6",
			padding: "10px 52px 10px 16px",
			borderRadius: 5,
			"& > input": {
				padding: 0,
				fontSize: 16,
				lineHeight: "24px",
				color: "#476282",
				"&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
					"-webkit-appearance": "none",
					margin: 0,
				},
				"&[type=number]": {
					"-moz-appearance": "textfield",
				},
			},
		},
		"& > label.MuiInputLabel-root": {
			padding: "5px 52px 10px 16px",
		},
		"& > label.MuiInputLabel-shrink": {
			transform: "translate(0, -3px) scale(0.75)",
		},
		"& > div.Mui-error": {
			border: "1px solid red",
		},
	},
	// }
}));

const afterSubmit = (result, dispatch) => dispatch(reset("CategoriesForm"));

CategoriesForm = reduxForm({
	form: "CategoriesForm",
	onSubmitSuccess: afterSubmit,
})(CategoriesForm);

export default CategoriesForm;
