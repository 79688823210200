import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import FormGroup from "@material-ui/core/FormGroup";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { withStyles, makeStyles } from "@material-ui/core/styles";

const CustomSwitch = withStyles({
	switchBase: {
		color: "#326dff",
		"&$checked": {
			color: "#326dff",
		},
		"&$checked + $track": {
			backgroundColor: "#326dff",
		},
	},
	checked: {},
	track: {},
})(Switch);

const Sales = ({ sales, updateSaleStatus }) => {
	const classes = useStyles();

	const handleSwitchChange = (index, isActive) => {
		updateSaleStatus(index, !isActive);
	};

	return (
		<Grid container className={classes.container}>
			{sales.map((sale, index) => (
				<Grid item xs={12} key={index} className={classes.item}>
					<Typography className={classes.name}>{sale.name}</Typography>
					<FormGroup>
						<FormControlLabel
							control={
								<CustomSwitch
									checked={sale.isActive}
									onChange={() => handleSwitchChange(index, sale.isActive)}
								/>
							}
							className={classes.switchLabel}
						/>
					</FormGroup>
				</Grid>
			))}
		</Grid>
	);
};

const useStyles = makeStyles((theme) => ({
	container: {
		padding: theme.spacing(2),
	},
	item: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		marginBottom: theme.spacing(2),
		padding: theme.spacing(1),
		border: `1px solid ${theme.palette.divider}`,
		borderRadius: theme.shape.borderRadius,
	},
	name: {
		fontSize: 16,
		fontWeight: 500,
	},
	switchLabel: {
		marginLeft: theme.spacing(1),
	},
}));

export default Sales;
