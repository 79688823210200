import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import Switch from '../../../components/Switch';
import ModalComponent from '../../../components/ModalComponent';

const ItemComponent = ({
    id,
    count,
    isAvailable,
    discountId,
    indexKey,
    changeChecker,
    deletePromocode,
    setEditPromocode,
    setOpenModal,
    ...props
}) => {
    const classes = useStyles();

    const [open, setOpen] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);

    const handleClose = () => {
        setOpen(false);
        setIsSubmit(false);
    };

    const handleClickSubmit = () => {
        setOpen(false);
        setIsSubmit(true);
    };

    useEffect(() => {
        if (isSubmit && !open) {
            deletePromocode(indexKey);
        }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSubmit, open]);

    const editPromocode = (id) => {
        setEditPromocode(id);
        setOpenModal(true);
    }

    // const name = Object.keys(props.promocodes)[count];
    
    return (
        <div className={classes.order} key={indexKey}>
            <ModalComponent
                title="Подтвердить действие!"
                description={`Вы подтверждаете удаление ${id}?`} 
                open={open} 
                handleClose={handleClose} 
                handleClickSubmit={handleClickSubmit} />
            <div className={classes.orderContent}>
                <Typography className={classes.title}>{id}</Typography>
                <div className={classes.orderChange}>
                    <EditIcon className={classes.editIcon} onClick={() => editPromocode(indexKey)} />
                    <DeleteOutlineIcon className={classes.trashIcon} onClick={() => setOpen(true)} />
                    <Switch 
                        id={indexKey}
                        className={classes.switchComponent} 
                        active={isAvailable}
                        changeChecker={changeChecker} />
                </div>
            </div>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    title: {
        fontWeight: '500',
        fontSize: 16,
        lineHeight: '19px',
    },
    order: {
        marginTop: 20,
        display: 'flex',
        height: 57,
        width: '100%',
        padding: '0 16px',
        marginBottom: 16,
        boxSizing: 'border-box',

        boxShadow: '0px 14px 23px rgba(232, 228, 228, 0.5)',
        background: 'white',
        borderRadius: 5,
    },
    orderContent: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    orderChange: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    editIcon: {
        width: 22,
        height: 22,
        marginRight: 15,
        color: '#476282',
        '&:hover': {
            color: '#326dff',
            cursor: 'pointer',
        }
    },
    trashIcon: {
        width: 22,
        height: 22,
        color: '#476282',
        '&:hover': {
            color: '#326dff',
            cursor: 'pointer',
        }
    }
}))
export default ItemComponent;