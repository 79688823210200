import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { withFirebase } from "../../../../firebase";
import { useSelector, useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import { addCategory, updateCategoriesOrder } from "../../../../reducers/shops";

import AddCategoryForm from "./form";
import useStyles from "./styles";

let Categories = (props) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const categories = useSelector((state) => state.shops.categories);
	const cities = useSelector((state) => state.shops.shops);
	const language = useSelector((state) => state.shops.defaultLanguage);
	const languages = useSelector((state) => state.shops.languages);

	const [isSelectOpen, setIsSelectOpen] = useState(null);
	const [preloder, setPreloader] = useState(false);
	const [selectedCities, setSelectedCities] = useState([]);
	const [selectedTerminals, setSelectedTerminals] = useState([]);
	const brand = JSON.parse(localStorage.getItem("currentBrand"));
	const isDebug = JSON.parse(localStorage.getItem("isDebug"));

	console.log("cities *** ", cities);

	useEffect(() => {
		if (cities.length === 1) setSelectedCities([cities[0].address]);
	}, [cities]);

	// useEffect(() => {
	//     if (cities.length === 1 && selectedCities.length > 0) {
	//         let terminals = getTerminals();

	//         setSelectedTerminals(terminals);
	//     }
	// }, [selectedCities, cities]);

	useEffect(() => {
		// if (cities.length === 1 && selectedCities.length > 0) {
		let terminals = getTerminals();

		setSelectedTerminals(terminals);
		// }
	}, []);

	const changeSelectClose = () => setIsSelectOpen(false);
	const changeSelectOpen = () => setIsSelectOpen(true);

	const handleChange = (event) => {
		const data = [...selectedTerminals];
		const index = data.findIndex((t) => t.id === event.target.name);
		data[index] = {
			...data[index],
			visible: data[index].visible === "1" ? "0" : "1",
		};

		setSelectedTerminals(data);
	};

	const getTerminals = () => {
		let terminals = [];

		const deliveryTerminals = brand.deliveryTerminals;

		deliveryTerminals.map((elem, index) => {
			deliveryTerminals[index] = { ...elem, visible: "1" };
		});

		terminals.push(...deliveryTerminals);

		return terminals;
	};

	const changeCity = (data) => {
		let terminals = [];

		data.map((address) => {
			let { spot_id, deliveryTerminals } = cities.find(
				(city) => city.address === address
			);

			deliveryTerminals.map((elem, index) => {
				deliveryTerminals[index] = { ...elem, visible: "1" };

				selectedTerminals.map((terminal) => {
					if (elem.id === terminal.id) {
						let isVisible = false;

						for (let key in terminal) {
							if (key == "visible") {
								isVisible = true;
							}
						}

						deliveryTerminals[index] = {
							...terminal,
							visible: terminal.visible,
						};
					}
				});
			});

			terminals.push(...deliveryTerminals);
		});

		setSelectedTerminals(terminals);
		setSelectedCities(data);
	};

	const createNewCategory = (data) => {
		let name = {};
		// let allTerminals = [];
		const categoryId = uuidv4();

		for (let key in data) {
			name[key] = data[key];
		}

		const spots = selectedTerminals.map((selectTerminal) => ({
			spotId: selectTerminal.id,
			visible: selectTerminal.visible,
		}));

		return {
			categoryId,
			name,
			spots,
		};
	};

	const onSubmit = (data) => {
		const category = createNewCategory(data);

		setPreloader(true);
		props.firebase.bd
			.ref(
				`${isDebug ? "debug" : ""}/EAppCRM/${brand.id}/nomenclature/categories`
			)
			.once("value")
			.then((snapshot) => {
				const categories = snapshot.val() || [];

				categories.push(category);

				props.firebase.bd
					.ref(
						`${isDebug ? "debug" : ""}/EAppCRM/${
							brand.id
						}/nomenclature/categories`
					)
					.set(categories)
					.then(() => {
						const keyCity = Number(
							JSON.parse(localStorage.getItem("chosenCity"))
						);
						const keyBrand = Number(
							JSON.parse(localStorage.getItem("chosenBrand"))
						);

						const categoriesOrderData = categories.map(
							(item) => item.categoryId
						);

						props.firebase.bd
							.ref(
								`${
									isDebug ? "debug" : ""
								}/settings/city/${keyCity}/brands/${keyBrand}/category/categoriesOrder`
							)
							.set(categoriesOrderData)
							.then(() => {
								addCategory(dispatch, category);
								setPreloader(false);
								updateCategoriesOrder(categoriesOrderData);

								props.history.push(`/page/menu/products/${brand.id}`);
							})
							.catch((error) => {
								console.error("Error adding сategories order:", error);
							});
					})
					.catch((error) => {
						console.error("Error adding category:", error);
					});
			})
			.catch((error) => {
				console.error("Error retrieving categories:", error);
				setPreloader(false);
			});
	};

	return (
		<div className={classes.root}>
			<div className={classes.modal}>
				<AddCategoryForm
					load={preloder}
					language={language}
					languages={languages}
					cities={cities}
					selectedTerminals={selectedTerminals}
					setSelectedTerminals={setSelectedTerminals}
					isSelectOpen={isSelectOpen}
					onSubmit={onSubmit}
					handleChange={handleChange}
					changeSelectClose={changeSelectClose}
					changeSelectOpen={changeSelectOpen}
					selectedCities={selectedCities}
					changeCity={changeCity}
				/>
			</div>
		</div>
	);
};

export default withFirebase(withRouter(Categories));
