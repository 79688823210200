import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import DialogComponent from './DialogComponent';
import ItemComponent from './ItemComponent';

const Promocodes = (props) => {
    const classes = useStyles();
    const [openModal, setOpenModal] = useState(false);

    const [editPromocode, setEditPromocode] = useState(null);
    const [dataForm, setDataForm] = useState(null);
    const [count, setCount] = useState(null);

    const isPromocodes = props.promocodes &&  props.promocodes.length !== 0 ? props.promocodes : null;

    const handleClose = () => {
        setOpenModal(false);
    };

    const createPromocode = () => {
        setOpenModal(true);
        setEditPromocode(null);
    }

    useEffect(() => {
        // eslint-disable-next-line no-mixed-operators
        if (props && props.promocodes) {
            if (!dataForm || Object.keys(props.promocodes).length !== dataForm.length) {
                setDataForm(props.promocodes);
            }
        }
    }, [dataForm, props.promocodes]);

    useEffect(() => {
        if (!count) {
            if (editPromocode) {
                setCount(dataForm[editPromocode].type);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [count, editPromocode]);

    const items = [];
    const keys = [];

    for (let key in dataForm) {
        const item = dataForm[key];

        items.push(item);
        keys.push(key);
    }


    return (
        <div className={classes.promocodes}>
            <div className={classes.promocodesHeaderWrap}>
                <Typography className={classes.typography} variant="h5">Промокоды</Typography>
                <Button 
                    className={classes.button} 
                    variant="contained" 
                    color="primary"
                    onClick={() => createPromocode()}>Создать</Button>
            </div>

            <DialogComponent
                open={openModal}
                count={count}
                dataForm={dataForm}
                setCount={setCount} 
                editPromocode={editPromocode}
                setDataForm={setDataForm}
                handleClose={handleClose}
                onSubmit={props.onSubmit}
                createPromocodeSubmit={props.createPromocode}
            />

            {!isPromocodes && (
                <div className={classes.promocodesContainer}>Пока нет промокодов</div>
            )}

            {isPromocodes && items.map((item, index) => 
                {
                    return <div key={index}>
                            <ItemComponent 
                                count={index}
                                indexKey={keys[index]}
                                classes={classes}
                                setEditPromocode={setEditPromocode}
                                setOpenModal={setOpenModal}
                                {...item} 
                                {...props} /> 
                        </div>
                }
            )}

        </div>
    )
}

const useStyles = makeStyles(theme => ({
    promocodes: {
        minHeight: 148,
        marginTop: 30,
        padding: 16,
        border: '1px solid #DADCE0',
        boxSizing: 'border-box',
        borderRadius: 5,
        [theme.breakpoints.down('600')]: {
            backgroundColor: 'white',
        },
    },
    promocodesHeaderWrap: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    button: {
        width: 109,
        height: 36,
        backgroundColor: '#E9F1FC',
        color: '#326DFF',
        boxShadow: 'none',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: '#326DFF',
            color: 'white',
            boxShadow: 'none',
        }

    },
    promocodesContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '80px',
        
        fontWeight: 500,
        fontSize: 16,
        lineHeight: 19,
        color: '#476282',
    },
    typography: {
        fontWeight: 500,
        fontSize: 18,
        lineHeight: '21px',
    },
}))

export default Promocodes;