import React from "react";
import { read, utils } from "xlsx";
import { makeStyles } from "@material-ui/core/styles";
import { Field } from "redux-form";
import CustomizedInputsPhone from "../../components/UIform/inputPhone";
import CustomizedInputsSelect from "../../components/UIform/inputSelect";
import { required } from "redux-form-validators";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import Popover from "@material-ui/core/Popover";

const InfoIcon = ({
	classes,
	className,
	onClick,
	ariaOwns,
	ariaHaspopup,
	onMouseEnter,
	onMouseLeave,
}) => (
	<div
		aria-owns={ariaOwns}
		aria-haspopup={ariaHaspopup}
		onMouseEnter={onMouseEnter}
		onMouseLeave={onMouseLeave}
	>
		<svg
			onClick={onClick}
			className={className}
			width="18"
			height="19"
			viewBox="0 0 18 19"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M16.5 9.91218C16.5 14.0926 13.1475 17.4854 9.0075 17.4854C4.86 17.4854 1.5 14.0926 1.5 9.91218C1.5 5.73179 4.86 2.33901 9.0075 2.33901C13.1475 2.33901 16.5 5.73179 16.5 9.91218ZM8.25 12.9414C8.25 13.358 8.5875 13.6988 9 13.6988C9.4125 13.6988 9.75 13.358 9.75 12.9414V9.91218C9.75 9.49566 9.4125 9.15486 9 9.15486C8.5875 9.15486 8.25 9.49566 8.25 9.91218L8.25 12.9414ZM9 3.85364C12.315 3.85364 15 6.56484 15 9.91218C15 13.2595 12.315 15.9707 9 15.9707C5.685 15.9707 3 13.2595 3 9.91218C3 6.56484 5.685 3.85364 9 3.85364ZM9 6.1256C8.58579 6.1256 8.25 6.4687 8.25 6.88291C8.25 7.29713 8.58579 7.64023 9 7.64023C9.41421 7.64023 9.75 7.29713 9.75 6.88291C9.75 6.4687 9.41421 6.1256 9 6.1256Z"
				fill="#476282"
			/>
		</svg>
	</div>
);

const IconComponent = ({ classes, isPhoneInfo, isFileInfo }) => {
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handlePopoverOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handlePopoverClose = () => {
		setAnchorEl(null);
	};
	const open = Boolean(anchorEl);

	return (
		<div>
			<InfoIcon
				className={classes.info}
				ariaOwns={open ? "mouse-over-popover" : undefined}
				ariaHaspopup="true"
				onMouseEnter={handlePopoverOpen}
				onMouseLeave={handlePopoverClose}
			/>
			<Popover
				id="mouse-over-popover"
				className={classes.popover}
				classes={{
					paper: classes.paper,
				}}
				open={open}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
				onClose={handlePopoverClose}
				disableRestoreFocus
			>
				{isPhoneInfo && (
					<>
						<Typography style={{ fontSize: 18, marginBottom: 3 }}>
							Номер в формате:
						</Typography>
						<Typography>Украина: 380501234567</Typography>
						<Typography>Россия: 79201234567</Typography>
					</>
				)}
				{isFileInfo && (
					<>
						<Typography>Поддерживаемые форматы файлов: .xls, .xlsx</Typography>
					</>
				)}
			</Popover>
		</div>
	);
};

const RadioButtons = ({
	audience,
	cityPush,
	brandPush,
	multiUserPush,
	updatePhonesList,
	...props
}) => {
	const classes = useStyles();
	const isOneCityNumber = props.city.length > 1;

	const brandsList = props.city
		.map((item) => item.brands)
		.reduce((acc, cur) => {
			return acc.concat(cur);
		}, []);

	const handleFileChange = (event) => {
		const file = event.target.files[0];
		if (file) {
			const reader = new FileReader();

			reader.onload = (e) => {
				const data = new Uint8Array(e.target.result);

				try {
					const workbook = read(data, { type: "array" });
					const worksheet = workbook.Sheets[workbook.SheetNames[0]];
					const jsonData = utils.sheet_to_json(worksheet, { header: 1 });

					const phoneNumbers = jsonData.flat();

					const trimmedArray =
						phoneNumbers.length > 100
							? phoneNumbers.slice(0, 100)
							: phoneNumbers;

					updatePhonesList(trimmedArray);

					console.log("phoneNumbers in file, ", trimmedArray);
				} catch {
					alert("При обработке файла произошла ошибка!!");
				}
			};
			reader.readAsArrayBuffer(file);
		}
	};

	return (
		<div>
			<div className={classes.wrapper}>
				<FormControl className={classes.controlLabel} component="div">
					<div className={classes.inputCheckbox}>
						<Field
							id="all"
							style={{ color: audience === "all" ? "#326DFF" : "#476282" }}
							className={classes.checkbox}
							name="audience"
							component="input"
							type="radio"
							value="all"
							checked={audience === "all"}
						/>
						<label
							htmlFor="all"
							style={{ color: audience === "all" ? "#326DFF" : "#476282" }}
							className={classes.textCheckbox}
						>
							Отправить всем
						</label>
					</div>
				</FormControl>
				<FormControl className={classes.controlLabel} component="div">
					<div className={classes.inputCheckbox}>
						<Field
							id="favorite"
							className={classes.checkbox}
							name="audience"
							component="input"
							type="radio"
							value="favorite"
						/>
						<label
							htmlFor="favorite"
							style={{ color: audience === "favorite" ? "#326DFF" : "#476282" }}
							className={classes.textCheckbox}
						>
							Избранное
						</label>

						<IconComponent classes={classes} isPhoneInfo={true} />
					</div>
					{audience === "favorite" && (
						<Field
							name="phone"
							component={CustomizedInputsPhone}
							label=""
							type="number"
							className={classes.phoneNumber}
							placeholder="380500612222"
							validate={required({
								msg: "Это поле обязательно для заполнения",
							})}
						/>
					)}
				</FormControl>
				{multiUserPush && (
					<FormControl className={classes.controlLabel} component="div">
						<div className={classes.inputCheckbox}>
							<Field
								id="multiUser"
								className={classes.checkbox}
								name="audience"
								component="input"
								type="radio"
								value="multiUser"
								disabled={!multiUserPush ? true : false}
							/>
							<label
								htmlFor="multiUser"
								style={{
									color: audience === "multiUser" ? "#326DFF" : "#476282",
								}}
								className={classes.textCheckbox}
							>
								Группа пользователей (в файле может быть до 100 номеров)
							</label>

							<IconComponent classes={classes} isFileInfo={true} />
						</div>

						{audience === "multiUser" && (
							<FormControl>
								<input
									className={classes.file}
									name="file"
									component="input"
									type="file"
									onChange={handleFileChange}
								/>
							</FormControl>
						)}
					</FormControl>
				)}

				{isOneCityNumber && cityPush && (
					<FormControl className={classes.controlLabel} component="div">
						<div className={classes.inputCheckbox}>
							<Field
								id="city"
								className={classes.checkbox}
								name="audience"
								component="input"
								type="radio"
								value="city"
								disabled={!cityPush ? true : false}
							/>
							<label
								htmlFor="city"
								style={{ color: audience === "city" ? "#326DFF" : "#476282" }}
								className={classes.textCheckbox}
							>
								Город
							</label>
						</div>

						{audience === "city" && (
							<FormControl className={classes.select}>
								<Field
									className={classes.selectCity}
									name="cityId"
									component={CustomizedInputsSelect}
									validate={required({ msg: "Пожалуйста выберите город" })}
								>
									<option value="">Выберите город</option>
									{props.city.map(({ id, address }, key) => (
										<option key={key} value={id}>
											{address}
										</option>
									))}
								</Field>
							</FormControl>
						)}
					</FormControl>
				)}
				{brandsList.length > 0 && brandPush && (
					<FormControl className={classes.controlLabel} component="div">
						<div className={classes.inputCheckbox}>
							<Field
								id="brand"
								className={classes.checkbox}
								name="audience"
								component="input"
								type="radio"
								value="brand"
								disabled={!brandPush ? true : false}
							/>
							<label
								htmlFor="brand"
								style={{ color: audience === "brand" ? "#326DFF" : "#476282" }}
								className={classes.textCheckbox}
							>
								Заведение
							</label>
						</div>

						{audience === "brand" && (
							<FormControl className={classes.select}>
								<Field
									className={classes.selectBrand}
									name="brandId"
									component={CustomizedInputsSelect}
									validate={required({ msg: "Пожалуйста выберите заведение" })}
								>
									<option value="">Выберите заведение</option>
									{brandsList.map(({ id, name }, key) => (
										<option key={key} value={id}>
											{name}
										</option>
									))}
								</Field>
							</FormControl>
						)}
					</FormControl>
				)}
			</div>
		</div>
	);
};

const useStyles = makeStyles((theme) => ({
	phoneNumber: {
		display: "block",
		marginTop: 14,
		"& .MuiInput-formControl": {
			width: 328,
			height: 45,
			padding: "13px 14px",
			boxSizing: "border-box",
			borderRadius: 5,
			border: "1px solid #326DFF",
			[theme.breakpoints.down("376")]: {
				width: "280px",
			},
		},
		"& div.Mui-error": {
			border: "1px solid red",
		},
		"& .MuiInput-underline:after": {
			content: "none",
		},
		"& .MuiInput-underline.Mui-error:after": {
			content: "none",
		},
		"& .MuiInput-underline:hover:not(.Mui-disabled):before": {
			content: "none",
		},
		"& .MuiInput-underline:before": {
			content: "none",
		},
		"& .MuiSelect-icon": {
			right: 8,
		},
	},
	select: {
		marginBottom: 12,
		width: 328,
		borderRadius: 5,
		border: "none",
		[theme.breakpoints.down("376")]: {
			width: "280px",
		},
		"& .MuiInput-formControl": {
			border: "1px solid #326DFF",
			borderRadius: 5,
		},
		"&:hover": {
			border: "none",
		},
		"& .MuiSelect-select.MuiSelect-select": {
			height: 45,
			boxSizing: "border-box",
			padding: 12,
		},
		"& .MuiOutlinedInput-input": {
			height: 45,
			padding: 12,
			boxSizing: "border-box",
		},
		"& .MuiInputBase-root::before": {
			content: "none",
		},
		"& .MuiInputBase-root::after": {
			content: "none",
		},
	},
	selectCity: {
		marginTop: 14,
		width: 165,
	},
	selectBrand: {
		marginTop: 14,
		width: 200,
	},
	wrapper: {
		display: "flex",
		flexDirection: "column",
	},
	controlLabel: {
		marginBottom: 16,
		"& > div": {
			[theme.breakpoints.down("376")]: {
				width: "280px",
			},
		},
	},
	inputCheckbox: {
		display: "flex",
	},
	info: {
		marginLeft: 8,
		cursor: "pointer",
	},
	checkbox: {
		width: 18,
		height: 18,
		color: "#476282",
		margin: 0,
		padding: 0,
		cursor: "pointer",
	},
	textCheckbox: {
		marginLeft: 8,
		fontFamily: "Roboto",
		fontSize: 15,
		lineheight: "18px",
		color: "#476282",
		cursor: "pointer",
	},
	disabledMessage: {
		marginLeft: 8,
		fontSize: 12,
		color: "red",
	},
	popover: {
		pointerEvents: "none",
	},
	paper: {
		padding: theme.spacing(1),
	},
	file: {
		marginTop: 10,
	},
}));

export default RadioButtons;
