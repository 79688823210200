/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Field } from "redux-form";
import moment from "moment";

import TextField from "@material-ui/core/TextField";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import Button from "@material-ui/core/Button";

const Calendar = ({ data, setData, updateDelay }) => {
	const classes = useStyles();
	const [open, setOpen] = useState(false);
	const [valid, setValid] = useState(false);
	const [inputValue, setInputValue] = useState("");

	const mounts = [
		"Января",
		"Февраля",
		"Марта",
		"Апреля",
		"Майя",
		"Июня",
		"Июля",
		"Августа",
		"Сентября",
		"Октября",
		"Ноября",
		"Декабря",
	];

	let defaultTime = moment().add(2, "minute").format("YYYY-MM-DDTHH:mm");
	const timeFirebaseFormat = (time) => moment(time).format("DD/MM/YYYY/HH:mm");
	const timeCalendarFormat = (time) => moment(time).format("YYYY-MM-DDTHH:mm");

	useEffect(() => {
		if (data && !inputValue) {
			if (!data.begin || !data.end) {
				setInputValue(defaultTime);
				setData({
					...data,
					begin: timeFirebaseFormat(defaultTime),
					end: timeFirebaseFormat(defaultTime),
				});
			} else {
				let begin = moment(data.begin, "DD/MM/YYYY/HH:mm").format(
					"YYYY-MM-DDTHH:mm"
				);
				setInputValue(timeCalendarFormat(begin));
			}
		}
	}, [data, inputValue, setData, defaultTime]);

	const handleChange = (e) => {
		const { value } = e.target;
		setInputValue(value);
		updateDelay(value);
	};

	const handleClick = () => {
		setData({
			...data,
			begin: timeFirebaseFormat(inputValue),
			end: timeFirebaseFormat(inputValue),
		});
		setOpen(false);
	};

	let formatStr = `${moment(inputValue).get("date")} ${
		mounts[moment(inputValue).get("month")]
	} ${moment(inputValue).get("year")} ${moment(inputValue).get(
		"hours"
	)}:${String(moment(inputValue).get("minutes")).padStart(2, "0")}`;

	let defaultTimestamp = moment(defaultTime).unix();
	let inputTimestamp = moment(inputValue).unix();

	useEffect(() => {
		if (inputTimestamp >= defaultTimestamp) {
			setValid(true);
		} else {
			setValid(false);
		}
	}, [inputTimestamp, defaultTimestamp]);

	return (
		<div className={classes.calendarField}>
			<div className={classes.calendarTitle}>Выбрать дату и время:</div>
			<div
				className={classes.calendarContent}
				style={{
					borderBottomLeftRadius: !open ? "5px" : 0,
					borderBottomRightRadius: !open ? "5px" : 0,
					borderBottom: !open ? "1px solid #ccc" : "1px solid transparent",
				}}
			>
				<div
					className={classes.calendarContentHeader}
					onClick={() => setOpen(!open)}
				>
					{inputValue ? formatStr : "дд.мм.гггг, --:--"}
					<CalendarTodayIcon className={classes.calendarIcon} />
				</div>
				{open && (
					<div className={classes.calendarContentItems}>
						<TextField
							id="singleInput"
							name="singleInput"
							label=""
							type="datetime-local"
							defaultValue={inputValue}
							className={classes.InputFirst}
							InputLabelProps={{
								shrink: true,
							}}
							onChange={handleChange}
						/>
						<Button
							className={classes.button}
							variant="contained"
							color="primary"
							disabled={!valid}
							onClick={valid ? handleClick : null}
						>
							Применить
						</Button>
					</div>
				)}
			</div>
		</div>
	);
};

const useStyles = makeStyles((theme) => ({
	calendarField: {
		display: "flex",
		alignItems: "center",
		[theme.breakpoints.down("600")]: {
			flexWrap: "wrap",
		},
	},
	calendarTitle: {
		marginRight: 15,
		fontSize: 16,
		lineHeight: "19px",
		color: "black",
	},
	calendarContent: {
		position: "relative",
		fontSize: 13,
		color: "black",
		fontWeight: 500,
		lineHeight: 1,
		border: "1px solid #ccc",
		borderRadius: 5,
		padding: "6px 10px 12px 10px",
		paddingRight: 18,
		background: "white",
		[theme.breakpoints.down("940")]: {
			maxWidth: 220,
			width: "100%",
		},
		[theme.breakpoints.down("441")]: {
			marginTop: 10,
		},
	},
	calendarIcon: {
		width: 18,
		height: 18,
		position: "relative",
		top: 3,
		left: 10,
		color: "#476282",
	},
	calendarContentHeader: {
		cursor: "pointer",
	},
	calendarContentItems: {
		width: 555,
		position: "absolute",
		top: 0,
		left: "-1px",
		marginTop: 38,
		border: "1px solid #ccc",
		borderRadius: 5,
		padding: "10px 10px 12px 10px",
		paddingRight: 18,
		lineHeight: 1,
		background: "white",
		borderTopLeftRadius: 0,
		zIndex: 999999,
		[theme.breakpoints.down("940")]: {
			display: "flex",
			flexDirection: "column",
			width: "250px",
			borderRadius: 0,
			padding: "10px 0",
			boxSizing: "border-box",
			"& #singleInput": {
				margin: "0 auto",
				marginBottom: 10,
			},
			"& button": {
				margin: "0 auto",
			},
		},
		[theme.breakpoints.down("460")]: {
			padding: "16px 8px",
		},
	},
	InputFirst: {
		"& > .MuiInput-underline .MuiInputBase-input": {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			textAlign: "center",
			height: 35,
			marginRight: "10px",
			width: 210,
			padding: "16px",
			fontSize: 13,
			fontWeight: 500,
			background: "white",
			border: "0.5px solid rgba(71, 98, 130, 0.5)",
			borderRadius: 5,
			boxSizing: "border-box",
		},
		"& > .MuiInput-underline::before": {
			content: "none",
		},
		"& > .MuiInput-underline::after": {
			content: "none",
		},
	},
	button: {
		width: 84,
		height: 35,
		fontSize: 12,
		fontWeight: 500,
		lineHeight: "14px",
		textTransform: "capitalize",
		color: "white",
		background: "#326DFF",
	},
}));

export default Calendar;
