import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
// import RenderFirebase from './RenderFirebase'
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import store from './reducers';

ReactDOM.render(
    <BrowserRouter>
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <App/>
            </ThemeProvider>
        </Provider>
    </BrowserRouter>
, document.getElementById('root'));


serviceWorker.unregister();
