import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import { withStyles } from '@material-ui/core/styles'

export const InfoIcon = ({ ariaOwns, ariaHaspopup, onMouseEnter, onMouseLeave, className }) => (
    <div aria-owns={ariaOwns} aria-haspopup={ariaHaspopup} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} className={className}>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M15.5 8C15.5 12.14 12.1475 15.5 8.0075 15.5C3.86 15.5 0.5 12.14 0.5 8C0.5 3.86 3.86 0.5 8.0075 0.5C12.1475 0.5 15.5 3.86 15.5 8ZM7.25 11C7.25 11.4125 7.5875 11.75 8 11.75C8.4125 11.75 8.75 11.4125 8.75 11V8C8.75 7.5875 8.4125 7.25 8 7.25C7.5875 7.25 7.25 7.5875 7.25 8L7.25 11ZM8 2C11.315 2 14 4.685 14 8C14 11.315 11.315 14 8 14C4.685 14 2 11.315 2 8C2 4.685 4.685 2 8 2ZM8 4.25C7.58579 4.25 7.25 4.58579 7.25 5C7.25 5.41421 7.58579 5.75 8 5.75C8.41421 5.75 8.75 5.41421 8.75 5C8.75 4.58579 8.41421 4.25 8 4.25Z" fill="#476282"/>
        </svg>
    </div>
);

export const HeadTableCell = withStyles((theme) => ({
    head: {
        minWidth: 156,
        height: 48,

        fontSize: 14,
        fontWeight: 'normal',
        lineHeight: '16px',
        letterSpacing: '0.003em',
        color: '#000000',
        textAlign: 'center',

        borderRight: '1px solid #F1F3F4',
        borderLeft: '1px solid #F1F3F4',

            // '&:nth-child(2n)': {
            //     background: '#F1F3F4',
            // },

        borderBottom: '1px solid #DADCE0',
        '&:first-child': {
            borderLeft: '1px solid transparent',
        },
        '&:last-child': {
            borderRight: '1px solid transparent',
        }
    },
}))(TableCell);

export const BodyTableCell = withStyles((theme) => ({
    body: {
        minWidth: 156,
        height: 120,
        padding: '16px 8px',
        paddingTop: 60,

        fontSize: 14,
        lineHeight: '16px',
        letterSpacing: '0.003em',
        color: '#000000',
        textAlign: 'center',

        // '&:nth-child(2n)': {
        //     background: '#F1F3F4',
        // },

        borderRight: '1px solid #F1F3F4',
        borderLeft: '1px solid #F1F3F4',

        '&:first-child': {
            borderLeft: '1px solid transparent',
        },
        '&:last-child': {
            borderRight: '1px solid transparent',
        }
    },
}))(TableCell);

export const FooterTableCell = withStyles((theme) => ({
    body: {
        minWidth: 156,
        height: 70,
        padding: '16px 8px',

        fontSize: 14,
        lineHeight: '16px',
        letterSpacing: '0.003em',
        color: '#000000',
        textAlign: 'center',

        // '&:nth-child(2n)': {
        //     background: '#F1F3F4',
        // },

        borderRight: '1px solid #F1F3F4',
        borderLeft: '1px solid #F1F3F4',

        '&:first-child': {
            // borderLeft: '1px solid transparent',
            // minWidth: 180,
        },
        '&:last-child': {
            borderRight: '1px solid transparent',
        }
    },
}))(TableCell);