import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form'
import Textarea from '../../../../../components/UIform/inputTextarea';

import ButtonMaterial from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';

import Button from '../../../../../components/Button';
import Loading from '../../../../../components/Loading';

import useStyles from './style';

const maxLength = (text) => text && text.length > 300 ? 'Превышено максимальное колличество символов - 300' : null;

const ModalForm = (props) => {
    const { handleSubmit, pristine, reset, submitting, table } = props
  const classes = useStyles();
  const [message, setMessage] = useState('');
  const [loadStatus, setLoadStatus] = useState(false);
  const checkedItems = table.filter(t => t.checked);

  const handleClose = () => {
    props.close();
  };

  const handleTextAreaChange = (e) => {
      const value = e.target.value;

      if (value.length <= 300) {
          setMessage(e.target.value);
      }
  }

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={props.open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <div className={classes.header}>
            <DialogTitle id="max-width-dialog-title">Отправить Push уведомление</DialogTitle>
            <CloseIcon onClick={handleClose} className={classes.closeIcon} />
        </div>
        <DialogContent>
          <DialogContentText>
            Выбрано клиентов <span className={classes.count}>{checkedItems.length}</span>
          </DialogContentText>
            <form className={classes.form} onSubmit={handleSubmit}>
                <div>
                    <div className={classes.textareaWrap}>
                        <div className={classes.textareaLabel}>Текст Push-уведомления</div>
                        <div className={classes.textareaCount}>{message.length}/300</div>
                    </div>
                    <Field
                        className={classes.textarea} 
                        name={'text'}
                        variant="outlined"
                        component={Textarea}
                        onChange={handleTextAreaChange}
                        placeholder={'Напишите текст push-уведомления'}
                        validate={[maxLength]} 
                    />
                </div>

                <div className={classes.buttonWrap}>
                    <ButtonMaterial 
                        onClick={handleClose} 
                        disabled={pristine || submitting}
                        className={classes.closeButton}
                    >
                        Отмена
                    </ButtonMaterial>
                    <Button text={loadStatus ? 
                        <Loading size={22} color={'#fff'}/> : 'Отправить'} 
                            disabled={pristine || submitting}
                            handleClick={handleSubmit} />
                </div>
            </form>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default reduxForm({
    form: 'ModalForm'
})(ModalForm);