import React from 'react';
import { makeStyles } from '@material-ui/core';
import Loading from '../../../../components/Loading';
import TableHeaders from './TableHeaders';
import TableList from './TableList';

const Table = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            {props.preloader ? <Loading /> : (
                <>
                    <TableHeaders rows={props.rows} />
                    <TableList />
                </>
            )}
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: 30,
        // width: '100%',
        // height: 350,
        // overflowY: 'auto',
    }
}));

export default Table;