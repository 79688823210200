// import app from "firebase/app";
import firebase from "firebase/app";
// import { initializeApp } from "firebase/compat/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";

class Firebase {
	constructor(props) {
		console.log("props", props);
		let config = props.config;
		//let data = require(`./${login}.json`);
		firebase.initializeApp(config);
		this.auth = firebase.auth();
		this.bd = firebase.database();
		this.storage = firebase.storage();
		this.deleteSessions = () => {
			firebase.app().delete();
		};

		//
		//
		// const app = initializeApp(config);
		// this.auth = app.auth();
		// this.bd = app.database();
		// this.storage = app.storage();
		// this.deleteSessions = () => {
		// 	app.app().delete();
		// };
	}

	doSignInWithEmailAndPassword = (email, password) =>
		this.auth.signInWithEmailAndPassword(email, password);

	doSignOut = () => {
		this.auth.signOut();
		this.deleteSessions();
	};
}

export default Firebase;
