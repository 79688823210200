/* eslint-disable jsx-a11y/alt-text */
import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import disableApp from '../../assets/img/disableApp.png';
import AlertForm from './alertForm';

import { useSelector } from 'react-redux';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Loading from '../../components/Loading';

const CustomSwitch = withStyles({
    switchBase: {
      color: '#326dff',
      '&$checked': {
        color: '#326dff',
      },
      '&$checked + $track': {
        backgroundColor: '#326dff',
      },
    },
    checked: {},
    track: {},
  })(Switch);

const DisableApp = (props) => {
    const classes = useStyles();

    const adminPermission =  JSON.parse(localStorage.getItem('adminPermission'))
    const brand = JSON.parse(localStorage.getItem('currentBrand'))

    // поправить начальное значение, что бы в локал сторадже лежало только акутальное значение

    const [appAvailabe, setAppAvailable] = useState(brand.appSettings ? brand.appSettings.isOrderAvailable  : null)

    const updateAvailable = () => {
        props.updateStatusApp(appAvailabe);
        setAppAvailable(!appAvailabe)
    }
    
    return(
        <Grid container spacing={3}>
            <Grid item xs={12} md={7}>
            <Typography variant="h6" className={classes.title}>
                 Состояние приложения:
            </Typography>
                {adminPermission && adminPermission.newAdmin === true ?
                (<>
                    {brand.appSettings ? <FormGroup>
                    <FormControlLabel
                        control={
                            <CustomSwitch
                            checked={brand.appSettings.isOrderAvailable}
                            onChange={() => updateAvailable(appAvailabe)}
                            value={brand.appSettings.isOrderAvailable}
                            
                            // checked={!appAvailabe}
                            // onChange={() => updateAvailable(appAvailabe)}
                            // value={!appAvailabe}
                            />
                        }
                        label="Включить приложение"
                    />
                 </FormGroup> : <Loading size={22} color={'#326dff'}/>}
                <AlertForm alert={brand.appSettings.closedSpotAlertDescription} onSubmit={props.updateAlert}/>
                </>)
                 : 
                (<>
                    {props.shops ? <FormGroup>
                    <FormControlLabel
                        control={
                            <CustomSwitch
                                checked={props.shops.orderAvailable}
                                onChange={() => props.updateStatusApp()}
                                value={props.shops.orderAvailable}
                            />
                        }
                        label="Включить приложение"
                    />
                 </FormGroup> : <Loading size={22} color={'#326dff'}/>}
                <AlertForm alert={props.alert} onSubmit={props.updateAlert}/>
                </>)
                }
            </Grid>
            <Grid item xs={12} md={5}>
                <div className={classes.imgContainer}>
                    <img className={classes.disableApp} src={disableApp}/>
                </div>
            </Grid>
        </Grid>
    )
} 
const useStyles = makeStyles(theme => ({
    imgContainer: {
        width: '100%',
        height: 'auto',
        display: 'flex',
        justifyContent: 'center'
    },
    disableApp: {
        width: '100%',
        maxWidth: 300,
        height: 'auto',
        margin: 'auto'
    },
    title: {
        marginBottom: 10,
        fontSize: 18
      },
}))

export default DisableApp;