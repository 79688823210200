import React, { useEffect, useState } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { withStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ModalComponent from "../../components/ModalComponent";
import CssBaseline from "@material-ui/core/CssBaseline";
import ButtonMore from "../../components/Button";
import DatePicker from "../../components/DatePicker";

import {
	EyeIcon,
	EyeOffIcon,
} from "../products/categories/listCategories/icons";

// import Tabs from "./Tab";
// import TabApp from "./TabApp";
// import TabProduct from "./TabProduct";
// import TabOrderReviews from "./TabOrderReviews";
import FilterPanel from "./FilterPanel";

const Reviews = (props) => {
	const {
		classes,
		reviews,
		handleHideReview,
		startDate,
		setStartDate,
		endDate,
		setEndDate,
	} = props;

	const isMin600 = useMediaQuery("(max-width: 599px)");
	const isMax600 = useMediaQuery("(min-width: 600px)");

	const [visibleReviews, setVisibleReviews] = useState(8);
	const [sortType, setSortType] = useState("newest");

	const handleShowMore = () => {
		setVisibleReviews((prevVisibleReviews) => prevVisibleReviews + 8);
	};

	const sortReviews = (type) => {
		setSortType(type);
	};

	const sortedReviews = (data, type) => {
		const result = [...data].sort((a, b) => {
			if (type === "newest") {
				return new Date(b.createdAt) - new Date(a.createdAt);
			} else if (type === "lowest") {
				return new Date(a.createdAt) - new Date(b.createdAt);
			} else if (type === "highGrade") {
				return (
					b.impression.grade - a.impression.grade ||
					new Date(b.createdAt) - new Date(a.createdAt)
				);
			} else if (type === "lowGrade") {
				return (
					a.impression.grade - b.impression.grade ||
					new Date(b.createdAt) - new Date(a.createdAt)
				);
			} else {
				return 0;
			}
		});

		return result;
	};

	const reviewsSort = sortedReviews(reviews, sortType);

	return (
		<>
			{isMin600 && (
				<Typography className={classes.titleMobile} variant="h1">
					Отзывы
				</Typography>
			)}
			<Grid
				className={classes.container}
				direction={"row"}
				container
				alignItems={"center"}
			>
				<Grid item xs={12}>
					{isMax600 && (
						<Typography className={classes.title} variant="h1">
							Отзывы
						</Typography>
					)}

					<FilterPanel
						sortType={sortType}
						sortReviews={sortReviews}
						{...props}
					/>

					<DatePicker
						startDate={startDate}
						setStartDate={setStartDate}
						endDate={endDate}
						setEndDate={setEndDate}
					/>

					<Grid direction={"row"} container alignItems={"center"}>
						<CssBaseline />

						<Grid spacing={3} container className={classes.reviewContaoner}>
							{reviewsSort.slice(0, visibleReviews).map((item, index) => {
								return (
									<Grid
										item
										xs={12}
										sm={6}
										md={4}
										lg={3}
										key={index}
										id="back-to-top-anchor"
									>
										<div className={classes.row}>
											<div
												className={classes.eyesWrapper}
												onClick={() => handleHideReview(item.id, !item.hidden)}
											>
												{item.hidden ? (
													<EyeOffIcon
														style={{ fill: "black", cursor: "pointer" }}
													/>
												) : (
													<EyeIcon
														style={{ fill: "black", cursor: "pointer" }}
													/>
												)}
											</div>

											<div className={classes.wrapper}>
												<span>Дата:</span> {item.date}
											</div>
											<div className={classes.wrapper}>
												<span>Номер телефона:</span> {item.phone}
											</div>
											<div className={classes.wrapper}>
												<span>Общее впечатление:</span> {item.impression.title}
											</div>

											{item.marks.map((mark, index) => {
												return (
													<div className={classes.wrapper} key={index}>
														<span>{mark.title}:</span> {mark.value}
													</div>
												);
											})}

											<div className={classes.wrapper}>
												<span>Комментарий: </span>
												<p
													style={{ display: "inline " }}
													dangerouslySetInnerHTML={{ __html: item.comment }}
												/>
											</div>
										</div>
									</Grid>
								);
							})}
						</Grid>
					</Grid>
					{visibleReviews < reviews.length && (
						<ButtonMore
							handleClick={handleShowMore}
							size={22}
							text={"Показать ещё"}
						/>
					)}
				</Grid>
			</Grid>
		</>
	);
};

const styles = (theme) => ({
	eyesWrapper: {
		position: "absolute",
		top: "7px",
		right: "15px",
	},
	selectContainer: {
		maxwWidth: 150,
		marginLeft: 5,
		"& .MuiInput-underline:before": {
			display: "none",
		},
		"& .MuiInput-underline:after": {
			display: "none",
		},
		"& .MuiSelect-select": {
			paddingTop: 0,
			paddingBottom: 0,
		},
		"& .MuiSelect-select:focus": {
			backgroundColor: "#fafafa",
		},
	},
	select: {
		fontSize: 14,
		padding: 0,
	},
	title: {
		fontSize: 14,
	},
	sortContainer: {
		width: "100%",
		display: "flex",
		flexWrap: "wrap",
		backgroundColor: "#fff",
		padding: 15,
		borderRadius: 5,
		position: "sticky",
		top: 80,
		boxShadow: "0 10px 18px rgba(0,0,0,0.08)",
	},
	buttonContainer: {
		flexGrow: "1",
		display: "flex",
		alignItems: "center",
		"& .MuiButton-root": {
			fontSize: 13,
		},
		"& .Mui-disabled": {
			color: "#326dff",
		},
	},
	button: {
		padding: "0px 5px",
		display: "flex",
	},
	filter: {
		display: "flex",
		alignItems: "center",
	},
	reviewContaoner: {
		padding: "20px 0px",
		background: "rgba(241,243,244, .5)",
		margin: 0,
	},
	row: {
		position: "relative",
		display: "flex",
		backgroundColor: "#fff",
		width: "100%",
		height: "100%",
		alignContent: "flex-start",
		flexWrap: "wrap",
		padding: 15,
		boxSizing: "border-box",
		[theme.breakpoints.down("600")]: {
			// width: 'calc(100% - 30px)',
			// height: 'calc(100% - 30px)',
		},
	},
	wrapper: {
		width: "100%",
		paddingBottom: 10,
		fontSize: 16,
		fontWeight: 400,
		wordWrap: "break-word",
		"&  span": {
			fontWeight: 600,
		},
	},
	load: {
		width: "100%",
		padding: 15,
		alignItems: "center",
		justifyContent: "center",
		display: "flex",
	},
	btContainer: {
		width: "100%",
		display: "flex",
		justifyContent: "center",
	},
	reviews: {
		textAlign: "center",
		margin: "1rem 0",
		width: "100%",
	},
	arrowUp: {
		background: "#326dff",
	},

	container: {
		padding: 16,
		borderRadius: 5,
		background: "white",
		border: "0.5px solid #DADCE0",
	},
	title: {
		fontSize: 22,
		fontWeight: "bold",
		lineHeight: "26px",
	},
	titleMobile: {
		marginTop: 16,
		marginBottom: 8,
		fontSize: 22,
		fontWeight: "bold",
		lineHeight: "26px",
	},
	tabContainer: {
		[theme.breakpoints.down("600")]: {
			"& > div > .MuiGrid-root": {
				display: "flex",
				justifyContent: "center",
				alignContent: "center",
			},
			"& > div > .MuiGrid-root > div": {
				marginBottom: "16px",
				marginRight: 6,
			},
		},
		[theme.breakpoints.down("376")]: {
			"& > div > .MuiGrid-root > div > div.MuiGrid-root > div": {
				width: "calc(100% - 16px)",
				// marginRight: 0,
				// minWidth: 297,
			},
			"& > div > .MuiGrid-root": {
				// justifyContent: 'inherit',
				// alignContent: 'inherit',
			},
			"& > div > .MuiGrid-root > div": {
				// width: '100%',
			},
		},
	},
});

export default withStyles(styles, { withTheme: true })(Reviews);
